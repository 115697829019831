import { ProgressLevel } from '../enums/ProgressLevel'
import { Skill } from '../enums/Skill'
import { ImageKey, TextKey } from '../types/Antiloop'
import { SessionInfo } from '../types/Session'
import { Images } from './Images'
import {
  getProgressLevelForSkillDevelopment,
  GetProgressLevelProps,
  getProgressLevelSecondSessionSkillDevelopment,
  getProgressLevelSecondSessionTaskPerformance,
  getUserSkillScoreProgressLevelTaskPerformance
} from './ProgressLevelHelper'
import { avgScoresForSlots, GetScoreForSlotsFunction, sumScoresForSlots } from './SkillsInfoHelper'
import { Texts } from './Texts'

export type SkillInfoItem = {
  imageKey: ImageKey
  imageKeyBlack: ImageKey
  textKey: TextKey
  myCollaborationProgress1TextKeys: { [property in ProgressLevel]: TextKey }
  myCollaborationProgress2TextKeys: { [property in ProgressLevel]: TextKey }
  resultSummary1TextKeys: { [property in ProgressLevel]: TextKey }
  resultSummary2TextKeys: { [property in ProgressLevel]: TextKey }
  type: 'user' | 'team'
  getScoreForSlots: GetScoreForSlotsFunction
  getUserSkillScoreForSession?: (sessionInfo: SessionInfo) => number
  getTeamSkillScoreForSession?: (sessionInfo: SessionInfo) => number
  getProgressLevel: (p: GetProgressLevelProps) => ProgressLevel
  getRelativeProgressPercentageProgressLevel: (diff: number) => ProgressLevel
}

export const SkillsInfo: {
  [property in Skill]: SkillInfoItem
} = {
  [Skill.TaskPerformance]: {
    type: 'team',
    imageKey: Images.collaborationSessionItemTaskPerformance,
    imageKeyBlack: Images.collaborationSessionItemTaskPerformance,
    textKey: Texts.componentsCapabilitiesCapabilityTaskPerformance,
    getScoreForSlots: (p, f): number => {
      if (p.uid) return avgScoresForSlots(p, f)
      return sumScoresForSlots(p, f)
    },
    getUserSkillScoreForSession: (sessionInfo: SessionInfo) =>
      sessionInfo.skills.TaskPerformance.items.UridiumCollected.yourScore,
    getTeamSkillScoreForSession: (sessionInfo: SessionInfo) =>
      sessionInfo.skills.TaskPerformance.items.UridiumCollected.teamScore,
    myCollaborationProgress1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1TaskSession1Good,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1TaskSession1Good,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1TaskSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1TaskSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1TaskSession1Bad
    },
    myCollaborationProgress2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2TaskMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2TaskSlightBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2TaskNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2TaskSlightWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2TaskBad
    },
    resultSummary1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtR1R1TaskGood,
      [ProgressLevel.better]: Texts.dynamicTxtR1R1TaskGood,
      [ProgressLevel.same]: Texts.dynamicTxtR1R1TaskNeutral,
      [ProgressLevel.worse]: Texts.dynamicTxtR1R1TaskBad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtR1R1TaskBad
    },
    resultSummary2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtR2R2TaskMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtR2R2TaskSlightBetter,
      [ProgressLevel.same]: Texts.dynamicTxtR2R2TaskNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtR2R2TaskSlightWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtR2R2TaskMuchWorse
    },
    getProgressLevel: getUserSkillScoreProgressLevelTaskPerformance,
    getRelativeProgressPercentageProgressLevel: getProgressLevelSecondSessionTaskPerformance
  },
  [Skill.HelpingAndAssisting]: {
    type: 'user',
    imageKey: Images.collaborationSessionItemHelping,
    imageKeyBlack: Images.imgHelpBack,
    textKey: Texts.componentsCapabilitiesCapabilityHelpingAndAssisting,
    getProgressLevel: getProgressLevelForSkillDevelopment,
    getScoreForSlots: avgScoresForSlots,
    myCollaborationProgress1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Good,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Good,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Bad
    },
    myCollaborationProgress2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingSlightlyBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingSlightlyWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingMuchWorse
    },
    // Below is incorrect
    resultSummary1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Good,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Good,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1HelpingSession1Bad
    },
    resultSummary2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingSlightlyBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingSlightlyWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2HelpingAndAssistingMuchWorse
    },
    getRelativeProgressPercentageProgressLevel: getProgressLevelSecondSessionSkillDevelopment
  },
  [Skill.Communication]: {
    type: 'user',
    imageKey: Images.collaborationSessionItemCommunication,
    imageKeyBlack: Images.communicationBlack,
    textKey: Texts.componentsCapabilitiesCapabilityCommunication,
    getProgressLevel: getProgressLevelForSkillDevelopment,
    getScoreForSlots: avgScoresForSlots,
    myCollaborationProgress1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Good,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Good,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Bad
    },
    myCollaborationProgress2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2CommunicationMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2CommunicationSlightlyBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2CommunicationNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2CommunicationSlightlyWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2CommunicationMuchWorse
    },
    resultSummary1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Good,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Good,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1CommunicationSession1Bad
    },
    resultSummary2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2CommunicationMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2CommunicationSlightlyBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2CommunicationNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2CommunicationSlightlyWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2CommunicationMuchWorse
    },
    getRelativeProgressPercentageProgressLevel: getProgressLevelSecondSessionSkillDevelopment
  },
  [Skill.DecisionMaking]: {
    type: 'user',
    imageKey: Images.collaborationSessionItemDecisionMaking,
    imageKeyBlack: Images.imgDecisionMakingBlack,
    textKey: Texts.componentsCapabilitiesCapabilityDecisionMaking,
    getProgressLevel: getProgressLevelForSkillDevelopment,
    getScoreForSlots: avgScoresForSlots,
    myCollaborationProgress1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Good,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Good,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Bad
    },
    myCollaborationProgress2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2DecisionMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2DecisionSlightBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2DecisionNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2DecisionSlightWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2DecisionBad
    },
    resultSummary1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Good,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Good,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1DecisionSession1Bad
    },
    resultSummary2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2DecisionMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2DecisionSlightBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2DecisionNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2DecisionSlightWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2DecisionBad
    },
    getRelativeProgressPercentageProgressLevel: getProgressLevelSecondSessionSkillDevelopment
  }
}

// Used to get correct calculation model for User Overall Score
// https://responsive-tribes.atlassian.net/wiki/spaces/Intraction/pages/582516737/Aff+rslogik+-+Collaboration+Progress
export const SkillsInfoItemOverallScore: SkillInfoItem = SkillsInfo.Communication
