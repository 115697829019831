import { RecommendationLevels } from '../enums/RecommendationLevels'
import { SkillItemProgress } from '../types/Session'
import { toPercent } from './getSessionInfo'
import { Images } from './Images'
import {
  communicatingActiveListeningWeight,
  communicatingAirtimeRelativeWeight,
  communicatingAirtimeWeight,
  communicatingHowYouFeelWeight,
  communicatingInteruptingOthersWeight,
  communicatingTimesTalkedWeight
} from './SessionInfoConstants'
import {
  commiunicatingAirTimeRelative,
  communicatingActiveListening,
  communicatingAirTime,
  communicatingHowYouFeel,
  communicatingInteruptingOthers,
  communicatingTimesTalked
} from './SessionInfoHelper'
import { SkillDevelopmentItemInfo } from './SkillDevelopmentItemInfo'
import { Texts } from './Texts'

export enum SkillDevelopmentItemCommunication {
  AirTime = 'AirTime',
  AirTimeRelative = 'AirTimeRelative',
  ActiveListening = 'ActiveListening',
  InteruptingOthers = 'InteruptingOthers',
  CommunicatingHowYouFeel = 'CommunicatingHowYouFeel',
  TimesTalked = 'TimesTalked'
}

export const SkillDevelopmentItemInfoCommunication: {
  [property in SkillDevelopmentItemCommunication]: SkillDevelopmentItemInfo
} = {
  [SkillDevelopmentItemCommunication.AirTime]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleAirTime,
    imageKey: Images.imgTimeSpentTalkingWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoAirTime,
    getValueForSlotDataPoint: ({ dataPoint }) => communicatingAirTime(dataPoint),
    weight: communicatingAirtimeWeight,
    metaDataPointKey: 'airTime'
  },
  [SkillDevelopmentItemCommunication.AirTimeRelative]: {
    enableAlways100Percent: true,
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleAirTime,
    imageKey: Images.imgTimeSpentTalkingWhite,
    enableYourScoreRatio: false,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoAirTime,
    getValueForSlotDataPoint: ({ dataPoint }) => commiunicatingAirTimeRelative(dataPoint),
    getDisplayValue: ({ dataPoint, slots }) => dataPoint.airTimeRelative / slots.length,
    formatDisplayValue: (n) => `${toPercent(n)}%`,
    weight: communicatingAirtimeRelativeWeight,
    metaDataPointKey: 'airTimeRelative',
    recommendationTextKeys: {
      [RecommendationLevels.tooHigh]: Texts.dynamicTxtRecCRecCAtrTooHigh,
      [RecommendationLevels.slightlyHigh]: Texts.dynamicTxtRecCRecCAtrSlightlyHigh,
      [RecommendationLevels.slightlyLow]: Texts.dynamicTxtRecCRecCAtrSlightlyLow,
      [RecommendationLevels.tooLow]: Texts.dynamicTxtRecCRecCAtrTooLow
    },
    getRecommendationLevel: ({ userDisplayValue }: SkillItemProgress) => {
      switch (true) {
        case userDisplayValue < 0.1:
          return RecommendationLevels.tooLow
        case userDisplayValue < 0.2:
          return RecommendationLevels.slightlyLow
        case userDisplayValue > 0.3:
          return RecommendationLevels.slightlyHigh
        case userDisplayValue > 0.4:
          return RecommendationLevels.tooHigh
        default:
          return undefined
      }
    }
  },
  [SkillDevelopmentItemCommunication.ActiveListening]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleActiveListening,
    imageKey: Images.imgCommunicationRecieptWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoActiveListening,
    getValueForSlotDataPoint: ({ dataPoint }) => communicatingActiveListening(dataPoint),
    getDisplayValue: ({ dataPoint, slots }) => dataPoint.timesReceipt / slots.length,
    formatDisplayValue: (n) => n.toFixed(0),
    weight: communicatingActiveListeningWeight,
    metaDataPointKey: 'timesReceipt',
    recommendationTextKeys: {
      [RecommendationLevels.tooHigh]: undefined,
      [RecommendationLevels.slightlyHigh]: undefined,
      [RecommendationLevels.slightlyLow]: Texts.dynamicTxtRecCRecCTrSlightlyLow,
      [RecommendationLevels.tooLow]: Texts.dynamicTxtRecCRecCTrTooLow
    },
    getRecommendationLevel: ({ userDisplayValue }: SkillItemProgress) => {
      switch (true) {
        case userDisplayValue < 35:
          return RecommendationLevels.tooLow
        case userDisplayValue < 70:
          return RecommendationLevels.slightlyLow
        default:
          return undefined
      }
    }
  },
  [SkillDevelopmentItemCommunication.InteruptingOthers]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleInterrupting,
    imageKey: Images.imgTImesInterruptedWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoInterrupting,
    getValueForSlotDataPoint: ({ dataPoint }) => communicatingInteruptingOthers(dataPoint),
    getDisplayValue: ({ dataPoint, slots }) => dataPoint.timesOverlapAirTime / slots.length,
    formatDisplayValue: (n) => n.toFixed(0),
    weight: communicatingInteruptingOthersWeight,
    metaDataPointKey: 'timesOverlapAirTime',
    recommendationTextKeys: {
      [RecommendationLevels.tooHigh]: Texts.dynamicTxtRecCRecCToatTooHigh,
      [RecommendationLevels.slightlyHigh]: Texts.dynamicTxtRecCRecCToatSlightlyHigh,
      [RecommendationLevels.slightlyLow]: undefined,
      [RecommendationLevels.tooLow]: undefined
    },
    getRecommendationLevel: ({ userDisplayValue }: SkillItemProgress) => {
      switch (true) {
        case userDisplayValue > 0:
          return RecommendationLevels.slightlyHigh
        case userDisplayValue > 40:
          return RecommendationLevels.tooHigh
        default:
          return undefined
      }
    }
  },
  [SkillDevelopmentItemCommunication.TimesTalked]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleInterrupting,
    imageKey: Images.imgTimeSpentTalkingWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoInterrupting,
    getValueForSlotDataPoint: ({ dataPoint }) => communicatingTimesTalked(dataPoint),
    weight: communicatingTimesTalkedWeight,
    metaDataPointKey: 'timesTalked'
  },
  [SkillDevelopmentItemCommunication.CommunicatingHowYouFeel]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleCommunicateFeeling,
    imageKey: Images.imgEmotionalWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoCommunicateFeeling,
    getValueForSlotDataPoint: ({ dataPoint }) => communicatingHowYouFeel(dataPoint),
    getDisplayValue: ({ dataPoint, slots }) => dataPoint.timesCircumplex / slots.length,
    formatDisplayValue: (n) => n.toFixed(0),
    weight: communicatingHowYouFeelWeight,
    metaDataPointKey: 'timesCircumplex',
    recommendationTextKeys: {
      [RecommendationLevels.tooHigh]: undefined,
      [RecommendationLevels.slightlyHigh]: undefined,
      [RecommendationLevels.slightlyLow]: Texts.dynamicTxtRecCRecCTcSlightlyLow,
      [RecommendationLevels.tooLow]: Texts.dynamicTxtRecCRecCTcTooLow
    },
    getRecommendationLevel: ({ userDisplayValue }: SkillItemProgress) => {
      switch (true) {
        case userDisplayValue < 2:
          return RecommendationLevels.tooLow
        case userDisplayValue < 4:
          return RecommendationLevels.slightlyLow
        default:
          return undefined
      }
    }
  }
}
