const adjectives = ['Brave', 'Clever', 'Witty', 'Bold', 'Swift', 'Mighty', 'Epic', 'Fierce', 'Noble', 'Vivid']

const nouns = [
  'Lions',
  'Tigers',
  'Eagles',
  'Wolves',
  'Dragons',
  'Phoenixes',
  'Knights',
  'Warriors',
  'Titans',
  'Rangers'
]

function getRandomElement(arr: string[]): string {
  return arr[Math.floor(Math.random() * arr.length)]
}

export function createUniqueTeamName(): string {
  const adjective = getRandomElement(adjectives)
  const noun = getRandomElement(nouns)
  return `${adjective} ${noun}`
}

export function getUniqueTeamId(teamName: string): string {
  const number = Math.floor(1000 + Math.random() * 9000) // Generates a random 4-digit number
  return `${teamName.trim().replaceAll(' ', '-')}-${number}`
}
