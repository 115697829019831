import styled from '@emotion/styled'
import { Input, InputProps } from '@mui/material'
import { MainFrameBG } from '../../../pure-js/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { tinySpacing } from '../enums/Spacings'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'

export function TextFieldFormExperimental({
  field,
  fieldState,
  headerText,
  inputProps,
  hasError
}: {
  field
  fieldState
  headerText?: string
  inputProps?: InputProps
  hasError?: boolean
}) {
  const hasValidationError = fieldState.error
  return (
    <Box fullWidth>
      <Container fullWidth hasValidationError={hasError || hasValidationError}>
        {headerText && (
          <Box top left spacing={tinySpacing}>
            <FigmaText textKey={Texts.onboardingOtpPageInputOtpCodeHeader} text={headerText} />
          </Box>
        )}
        <Input
          {...field}
          fullWidth
          disableUnderline
          size="small"
          inputProps={{ style: { color: 'white', borderColor: 'white' } }}
          sx={{
            borderRadius: BorderRadixes.regular,
            paddingLeft: tinySpacing,
            ...field.sx
          }}
          {...inputProps}
        />
      </Container>
      <Box fullWidth align="flex-end">
        {hasValidationError && <GenericErrorComponent fieldState={fieldState} />}
      </Box>
    </Box>
  )
}

const Container = styled(Box)<{ hasValidationError?: boolean }>`
  background-color: ${MainFrameBG};
  border-radius: ${BorderRadixes.small};
  ${({ hasValidationError }) => (hasValidationError ? 'border: 1px solid red' : '')};
`

export function GenericErrorComponent({ fieldState }) {
  return <FigmaText textKey={Texts.onboardingStartPageInputEmailErrorWrongFormat} text={fieldState?.error?.message} />
}
