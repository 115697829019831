import { onAuthStateChanged, User } from 'firebase/auth'
import invariant from 'invariant'
import React from 'react'
import { getEmailForFirebaseUser } from '../../../pure-js/libs/Common'
import { getUser, updateUser } from '../libs/DBApiHandler'
import { analytics, auth } from '../libs/Firebase'
import { toBaseObject } from '../libs/Mapper'
import { captureException, setSentryUser } from '../libs/SentryHelper'
import { SignInPageProps } from '../libs/SignInHelper'
import useAppState, { State } from './useAppState'
import { useLogout } from './useLogout'
import useQueryParams from '../libs/useQueryParams'
import { DevIdsQueryParams } from '../../../pure-js/types/QueryParamTypes'
import { getUidFromQueryParams } from './useUser'
import dayjs from 'dayjs'
import { setUserId } from 'firebase/analytics'

type Props = SignInPageProps & {
  onFinally: () => unknown
}

export default function useLogin(props: Props) {
  const { onFinally } = props
  const { state, setState } = useAppState()
  const [loading, setLoading] = React.useState(true)
  const logout = useLogout()
  const qp = useQueryParams<DevIdsQueryParams>()

  const onLogin = (firebaseUser: User) =>
    getUser(getUidFromQueryParams(qp, firebaseUser) || firebaseUser.uid).then((user) => {
      const email = getEmailForFirebaseUser(firebaseUser)
      setSentryUser({ email, id: firebaseUser.uid })
      setState({ ...state, firebaseUser })
      analytics && setUserId(analytics, firebaseUser.uid)

      return Promise.resolve(user)
        .then(async (user) => {
          if (user?.email) return user

          user = {
            ...toBaseObject({ user: { id: firebaseUser.uid } } as State),
            id: firebaseUser.uid,
            email: email,
            lastActiveAt: dayjs().format()
          }

          await updateUser(user)

          return user
        })
        .then(async (user) => {
          invariant(user, '!user')
          invariant(user.id, '!user.id')

          const currentUser = auth.currentUser
          invariant(currentUser, 'currentUser')

          // Force refresh to get the latest custom claims
          await currentUser.getIdToken(true)

          const _state: State = { ...state, user }
          setState(_state)

          return props.onLogin?.(_state)
        })
        .catch((e) => {
          logout()
          return Promise.reject(e)
        })
    })

  React.useEffect(() => {
    onAuthStateChanged(
      auth,
      (user) =>
        Promise.resolve()
          .then(() => {
            if (!user && !!state.user.id) return logout()
            if (!user) return
            return Promise.resolve(setLoading(true))
              .then(() => onLogin(user))
              .finally(() => onFinally())
          })
          .catch(captureException)
          .finally(() => setLoading(false)),
      captureException
    )
  }, [])

  return loading
}
