import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import config from './Config'

const opts = {
  dsn: config.sentryDsn,
  environment: window.location.hostname || '',
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  enabled: config.enableSentry,
  tracesSampleRate: 1.0,
  release: process.env.VITE_SENTRY_RELEASE || undefined
}

Sentry.init(opts)

export const captureException = Sentry.captureException
export const setUser = Sentry.setUser
export const setTag = Sentry.setTag
export const withScope = Sentry.withScope
export type User = Sentry.User
