import { useEffect } from 'react'
import { useNavigate } from '../hooks/useNavigate'
import { DashboardPage } from '../pages/DashboardPage'
import RoutePath from '../../../pure-js/libs/RoutePath'

export const SignedInGoToDashboard = () => {
  const navigate = useNavigate()
  useEffect(() => {
    navigate(RoutePath.DASHBOARD)
  }, [])
  return <DashboardPage />
}
