import RoutePath from '../../../pure-js/libs/RoutePath'
import { Texts } from '../../../pure-js/libs/Texts'
import { Session } from '../../../pure-js/types/Session'
import { useSessions } from '../hooks/useSessions'
import { useNavigate } from '../hooks/useNavigate'
import { dayjs } from '../libs/DayJsHelper'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'

export function DashboardOverallCollaboration() {
  const navigate = useNavigate()
  const { data: sessions = [] } = useSessions()
  const session = getLatestSession(sessions)
  const sessionId = session?.id

  const textKey =
    sessions.length > 0
      ? Texts.myCollaborationProgressCollaborationProgressSessionReport
      : Texts.dashboardOverallCollaborationEmptyState

  return (
    <Box fullWidth fullHeight justify="space-between">
      <Box>
        <Box>
          <FigmaText textKey={Texts.dashboardOverallCollaborationSectionHeader} />
        </Box>
        <Box top>
          <FigmaText textKey={textKey} />
        </Box>
      </Box>
      {sessionId && (
        <Box direction="row" align="center" top>
          <Box>
            <Button
              textKey={Texts.myCollaborationProgressColProgCtaLastSession}
              onClick={() => navigate(RoutePath.COLLABORATION, { sessionId })}
            />
          </Box>
          <Box left>
            <Button
              textKey={Texts.myCollaborationProgressColProgCtaViewAllSession}
              onClick={() => navigate(RoutePath.COLLABORATION)}
            />
          </Box>
        </Box>
      )}
    </Box>
  )
}

export function getLatestSession(sessions: Session[]) {
  return sessions.sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())[0]
}
