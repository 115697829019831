export const HardcodedTextKeys = {
  userMenuSettingsButtonText: {
    name: 'userMenuSettingsButtonText',
    characters: 'Settings',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.4000000059604645px',
      color: 'rgba(0, 0, 0, 1)'
    },
    pageName: 'New base',
    figmaTextKeyName: 'TXT_userMenuSettingsButtonText'
  },
  cssOrangeSmallText: {
    characters: '+ 16%',
    name: 'cssCollaborationProgressSessionTaskPerformanceEventItemTeamDataRelative',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(255, 171, 64, 1)'
    },
    pageName: 'App'
  }
}
