import dayjs from 'dayjs'
import { DMMMHHmm } from '../../../pure-js/libs/Consts'
import { Images } from '../../../pure-js/libs/Images'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { Texts } from '../../../pure-js/libs/Texts'
import { SessionInfo } from '../../../pure-js/types/Session'
import { useTeam } from '../hooks/QueryHooks'
import { useNavigate } from '../hooks/useNavigate'
import { useTeamId } from '../hooks/useTeamId'
import { formatText, getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export function CollaborationProgressSessionHeader({ sessionInfo }: { sessionInfo: SessionInfo }) {
  const { name, createdAt } = sessionInfo
  const { data: t } = useTeam(useTeamId())

  const navigate = useNavigate()
  const onClickBack = () => navigate(RoutePath.COLLABORATION, { sessionId: null, index: null })

  return (
    <Box fullWidth direction="row" align="center" justify="space-between">
      <Box direction="row" align="center">
        <Box pointer onClick={onClickBack}>
          <FigmaImageContainer imageKey={Images.goBack} />
        </Box>
        <Box left>
          <FigmaText textKey={Texts.myCollaborationProgressSessionDetailsSessionDetailsSessionIdentifier} text={name} />
        </Box>
        {t?.name && (
          <Box left>
            <FigmaText
              textKey={Texts.myCollaborationProgressCollaborationTeamName}
              text={formatText(getFigmaText(Texts.myCollaborationProgressCollaborationTeamName), [t.name])}
            />
          </Box>
        )}
        {createdAt && (
          <Box left>
            <FigmaText
              textKey={Texts.myCollaborationProgressSessionDetailsSessionDetailsSessionDate}
              text={dayjs(createdAt).format(DMMMHHmm)}
            />
          </Box>
        )}
      </Box>
      <Box onClick={onClickBack} pointer>
        <FigmaImageContainer imageKey={Images.iconClosePage} />
      </Box>
    </Box>
  )
}
