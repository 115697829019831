import dayjs from 'dayjs'
import invariant from 'invariant'
import { ProgressLevel } from '../enums/ProgressLevel'
import { Skill } from '../enums/Skill'
import {
  Session,
  SessionInfo,
  SessionInfoPotential,
  SessionWithSlots,
  SkilItemProgressCalculatedBy,
  SkillItemProgress,
  SkillItemProgressCalculatedByItem
} from '../types/Session'
import { Slot } from '../types/Slot'
import { getProgressLevelForSkillDevelopment } from './ProgressLevelHelper'
import {
  AllSkillInfoDevelopmentItemInfos,
  SkillDevelopmentItemInfo,
  SkillInfoDevelopmentItemInfoKey
} from './SkillDevelopmentItemInfo'
import {
  SkillDevelopmentItemCommunication,
  SkillDevelopmentItemInfoCommunication
} from './SkillDevelopmentItemInfoCommunication'
import {
  SkillDevelopmentItemDecisionMaking,
  SkillDevelopmentItemInfoDecisionMaking
} from './SkillDevelopmentItemInfoDecisionMaking'
import { SkillDevelopmentItemHelping, SkillDevelopmentItemInfoHelping } from './SkillDevelopmentItemInfoHelping'
import {
  SkillDevelopmentItemInfoTaskPerformance,
  SkillDevelopmentTaskPerformance
} from './SkillDevelopmentItemInfoTaskPerformance'
import { SkillInfoItem, SkillsInfo, SkillsInfoItemOverallScore } from './SkillsInfo'
import { sumSkillScore } from './SkillsInfoHelper'
import { isElligbleSlot } from './getElligebleSessions'

export type GetSessionInfoOptions = {
  enableSessionOnSessionInfo?: boolean
}

const GetSessionInfoOptionsDefault: GetSessionInfoOptions = {
  enableSessionOnSessionInfo: true
}

export const getSessionInfo = (
  {
    session,
    sessionsWithSlots = [],
    uid,
    slotId
  }: {
    session: SessionWithSlots
    sessionsWithSlots: SessionWithSlots[]
    uid: string
    slotId?: string
  },
  f: Partial<GetSessionInfoOptions> = GetSessionInfoOptionsDefault
): SessionInfo => {
  f = { ...GetSessionInfoOptionsDefault, ...f }
  session.slots = session.slots.filter((s) => !slotId || s.id === slotId)

  const previousSessions: SessionWithSlots[] = getPreviousSessions(session, sessionsWithSlots)

  const sessionInfo: SessionInfo = getPartialSessionInfo({ uid, session, sessionsWithSlots }, f)
  const previousSessionInfos: SessionInfo[] = previousSessions.map((session) =>
    getPartialSessionInfo({ uid, session, sessionsWithSlots }, f)
  )

  sessionInfo.skills = Object.keys(sessionInfo.skills).reduce(
    reduceSkills(sessionInfo, previousSessionInfos, uid),
    {} as SessionInfo['skills']
  )

  previousSessionInfos.forEach((prevSessionInfo) => {
    prevSessionInfo.skills = Object.keys(sessionInfo.skills).reduce(
      reduceSkills(prevSessionInfo, previousSessionInfos, uid),
      {} as SessionInfo['skills']
    )
  })

  return {
    ...sessionInfo,
    userProgressLevel: getProgressLevelForUserOverallScore({
      sessionInfo,
      prevSessionInfo: getPreviousSession(sessionInfo, previousSessionInfos),
      uid
    }),
    userOverallScore: getUserOverallScore(sessionInfo),
    userTop1Potential: getUserTop1Potential({ sessionInfo })
  }
}

const getScoresForSkill = (
  skill: Skill,
  sessionInfo: SessionInfo,
  previousSessionInfos: SessionInfo[],
  uid: string
) => {
  const { session } = sessionInfo
  const skillInfoItem = SkillsInfo[skill]
  const {
    getUserSkillScoreForSession = (sessionInfo) =>
      sumSkillScore(Object.values(sessionInfo.skills[skill].items), 'yourScore'),
    getTeamSkillScoreForSession = (sessionInfo) =>
      sumSkillScore(Object.values(sessionInfo.skills[skill].items), 'teamScore'),
    getProgressLevel
  } = skillInfoItem

  const userSkillScore = getUserSkillScoreForSession(sessionInfo)
  const teamSkillScore = getTeamSkillScoreForSession(sessionInfo)

  const prev = getPreviousSession(sessionInfo, previousSessionInfos)
  const userProgressLevel = getProgressLevel({
    skillScore: userSkillScore,
    otherSkillScores: prev ? [getUserSkillScoreForSession(prev)] : [],
    skillInfoItem,
    session,
    uid,
    shouldLog: false
  })

  const teamProgressLevel = getProgressLevel({
    skillScore: teamSkillScore,
    otherSkillScores: prev ? [getTeamSkillScoreForSession(prev)] : [],
    skillInfoItem,
    session,
    shouldLog: false
  })

  const userTop1Potential = getUserTop1Potential({ sessionInfo, skill }) as SessionInfoPotential

  return {
    uid,
    userSkillScore,
    teamSkillScore,
    userProgressLevel,
    teamProgressLevel,
    userTop1Potential,
    _skillDevelopmentItemInfo: undefined
  }
}

function reduceSkills(
  sessionInfo: SessionInfo,
  previousSessionInfos: SessionInfo[],
  uid: string
): (
  previousValue: SessionInfo['skills'],
  currentValue: string,
  currentIndex: number,
  array: string[]
) => SessionInfo['skills'] {
  return (a, skill) => {
    a[skill] = {
      ...sessionInfo.skills[skill as Skill],
      ...getScoresForSkill(skill as Skill, sessionInfo, previousSessionInfos, uid)
    }

    return a
  }
}

function getUserTop1Potential(p: { sessionInfo: SessionInfo; skill?: Skill }) {
  const userSkillItemWithHighestPotential = getAllSkillItemProgressItems(p.sessionInfo)
    .filter(
      (item: {
        skill: Skill
        skillDevelopmentItemKey: SkillInfoDevelopmentItemInfoKey
        item: SkillItemProgress
      }): boolean => {
        return (
          (!p.skill || item.skill === p.skill) &&
          !!AllSkillInfoDevelopmentItemInfos[item.skillDevelopmentItemKey].getRecommendationLevel &&
          !!AllSkillInfoDevelopmentItemInfos[item.skillDevelopmentItemKey].recommendationTextKeys
        )
      }
    )
    .sort((a, b): number => b.item.yourPotential - a.item.yourPotential)[0]

  if (!userSkillItemWithHighestPotential) return undefined

  const userDevelopmentItemInfo =
    AllSkillInfoDevelopmentItemInfos[userSkillItemWithHighestPotential.skillDevelopmentItemKey]

  return {
    skill: userSkillItemWithHighestPotential.skill,
    skillDevelopmentItemKey: userSkillItemWithHighestPotential.skillDevelopmentItemKey,
    recommendationLevel: userDevelopmentItemInfo.getRecommendationLevel?.(
      userSkillItemWithHighestPotential.item,
      p.sessionInfo.session.slots
    ),
    potential: userSkillItemWithHighestPotential.item.yourPotential
  }
}

function getAllSkillItemProgressItems(sessionInfo: SessionInfo): {
  skill: Skill
  skillDevelopmentItemKey: SkillInfoDevelopmentItemInfoKey
  item: SkillItemProgress
}[] {
  return Object.keys(sessionInfo.skills)
    .filter((skill) => skill !== Skill.TaskPerformance)
    .flatMap((skill) =>
      Object.keys(sessionInfo.skills[skill].items).map((skillDevelopmentItemKey) => ({
        skill: skill as Skill,
        skillDevelopmentItemKey: skillDevelopmentItemKey as SkillInfoDevelopmentItemInfoKey,
        item: sessionInfo.skills[skill].items[skillDevelopmentItemKey]
      }))
    )
}

function getPreviousSessions(session: SessionWithSlots, sessionsWithSlots: SessionWithSlots[]): SessionWithSlots[] {
  return sessionsWithSlots.filter((s) => dayjs(s.createdAt).isBefore(session.createdAt))
}

function getPartialSessionInfo(
  i: {
    uid: string
    session: SessionWithSlots
    sessionsWithSlots: SessionWithSlots[]
  },
  f = GetSessionInfoOptionsDefault
): SessionInfo {
  const { session } = i
  const sessionNumber =
    i.sessionsWithSlots
      .sort((a, b) => dayjs(a.createdAt).diff(dayjs(b.createdAt)))
      .findIndex((s) => s.id === session.id) + 1

  return {
    ...DefaultSessionInfo,
    createdAt: session.slots[0]?.start_time || session.createdAt,
    sessionId: session.id,
    name: `Session ${sessionNumber || ''}`.trim(),
    sessionNumber,
    session: (f.enableSessionOnSessionInfo ? session : undefined) as SessionWithSlots,
    previousSessions: getPreviousSessions(session, i.sessionsWithSlots),
    skills: {
      [Skill.TaskPerformance]: {
        ...DefaultSkillItem,
        items: {
          [SkillDevelopmentTaskPerformance.UridiumCollected]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoTaskPerformance[SkillDevelopmentTaskPerformance.UridiumCollected],
              skillInfoItem: SkillsInfo[Skill.TaskPerformance]
            },
            f
          ),
          [SkillDevelopmentTaskPerformance.AbilityUsed]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoTaskPerformance[SkillDevelopmentTaskPerformance.AbilityUsed],
              skillInfoItem: SkillsInfo[Skill.TaskPerformance]
            },
            f
          ),
          [SkillDevelopmentTaskPerformance.DroneUsed]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoTaskPerformance[SkillDevelopmentTaskPerformance.DroneUsed],
              skillInfoItem: SkillsInfo[Skill.TaskPerformance]
            },
            f
          ),
          [SkillDevelopmentTaskPerformance.Hypothermia]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoTaskPerformance[SkillDevelopmentTaskPerformance.Hypothermia],
              skillInfoItem: SkillsInfo[Skill.TaskPerformance]
            },
            f
          ),
          [SkillDevelopmentTaskPerformance.PeopleHealed]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoTaskPerformance[SkillDevelopmentTaskPerformance.PeopleHealed],
              skillInfoItem: SkillsInfo[Skill.TaskPerformance]
            },
            f
          ),
          [SkillDevelopmentTaskPerformance.RobotsUnlocked]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoTaskPerformance[SkillDevelopmentTaskPerformance.RobotsUnlocked],
              skillInfoItem: SkillsInfo[Skill.TaskPerformance]
            },
            f
          )
        }
      },
      [Skill.Communication]: {
        ...DefaultSkillItem,
        items: {
          [SkillDevelopmentItemCommunication.AirTime]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoCommunication[SkillDevelopmentItemCommunication.AirTime],
              skillInfoItem: SkillsInfo[Skill.Communication]
            },
            f
          ),
          [SkillDevelopmentItemCommunication.AirTimeRelative]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoCommunication[SkillDevelopmentItemCommunication.AirTimeRelative],
              skillInfoItem: SkillsInfo[Skill.Communication]
            },
            f
          ),
          [SkillDevelopmentItemCommunication.TimesTalked]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoCommunication[SkillDevelopmentItemCommunication.TimesTalked],
              skillInfoItem: SkillsInfo[Skill.Communication]
            },
            f
          ),
          [SkillDevelopmentItemCommunication.ActiveListening]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoCommunication[SkillDevelopmentItemCommunication.ActiveListening],
              skillInfoItem: SkillsInfo[Skill.Communication]
            },
            f
          ),
          [SkillDevelopmentItemCommunication.InteruptingOthers]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoCommunication[SkillDevelopmentItemCommunication.InteruptingOthers],
              skillInfoItem: SkillsInfo[Skill.Communication]
            },
            f
          ),
          [SkillDevelopmentItemCommunication.CommunicatingHowYouFeel]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoCommunication[SkillDevelopmentItemCommunication.CommunicatingHowYouFeel],
              skillInfoItem: SkillsInfo[Skill.Communication]
            },
            f
          )
        }
      },
      [Skill.HelpingAndAssisting]: {
        ...DefaultSkillItem,
        items: {
          [SkillDevelopmentItemHelping.ResourcesShared]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo: SkillDevelopmentItemInfoHelping[SkillDevelopmentItemHelping.ResourcesShared],
              skillInfoItem: SkillsInfo[Skill.HelpingAndAssisting]
            },
            f
          ),
          [SkillDevelopmentItemHelping.AbilitiesShared]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo: SkillDevelopmentItemInfoHelping[SkillDevelopmentItemHelping.AbilitiesShared],
              skillInfoItem: SkillsInfo[Skill.HelpingAndAssisting]
            },
            f
          ),
          [SkillDevelopmentItemHelping.CoordinatingWithOthers]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoHelping[SkillDevelopmentItemHelping.CoordinatingWithOthers],
              skillInfoItem: SkillsInfo[Skill.HelpingAndAssisting]
            },
            f
          )
        }
      },
      [Skill.DecisionMaking]: {
        ...DefaultSkillItem,
        items: {
          [SkillDevelopmentItemDecisionMaking.RiskProfile]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoDecisionMaking[SkillDevelopmentItemDecisionMaking.RiskProfile],
              skillInfoItem: SkillsInfo[Skill.DecisionMaking]
            },
            f
          ),
          [SkillDevelopmentItemDecisionMaking.PrioritizedDecisionMaking]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoDecisionMaking[SkillDevelopmentItemDecisionMaking.PrioritizedDecisionMaking],
              skillInfoItem: SkillsInfo[Skill.DecisionMaking]
            },
            f
          ),
          [SkillDevelopmentItemDecisionMaking.TimeManagement]: getSkillItemProgress(
            {
              ...i,
              skillDevelopmentItemInfo:
                SkillDevelopmentItemInfoDecisionMaking[SkillDevelopmentItemDecisionMaking.TimeManagement],
              skillInfoItem: SkillsInfo[Skill.DecisionMaking]
            },
            f
          )
        }
      }
    }
  }
}

function getSkillItemProgress(
  {
    session,
    uid,
    skillDevelopmentItemInfo,
    skillInfoItem,
    sessionsWithSlots
  }: {
    session: SessionWithSlots
    sessionsWithSlots: SessionWithSlots[]
    skillDevelopmentItemInfo: SkillDevelopmentItemInfo
    skillInfoItem: SkillInfoItem
    uid: string
  },
  f: typeof GetSessionInfoOptionsDefault
): SkillItemProgress {
  const { formatDisplayValue = (n) => n.toString() } = skillDevelopmentItemInfo
  const { getScoreForSlots, getRelativeProgressPercentageProgressLevel } = skillInfoItem
  const lastSession = getPreviousSession(session, sessionsWithSlots)

  const prevSlots = (lastSession?.slots || []).filter(isElligbleSlot)
  const slots = session.slots.filter(isElligbleSlot)

  const teamScore = getScoreForSlots({ skillDevelopmentItemInfo, slots, session }, f) // "viktas" in google sheet

  const userDisplayValue = getDisplayValueForSlots({ slots, uid, skillDevelopmentItemInfo, session }, f)
  const teamDisplayValue = getDisplayValueForSlots({ slots, skillDevelopmentItemInfo, session }, f)

  const prevUserDisplayValue = getDisplayValueForSlots({ slots: prevSlots, uid, skillDevelopmentItemInfo }, f)
  const prevTeamDisplayValue = getDisplayValueForSlots({ slots: prevSlots, skillDevelopmentItemInfo }, f)

  const yourScore = getScoreForSlots({ uid, skillDevelopmentItemInfo, slots, session }, f) // "viktas" in google sheet

  const yourScoreRatio = teamDisplayValue !== 0 ? userDisplayValue / teamDisplayValue : 0

  const teamRelativeProgress =
    prevTeamDisplayValue !== 0 ? (teamDisplayValue - prevTeamDisplayValue) / prevTeamDisplayValue : undefined

  const yourRelativeProgress =
    prevUserDisplayValue !== 0 ? (userDisplayValue - prevUserDisplayValue) / prevUserDisplayValue : undefined

  const formattedTeamDisplayValue = formatDisplayValue(teamDisplayValue, f)
  const formattedUserDisplayValue = formatDisplayValue(userDisplayValue, f)

  const yourPotential = skillDevelopmentItemInfo.weight - yourScore
  const teamPotential = skillDevelopmentItemInfo.weight - teamScore

  const calculatedBy = slots
    .map((slot): SkilItemProgressCalculatedBy => {
      const dataPoint = slot.slotDataPoints.find((dp) => dp?.id === uid)
      const slotId = slot.id
      // @ts-ignore
      if (!dataPoint) return

      const normalized = skillDevelopmentItemInfo.getValueForSlotDataPoint({ dataPoint, slots, uid, session }, f)

      if (skillDevelopmentItemInfo.calculatedBy) {
        const children = skillDevelopmentItemInfo.calculatedBy.map(({ key, weight, getValueForSlotDataPoint }) => {
          const normalized = getValueForSlotDataPoint(dataPoint, f)

          return {
            slotId,
            key,
            weight,
            value: dataPoint[key] as unknown as number,
            normalized,
            viktas: normalized * weight
          }
        })

        return {
          slotId,
          weight: skillDevelopmentItemInfo.weight,
          key: skillDevelopmentItemInfo.metaDataPointKey,
          normalized,
          value: 0,
          viktas: normalized * skillDevelopmentItemInfo.weight,
          children
        }
      }

      invariant(skillDevelopmentItemInfo.metaDataPointKey, 'dataPointKey is required')

      return {
        slotId,
        key: skillDevelopmentItemInfo.metaDataPointKey,
        weight: skillDevelopmentItemInfo.weight,
        value: dataPoint[skillDevelopmentItemInfo.metaDataPointKey] as unknown as number,
        normalized,
        viktas: normalized * skillDevelopmentItemInfo.weight,
        children: [] as SkillItemProgressCalculatedByItem[]
      }
    })
    .filter((x) => x)

  return {
    ...DefaultSkillItemProgress,
    metaData: {
      calculatedBy
    },
    uid,
    yourPotential,
    teamPotential,
    teamScore,
    yourScore,
    yourScoreRatio,
    yourScoreNormalized: yourScore / skillDevelopmentItemInfo.weight,
    teamDisplayValue,
    userDisplayValue,
    userDisplayValuePerSlot: userDisplayValue / slots.length,
    formattedTeamDisplayValue,
    formattedUserDisplayValue,
    teamRelativeProgressPercentage: teamRelativeProgress ? toPercent(teamRelativeProgress) : undefined,
    teamRelativeProgressPercentageProgressLevel: teamRelativeProgress
      ? getRelativeProgressPercentageProgressLevel(teamRelativeProgress)
      : undefined,
    yourRelativeProgressPercentage: yourRelativeProgress ? toPercent(yourRelativeProgress) : undefined,
    yourRelativeProgressPercentageProgressLevel: yourRelativeProgress
      ? getRelativeProgressPercentageProgressLevel(yourRelativeProgress)
      : undefined,
    _skillDevelopmentItemInfo: skillDevelopmentItemInfo
  }
}

export const toPercent = (n: number) => Math.round(n * 1000) / 10

const DefaultSkillItem = {
  descriptionText: '',
  userSkillScore: 0,
  teamSkillScore: 0,
  userProgressLevel: ProgressLevel.same,
  teamProgressLevel: ProgressLevel.same,
  metaData: {
    calculatedBy: []
  },
  userTop1Potential: {} as SessionInfoPotential
}

const DefaultSkillItemProgress = {
  teamScore: 0, // OK
  yourScorePercentage: 0, // OK
  teamRelativeProgressPercentage: 0, // NOK
  teamRelativeProgressPercentageProgressLevel: ProgressLevel.same, // NOK
  yourRelativeProgressPercentage: 0, // NOK
  yourRelativeProgressPercentageProgressLevel: ProgressLevel.same
}

const DefaultSessionInfo = {
  name: `Session`.trim(),
  userOverallScore: 0,
  userProgressLevel: ProgressLevel.same
}

function getDisplayValueForSlots(
  {
    slots,
    uid,
    skillDevelopmentItemInfo,
    session
  }: {
    slots: Slot[]
    uid?: string
    skillDevelopmentItemInfo: SkillDevelopmentItemInfo
    session?: Session
  },
  f: GetSessionInfoOptions
) {
  const { getValueForSlotDataPoint } = skillDevelopmentItemInfo
  const { getDisplayValue: getDisplayValueForSlotDataPoint = getValueForSlotDataPoint } = skillDevelopmentItemInfo

  return slots
    .flatMap((s) => s.slotDataPoints)
    .filter((dp) => !uid || dp.id === uid)
    .reduce((a: number, dp) => a + getDisplayValueForSlotDataPoint({ dataPoint: dp, slots, uid, session }, f), 0)
}

export function getPreviousSession<T extends { createdAt: string }>(session: T, sessions: T[]): T {
  return sessions
    .filter((s) => dayjs(s.createdAt).isBefore(session.createdAt))
    .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())[0]
}

export function getProgressLevelForUserOverallScore({
  sessionInfo,
  prevSessionInfo,
  uid
}: {
  sessionInfo?: SessionInfo
  prevSessionInfo?: SessionInfo
  uid: string
}) {
  if (!sessionInfo) return ProgressLevel.same
  const skillScore = getUserOverallScore(sessionInfo)
  const otherSkillScores = prevSessionInfo ? [getUserOverallScore(prevSessionInfo)] : []

  return getProgressLevelForSkillDevelopment({
    skillScore,
    otherSkillScores,
    skillInfoItem: SkillsInfoItemOverallScore,
    session: sessionInfo.session,
    uid
  })
}

export const getUserOverallScore = (sessionInfo: SessionInfo): number =>
  Object.entries(sessionInfo.skills)
    .filter(([key]) => [Skill.DecisionMaking, Skill.HelpingAndAssisting, Skill.Communication].includes(key as Skill))
    .map(([_, value]) => value)
    .reduce((acc, skill, i, arr) => acc + skill.userSkillScore / arr.length, 0)
