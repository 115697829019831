
import {ReactComponent as goBackImg} from './../../assets/images/goBack.svg'
export const goBack = goBackImg 
import {ReactComponent as iconClosePageImg} from './../../assets/images/iconClosePage.svg'
export const iconClosePage = iconClosePageImg 
import {ReactComponent as iconResultsImg} from './../../assets/images/iconResults.svg'
export const iconResults = iconResultsImg 
import {ReactComponent as iconUridiumCollectedImg} from './../../assets/images/iconUridiumCollected.svg'
export const iconUridiumCollected = iconUridiumCollectedImg 
import {ReactComponent as collaborationProgressSessionTaskPerformanceEventItemTeamIconImg} from './../../assets/images/collaborationProgressSessionTaskPerformanceEventItemTeamIcon.svg'
export const collaborationProgressSessionTaskPerformanceEventItemTeamIcon = collaborationProgressSessionTaskPerformanceEventItemTeamIconImg 
import {ReactComponent as groupImg} from './../../assets/images/group.svg'
export const group = groupImg 
import {ReactComponent as iconHypothermiaImg} from './../../assets/images/iconHypothermia.svg'
export const iconHypothermia = iconHypothermiaImg 
import {ReactComponent as iconAbilityUsedImg} from './../../assets/images/iconAbilityUsed.svg'
export const iconAbilityUsed = iconAbilityUsedImg 
import {ReactComponent as iconDroneUsedImg} from './../../assets/images/iconDroneUsed.svg'
export const iconDroneUsed = iconDroneUsedImg 
import {ReactComponent as iconRobotsUnlockedImg} from './../../assets/images/iconRobotsUnlocked.svg'
export const iconRobotsUnlocked = iconRobotsUnlockedImg 
import {ReactComponent as iconPeopleHealedImg} from './../../assets/images/iconPeopleHealed.svg'
export const iconPeopleHealed = iconPeopleHealedImg 
import {ReactComponent as collaborationSessionItemTaskPerformanceImg} from './../../assets/images/collaborationSessionItemTaskPerformance.svg'
export const collaborationSessionItemTaskPerformance = collaborationSessionItemTaskPerformanceImg 
import {ReactComponent as fullScreenImg} from './../../assets/images/fullScreen.svg'
export const fullScreen = fullScreenImg 
import {ReactComponent as menuIconOnboardingImg} from './../../assets/images/menuIconOnboarding.svg'
export const menuIconOnboarding = menuIconOnboardingImg 
import {ReactComponent as menuIconDashboardImg} from './../../assets/images/menuIconDashboard.svg'
export const menuIconDashboard = menuIconDashboardImg 
import {ReactComponent as menuIconCollaborationProgressImg} from './../../assets/images/menuIconCollaborationProgress.svg'
export const menuIconCollaborationProgress = menuIconCollaborationProgressImg 
import {ReactComponent as menuIconTheoryBehindImg} from './../../assets/images/menuIconTheoryBehind.svg'
export const menuIconTheoryBehind = menuIconTheoryBehindImg 
import {ReactComponent as menuIconPlayGameImg} from './../../assets/images/menuIconPlayGame.svg'
export const menuIconPlayGame = menuIconPlayGameImg 
import {ReactComponent as iconsTeamImg} from './../../assets/images/iconsTeam.svg'
export const iconsTeam = iconsTeamImg 
import {ReactComponent as collaborationSessionItemCommunicationImg} from './../../assets/images/collaborationSessionItemCommunication.svg'
export const collaborationSessionItemCommunication = collaborationSessionItemCommunicationImg 
import {ReactComponent as collaborationSessionItemHelpingImg} from './../../assets/images/collaborationSessionItemHelping.svg'
export const collaborationSessionItemHelping = collaborationSessionItemHelpingImg 
import {ReactComponent as collaborationSessionItemDecisionMakingImg} from './../../assets/images/collaborationSessionItemDecisionMaking.svg'
export const collaborationSessionItemDecisionMaking = collaborationSessionItemDecisionMakingImg 
import {ReactComponent as icons8Google1Img} from './../../assets/images/icons8Google1.svg'
export const icons8Google1 = icons8Google1Img 
import {ReactComponent as progressIconImg} from './../../assets/images/progressIcon.svg'
export const progressIcon = progressIconImg 
import {ReactComponent as bigPositiveChangeImg} from './../../assets/images/bigPositiveChange.svg'
export const bigPositiveChange = bigPositiveChangeImg 
import {ReactComponent as majorUpArrowImg} from './../../assets/images/majorUpArrow.svg'
export const majorUpArrow = majorUpArrowImg 
import {ReactComponent as smallPositiveChangeImg} from './../../assets/images/smallPositiveChange.svg'
export const smallPositiveChange = smallPositiveChangeImg 
import {ReactComponent as minorUpArrowImg} from './../../assets/images/minorUpArrow.svg'
export const minorUpArrow = minorUpArrowImg 
import {ReactComponent as smallNegativeChangeImg} from './../../assets/images/smallNegativeChange.svg'
export const smallNegativeChange = smallNegativeChangeImg 
import {ReactComponent as minorDownArrowImg} from './../../assets/images/minorDownArrow.svg'
export const minorDownArrow = minorDownArrowImg 
import {ReactComponent as bigNegativeChangeImg} from './../../assets/images/bigNegativeChange.svg'
export const bigNegativeChange = bigNegativeChangeImg 
import {ReactComponent as majorDownArrowImg} from './../../assets/images/majorDownArrow.svg'
export const majorDownArrow = majorDownArrowImg 
import {ReactComponent as noChangeImg} from './../../assets/images/noChange.svg'
export const noChange = noChangeImg 
import {ReactComponent as unchangedArrowImg} from './../../assets/images/unchangedArrow.svg'
export const unchangedArrow = unchangedArrowImg 
import {ReactComponent as noInfoForChangeImg} from './../../assets/images/noInfoForChange.svg'
export const noInfoForChange = noInfoForChangeImg 
import {ReactComponent as icondefaultImg} from './../../assets/images/icondefault.svg'
export const icondefault = icondefaultImg 
import {ReactComponent as placeholderImg} from './../../assets/images/placeholder.svg'
export const placeholder = placeholderImg 
import {ReactComponent as team2Img} from './../../assets/images/team2.svg'
export const team2 = team2Img 
import {ReactComponent as team1Img} from './../../assets/images/team1.svg'
export const team1 = team1Img 
import {ReactComponent as uridiumImg} from './../../assets/images/uridium.svg'
export const uridium = uridiumImg 
import {ReactComponent as tempImg} from './../../assets/images/temp.svg'
export const temp = tempImg 
import {ReactComponent as abilityImg} from './../../assets/images/ability.svg'
export const ability = abilityImg 
import {ReactComponent as droneImg} from './../../assets/images/drone.svg'
export const drone = droneImg 
import {ReactComponent as robotImg} from './../../assets/images/robot.svg'
export const robot = robotImg 
import {ReactComponent as healImg} from './../../assets/images/heal.svg'
export const heal = healImg 
import {ReactComponent as communicationBlackImg} from './../../assets/images/communicationBlack.svg'
export const communicationBlack = communicationBlackImg 
import {ReactComponent as imgCommunicationWhiteImg} from './../../assets/images/imgCommunicationWhite.svg'
export const imgCommunicationWhite = imgCommunicationWhiteImg 
import {ReactComponent as imgTimeSpentImg} from './../../assets/images/imgTimeSpent.svg'
export const imgTimeSpent = imgTimeSpentImg 
import {ReactComponent as imgTimeSpentTalkingWhiteImg} from './../../assets/images/imgTimeSpentTalkingWhite.svg'
export const imgTimeSpentTalkingWhite = imgTimeSpentTalkingWhiteImg 
import {ReactComponent as imgCommunicationRecieptBlackImg} from './../../assets/images/imgCommunicationRecieptBlack.svg'
export const imgCommunicationRecieptBlack = imgCommunicationRecieptBlackImg 
import {ReactComponent as imgCommunicationRecieptWhiteImg} from './../../assets/images/imgCommunicationRecieptWhite.svg'
export const imgCommunicationRecieptWhite = imgCommunicationRecieptWhiteImg 
import {ReactComponent as imgDecisionMakingBlackImg} from './../../assets/images/imgDecisionMakingBlack.svg'
export const imgDecisionMakingBlack = imgDecisionMakingBlackImg 
import {ReactComponent as imgTimesInteruptedWhiteImg} from './../../assets/images/imgTimesInteruptedWhite.svg'
export const imgTimesInteruptedWhite = imgTimesInteruptedWhiteImg 
import {ReactComponent as imgTImesInterruptedWhiteImg} from './../../assets/images/imgTImesInterruptedWhite.svg'
export const imgTImesInterruptedWhite = imgTImesInterruptedWhiteImg 
import {ReactComponent as partOfTeamAirtimeImg} from './../../assets/images/partOfTeamAirtime.svg'
export const partOfTeamAirtime = partOfTeamAirtimeImg 
import {ReactComponent as circumplexReportedImg} from './../../assets/images/circumplexReported.svg'
export const circumplexReported = circumplexReportedImg 
import {ReactComponent as whiteCheckBoxImg} from './../../assets/images/whiteCheckBox.svg'
export const whiteCheckBox = whiteCheckBoxImg 
import {ReactComponent as timesTalkedImg} from './../../assets/images/timesTalked.svg'
export const timesTalked = timesTalkedImg 
import {ReactComponent as imgHelpBackImg} from './../../assets/images/imgHelpBack.svg'
export const imgHelpBack = imgHelpBackImg 
import {ReactComponent as property234Img} from './../../assets/images/property234.svg'
export const property234 = property234Img 
import {ReactComponent as letOthersSpeakImg} from './../../assets/images/letOthersSpeak.svg'
export const letOthersSpeak = letOthersSpeakImg 
import {ReactComponent as tryToListenImg} from './../../assets/images/tryToListen.svg'
export const tryToListen = tryToListenImg 
import {ReactComponent as howToActImg} from './../../assets/images/howToAct.svg'
export const howToAct = howToActImg 
import {ReactComponent as taskPerformanceImg} from './../../assets/images/taskPerformance.svg'
export const taskPerformance = taskPerformanceImg 
import {ReactComponent as imgEmotionalBlackImg} from './../../assets/images/imgEmotionalBlack.svg'
export const imgEmotionalBlack = imgEmotionalBlackImg 
import {ReactComponent as imgEmotionalWhiteImg} from './../../assets/images/imgEmotionalWhite.svg'
export const imgEmotionalWhite = imgEmotionalWhiteImg 
import {ReactComponent as infoImg} from './../../assets/images/info.svg'
export const info = infoImg 
import {ReactComponent as loopImg} from './../../assets/images/loop.svg'
export const loop = loopImg 
import {ReactComponent as coordinationImg} from './../../assets/images/coordination.svg'
export const coordination = coordinationImg 
import {ReactComponent as shareResourcesImg} from './../../assets/images/shareResources.svg'
export const shareResources = shareResourcesImg 
import {ReactComponent as imgResourcesSharedWhiteImg} from './../../assets/images/imgResourcesSharedWhite.svg'
export const imgResourcesSharedWhite = imgResourcesSharedWhiteImg 
import {ReactComponent as robotUnlockImg} from './../../assets/images/robotUnlock.svg'
export const robotUnlock = robotUnlockImg 
import {ReactComponent as abilitiesSharedImg} from './../../assets/images/abilitiesShared.svg'
export const abilitiesShared = abilitiesSharedImg 
import {ReactComponent as imgAbilitiesSharedWhiteImg} from './../../assets/images/imgAbilitiesSharedWhite.svg'
export const imgAbilitiesSharedWhite = imgAbilitiesSharedWhiteImg 
import {ReactComponent as timeManagementImg} from './../../assets/images/timeManagement.svg'
export const timeManagement = timeManagementImg 
import {ReactComponent as paralysisImg} from './../../assets/images/paralysis.svg'
export const paralysis = paralysisImg 
import {ReactComponent as imgPrioritizedDecisionMakingWhiteImg} from './../../assets/images/imgPrioritizedDecisionMakingWhite.svg'
export const imgPrioritizedDecisionMakingWhite = imgPrioritizedDecisionMakingWhiteImg 
import {ReactComponent as riskProfileImg} from './../../assets/images/riskProfile.svg'
export const riskProfile = riskProfileImg 
import {ReactComponent as imgRiskProfileWhiteImg} from './../../assets/images/imgRiskProfileWhite.svg'
export const imgRiskProfileWhite = imgRiskProfileWhiteImg 
import {ReactComponent as imgEditImg} from './../../assets/images/imgEdit.svg'
export const imgEdit = imgEditImg 
import {ReactComponent as imgDeleteImg} from './../../assets/images/imgDelete.svg'
export const imgDelete = imgDeleteImg 
import {ReactComponent as logoImg} from './../../assets/images/logo.svg'
export const logo = logoImg 
import pngLogoImg from './../../assets/images/pngLogo.png'
export const pngLogo = pngLogoImg 
import {ReactComponent as backIconImg} from './../../assets/images/backIcon.svg'
export const backIcon = backIconImg 