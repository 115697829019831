import styled from '@emotion/styled'
import { MainColor, MainFrameBG } from '../../../pure-js/libs/Colors'
import { PRIVACY_POLICY_URL } from '../../../pure-js/libs/Consts'
import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, reallyBigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { LoginConatinerWidth } from '../libs/HardCodedSizes'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FigmaTextLoader from './FigmaTextLoader'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { Iframe } from './Iframe'
import { Layout } from './Layout'
import { SignInLandingIntractionLogo } from './SignInLandingIntractionLogo'

export default function SignedInTemsAndConditions(props: SignedInViewProps) {
  return (
    <Layout>
      <Box fullWidth align="center">
        <Box top spacing={reallyBigSpacing}>
          <SignInLandingIntractionLogo />
        </Box>
        <Box top spacing={reallyBigSpacing} align="center">
          <Box top spacing={smallSpacing} width={`${LoginConatinerWidth - 100}px`}>
            <FigmaText textKey={Texts.onboardingPrivacyPagePrivacyPageHeaderText} />
            <Box top spacing={smallSpacing}>
              <FigmaTextWithLinebreaks textKey={Texts.onboardingPrivacyPagePrivacyPageSecondaryText} />
            </Box>
          </Box>
        </Box>
        <Box top spacing={bigSpacing}>
          <Container fullWidth fullPadding>
            <Box>
              <FigmaText textKey={Texts.onboardingPrivacyPagePrivacyContentHeader} />
            </Box>
            <PrivacyPolicyContainer fullPadding spacing={tinySpacing} multiline fullWidth>
              <Iframe url={PRIVACY_POLICY_URL} name="Intraction Privacy Policy" />
            </PrivacyPolicyContainer>
            <Box top fullWidth>
              <Box fullWidth left right>
                <Button
                  fullWidth
                  onClick={() => props.onPressContinue(props.signedInState)}
                  textKey={Texts.onboardingPrivacyPageCtaApprovePrivacy}
                >
                  <FigmaTextLoader textKey={Texts.onboardingPrivacyPageCtaApprovePrivacy} />
                </Button>
              </Box>
            </Box>
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${MainColor};
  border-radius: ${BorderRadixes.big};
  width: ${LoginConatinerWidth}px;
`

const PrivacyPolicyContainer = styled(Box)`
  overflow-y: auto;
  background-color: ${MainFrameBG};
  height: 340px;
`
