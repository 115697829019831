import dayjs from 'dayjs'
import { useEffect } from 'react'
import { createUniqueId } from '../../../pure-js/libs/Common'
import { YYYY_MM_DD } from '../../../pure-js/libs/Consts'
import { Session } from '../../../pure-js/types/Session'
import { upsertSession } from '../libs/DBApiHandler'
import { _useSessions } from './QueryHooks'
import { useUser } from './useUser'
import { useTeamId } from './useTeamId'

const newSessionId = createUniqueId()

export const useSessionId = () => {
  const { data: user } = useUser()
  const teamId = useTeamId()
  const q = _useSessions(teamId)
  const { data: sessions = [], isLoading } = q

  const lastActiveSessionId = getLastActiveSessionId(sessions)

  useEffect(() => {
    if (!user.team) return
    if (isLoading) return
    // TODO WRITE TEST, should create session if no session exists
    if (!lastActiveSessionId) upsertSession({ teamId: user.team, id: newSessionId, status: 'active' })
  }, [isLoading, teamId, lastActiveSessionId])

  if (isLoading) return { data: undefined, isLoading }

  return { data: getLastActiveSessionId(sessions), isLoading }
}

// TODO: Write should get last active session for today
export function getLastActiveSessionId(sessions: Session[]) {
  return sessions
    .filter((session) => session.status === 'active')
    .filter((session) => dayjs(session.createdAt).format(YYYY_MM_DD) === dayjs().format(YYYY_MM_DD))
    .sort((a, b) => dayjs(b.createdAt).valueOf() - dayjs(a.createdAt).valueOf())[0]?.id
}
