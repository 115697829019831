import dayjs from 'dayjs'
import { Questionnaire, QuestionnaireAnswer, QuestionnaireContext } from '../../../pure-js/types/Questionnaire'
import * as DBApiHandler from './DBApiHandler'

export const setQuestionnaire = async ({
  userId,
  context
}: {
  userId: string
  context: QuestionnaireContext
}): Promise<Questionnaire> => DBApiHandler.updateQuestionnaire({ id: `${userId}_${context}`, userId, context })

export type QuestionareQueryInputParams = {
  userId: string
  context: QuestionnaireContext
}

export const getQuesionaireQuery = ({ userId, context }: QuestionareQueryInputParams) =>
  DBApiHandler.getQuesionaireQuery({ id: `${userId}_${context}` })

export const updateQuestionareAnswer = async ({
  questionId,
  questionaire,
  answer,
  slotId,
  textKeyName
}: {
  questionaire: Questionnaire
  questionId: string
  userId: string
  answer: string
  textKeyName: string
  slotId?: string
}): Promise<QuestionnaireAnswer> =>
  DBApiHandler.updateQuestionareAnswer(
    {
      answer,
      timestamp: dayjs().format(),
      questionId: `${slotId || 'onboarding'}_${questionId}`,
      textKeyName
    },
    questionaire.id
  )

export const getQuestionareAnswerQuery = ({
  questionId,
  questionaireId,
  slotId
}: {
  questionaireId: string
  questionId: string
  slotId?: string
}) =>
  DBApiHandler.getQuestionareAnswerQuery({
    questionId: `${slotId || 'onboarding'}_${questionId}`,
    questionaireId
  })
