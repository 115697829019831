/* eslint-disable @typescript-eslint/no-unused-vars */
import styled from '@emotion/styled'
import { Black, MainColor, SecondaryText, SecondayColor, White } from '../../../pure-js/libs/Colors'
import { getInitials } from '../../../pure-js/libs/UserWidgetHelper'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { User } from '../../../pure-js/types/User'
import { isPropValid } from '../libs/EmotionHelper'
import { USER_WIDGET_SIZE } from '../libs/HardCodedSizes'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'

const SIZE = USER_WIDGET_SIZE

export type DashboardMyTeamsUserWidgetProps = {
  user: User
  size?: number
}

export const DashboardMyTeamUserItemUserWidget = ({ user, size }: DashboardMyTeamsUserWidgetProps) => (
  <DashboardMyTeamsUserWidget
    text={getInitialsForUser(user)}
    textKey={Texts.componentsTeamListTeamListOtherUserInitials}
    backgroundColor={SecondaryText}
    size={size}
  />
)

export const DashboardMyTeamUserItemLoggedInUserWidget = ({ user, size }: DashboardMyTeamsUserWidgetProps) => (
  <DashboardMyTeamsUserWidget
    text={getInitialsForUser(user)}
    textKey={Texts.cssUserWidget}
    backgroundColor={MainColor}
    size={size}
  />
)

export const DashboardMyTeamUserItemNotLoggedInUserWidget = (p: DashboardMyTeamsUserWidgetProps) => (
  <DashboardMyTeamsUserWidget
    text={getInitialsForUser(p.user)}
    textKey={Texts.componentsTeamListTeamListOtherUserInitials}
    backgroundColor={SecondaryText}
    size={p.size}
  />
)

export const DashboardMyTeamUserWidgetInviteUser = (p: DashboardMyTeamsUserWidgetProps) => (
  <DashboardMyTeamsUserWidget
    textKey={Texts.componentsTeamListTeamListOtherUserInitials}
    backgroundColor={SecondayColor}
    text="?"
    size={p.size}
  />
)

export const DashboardMyTeamsUserWidget = ({
  textKey,
  backgroundColor,
  text,
  size = USER_WIDGET_SIZE
}: {
  backgroundColor: string
  textKey: TextKey
  text: string
  size?: number
}) => {
  return (
    <Container align="center" justify="center" $backgroundColor={backgroundColor} $size={size}>
      <UserWidgetContent textKey={textKey} text={text} />
    </Container>
  )
}

export const UserWidgetContent = ({ textKey, text }: { textKey: TextKey; text: string }) => {
  return <FigmaText textKey={textKey} text={text} />
}

const Container = styled(Box, { shouldForwardProp: isPropValid })<{
  $backgroundColor: string
  $size: number
}>`
  width: ${({ $size: size = SIZE }) => size}px;
  height: ${({ $size: size = SIZE }) => size}px;
  border-radius: 50%;
  background-color: ${({ $backgroundColor }) => $backgroundColor};
`

export function getInitialsForUser(user: User): string {
  const txt = user?.displayName || user?.email?.split('@')[0] || ''
  return getInitials(txt)
}
