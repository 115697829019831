import OBPTexts from '../obp/texts.json'
import { Black } from './Colors'

export const TextsDeprecated = { ...OBPTexts }
// export const TextsDeprecated = { ...OBPTexts }

export const DeprecatedTextKeys = {
  txtMyPageSegmentTheoryBehindHeader: {
    characters: 'Theory behind the training',
    name: 'txtMyPageSegmentTheoryBehindHeader',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'App'
  },
  txtButtonReadMore: {
    characters: 'Read more',
    name: 'txtButtonReadMore',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'App'
  },
  txtEmailTextFieldHelperText: {
    characters: 'Email',
    name: 'txtEmailTextFieldHelperText',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(155, 155, 155, 1)'
    },
    pageName: 'App'
  },
  txtPrivacyPolicy: {
    characters:
      '1. Introduction \nWelcome to [Company Name] ("we," "our," "us"). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and share your information when you use our services.  \n\n2. Information \nWe Collect We may collect the following types of information: Personal Information: Name, email address, phone number, and other contact details. Usage Data: Information about how you use our services, including IP address, browser type, and pages visited. Cookies and Tracking Technologies: Data collected through cookies and similar technologies to enhance your experience.   \n\n3. How We Use Your Information \nWe use your information for the following purposes: To provide and maintain our services To improve, personalize, and expand our services To communicate with you, including responding to your inquiries\n\n1. Introduction \nWelcome to [Company Name] ("we," "our," "us"). We are committed to protecting your privacy and ensuring the security of your personal information. This Privacy Policy explains how we collect, use, and share your information when you use our services.  \n\n2. Information \nWe Collect We may collect the following types of information: Personal Information: Name, email address, phone number, and other contact details. Usage Data: Information about how you use our services, including IP address, browser type, and pages visited. Cookies and Tracking Technologies: Data collected through cookies and similar technologies to enhance your experience.   \n\n3. How We Use Your Information \nWe use your information for the following purposes: To provide and maintain our services To improve, personalize, and expand our services To communicate with you, including responding to your inquiries',
    name: 'txtPrivacyPolicy',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'App'
  },
  txtMyPageSegmentMyTeamHeader: {
    characters: 'My team:',
    name: 'txtMyPageSegmentMyTeamHeader',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'App'
  },
  txtMyPageSegmentLatestSessionHeader: {
    characters: 'Latest session:',
    name: 'txtMyPageSegmentLatestSessionHeader',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'App'
  },
  txtSessionLiteItemText: {
    characters: 'Session xxx',
    name: 'txtSessionLiteItemText',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(255, 255, 255, 1)'
    },
    pageName: 'App',
    styleOverrideTable: {
      '1': {
        fontFamily: 'IBM Plex Sans',
        color: 'rgba(255, 255, 255, 1)'
      }
    },
    characterStyleOverrides: [1, 1, 1, 1, 1, 1, 1]
  },
  txtButtonView: {
    characters: 'View',
    name: 'txtButtonView',
    texts: {},
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 700,
      letterSpacing: 0,
      color: 'rgba(26, 42, 52, 1)'
    },
    pageName: 'App'
  }
}

export const OldTextKeys = {
  pageHeader: {
    name: 'pageHeader',
    characters: 'Låt Gazelle skriva din annons',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '40px',
      fontWeight: 400,
      letterSpacing: 0,
      color: Black
    }
  },
  cssHomeFormSubHeader: {
    name: 'cssHomeFormSubHeader',
    characters: '',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '18px',
      fontWeight: 400,
      letterSpacing: 0,
      color: Black
    }
  },
  cssHomeFormDescription: {
    name: 'cssHomeFormDescription',
    characters: '',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  copyYourPropertyInformation: {
    name: 'copyYourPropertyInformation',
    characters: 'Describe the object',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '22px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  selectYourStyle: {
    name: 'selectYourStyle',
    characters: 'Select your style',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '22px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  selectOutputLanguage: {
    name: 'selectOutputLanguage',
    characters: 'Select language',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '22px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  textOptions: {
    name: 'textOptions',
    characters: 'Text options',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '22px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  userMenuDeleteUser: {
    name: 'userMenuDeleteUser',
    characters: 'Delete account',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '12px',
      fontWeight: 400,
      letterSpacing: '0.4000000059604645px',
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  applicationMenuHomeButtonText: {
    name: 'applicationMenuHomeButtonText',
    characters: 'Home',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0.10000000149011612px',
      color: 'rgba(27, 27, 30, 1)'
    }
  },
  applicationMenuSettingsText: {
    name: 'applicationMenuSettingsText',
    characters: 'Settings',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: 500,
      letterSpacing: '0.10000000149011612px',
      color: 'rgba(27, 27, 30, 1)'
    }
  },
  homeFormNumberOfParagraphs: {
    name: 'homeFormNumberOfParagraphs',
    characters: 'Number of paragraphs',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '16px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  cssGeneratedBio: {
    name: 'cssGeneratedBio',
    characters: '',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  cssGeneratedHeader: {
    name: 'cssGeneratedHeader',
    characters: '',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.25px',
      color: 'rgba(0, 0, 0, 1)',
      textDecoration: 'underline'
    }
  },
  genericDialogButtonSecondaryButtonText: {
    name: 'genericDialogButtonSecondaryButtonText',
    characters: 'Cancel',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      color: 'rgba(0,0,0,1)'
    },
    pageName: 'Integrations',
    figmaTextKeyName: 'cssGenericDialogButtonSecondaryButtonText'
  },
  signInUpsellHeader: {
    name: 'signInUpsellHeader',
    characters: 'Vill du frigöra tid?',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '40px',
      fontWeight: 400,
      letterSpacing: 0,
      color: Black
    }
  },
  signInUpsellDescription: {
    name: 'signInUpsellDescription',
    characters: 'Skapa en bostadsannons på en minut',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '24px',
      fontWeight: 400,
      letterSpacing: 0,
      color: Black
    }
  },
  secureLinkMailFooter: {
    name: 'secureLinkMailFooter',
    characters:
      '\n\nIf you did not request this secure link, you can safely ignore this email.\n\nThanks,\n\nYour Gazelle team\ninfo@gazelle.work',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  homeForPropertyTextValidationText: {
    name: 'homeForPropertyTextValidationText',
    characters: 'Please add more text to your description',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  },
  cssSettingsValue: {
    name: 'cssSettingsValue',
    characters: 'Indicator',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 600,
      letterSpacing: '0.25px',
      color: 'rgba(27, 27, 30, 1)'
    },
    pageName: 'New base',
    figmaTextKeyName: 'TXT_cssObjectiveListIndicatorRowSummaryText'
  },
  cssNormalText: {
    name: 'cssNormalText',
    characters: 'Indicator',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      color: 'rgba(27, 27, 30, 1)'
    },
    pageName: 'New base',
    figmaTextKeyName: 'TXT_cssObjectiveListIndicatorRowSummaryText'
  },
  cssNormalTextUnderline: {
    name: 'cssNormalTextUnderline',
    characters: 'Indicator',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: '0.25px',
      color: 'rgba(27, 27, 30, 1)',
      textDecoration: 'underline'
    },
    pageName: 'New base',
    figmaTextKeyName: 'TXT_cssObjectiveListIndicatorRowSummaryText'
  },
  emailTemplateSupportContact: {
    name: 'emailTemplateSupportContact',
    characters: 'Mail us: support@gazelle.work',
    style: {
      fontFamily: 'IBM Plex Sans',
      fontSize: '14px',
      fontWeight: 400,
      letterSpacing: 0,
      color: 'rgba(0, 0, 0, 1)'
    }
  }
}

export const TextKeysWithOnlyTexts = {
  generateYourAdd: {
    name: 'generateYourAdd',
    characters: 'Generate your ad'
  },
  yourGeneratedAd: {
    name: 'yourGeneratedAd',
    characters: 'Your generated ad'
  },
  copyWholeAddToClipbpard: {
    name: 'copyWholeAddToClipbpard',
    characters: 'Copy whole ad to clipboard'
  },
  homeFormFooter: {
    name: 'homeFormFooter',
    characters: 'Powered by Gazelle Technology 2023'
  },
  applicationMenuFooterChatWithUsButtonText: {
    name: 'applicationMenuFooterChatWithUsButtonText',
    characters: 'Chat'
  },
  homeFormTabsOverview: {
    name: 'homeFormTabsOverview',
    characters: 'Overview'
  },
  homeFormTabsInterior: {
    name: 'homeFormTabsInterior',
    characters: 'Interior'
  },
  homeFormTabsExterior: {
    name: 'homeFormTabsExterior',
    characters: 'Exterior'
  },
  homeFormTabsArea: {
    name: 'homeFormTabsArea',
    characters: 'Area'
  },
  homeFormInteriorDescribeHeader: {
    name: 'homeFormInteriorDescribeHeader',
    characters: 'Beskriv Interiören'
  },
  homeFormInteriorDescribeDescription: {
    name: 'homeFormInteriorDescribeDescription',
    characters: 'Inkludera information om varje rum. Exempeltext:'
  },
  homeFormExteriorDescribeHeader: {
    name: 'homeFormExteriorDescribeHeader',
    characters: 'Beskriv Exteriören'
  },
  homeFormExteriorDescribeDescription: {
    name: 'homeFormExteriorDescribeDescription',
    characters: 'Inkludera information om t ex trädgård, garage,... Exempel:'
  },
  homeFormAreaDescribeHeader: {
    name: 'homeFormAreaDescribeHeader',
    characters: 'Beskriv området'
  },
  homeFormAreaDescribeDescription: {
    name: 'homeFormAreaDescribeDescription',
    characters: 'Beskriv området och om möjligt, nämn något du vill ska stå med. '
  },
  homeFormResponseStopGeneratingButton: {
    name: 'homeFormResponseStopGeneratingButton',
    characters: 'Stop generating'
  },
  homeFormPlaceholderOverview: {
    name: 'homeFormPlaceholderOverview',
    characters:
      'Villa, high location with no view, distributed over two levels. Open floor plan between kitchen and living room and solid material choices. Waterborne underfloor heating, ceiling height to ridge and spotlights in the ceiling in large parts of the home. The garden is lush, pool and flat surfaces.'
  },
  homeFormPlaceholderInterior: {
    name: 'homeFormPlaceholderInterior',
    characters:
      'The hall has a dark tiled floor and white walls, the kitchen is next to the hall and has oak parquet and a white kitchen from IKEA, lots of storage. Two bedrooms on the entrance floor, with oak parquet and beige walls. Large family room that goes together with the kitchen. The bathroom is fully tiled with white tiles and gray tiles. The laundry room is spacious with a washing machine, dryer and drying cabinet. The upper floor has oak parquet throughout and consists of two good bedrooms on either side, these with oak parquet and white walls in the family room and a fully tiled bathroom.'
  },
  homeFormPlaceholderExterior: {
    name: 'homeFormPlaceholderExterior',
    characters:
      'Trädgården är lummig, insynsskyddad och plan. Vacker natur runt omkring med utsikt över landskap och omgivningen runt om. Dubbelgarage med plats för två bilar med portautomatik. Asfalterad uppfart. '
  },
  homeFormPlaceholderArea: {
    name: 'homeFormPlaceholderArea',
    characters: 'Kungshöjd in Gothenburg, close to transport links.'
  },
  homeFormMoreParametersHeader: {
    name: 'homeFormMoreParametersHeader',
    characters: 'Anpassa (frivilligt)'
  },
  homeFormOptionsMobile: {
    name: 'homeFormOptionsMobile',
    characters: 'Options'
  },
  homeFormUserOverQuota: {
    name: 'homeFormUserOverQuota',
    characters:
      'You have reached the maximum number of free trials (10). In order to continue using our service, please register a payment method to become a member.'
  },
  homeFormShowMoreParameters: {
    name: 'homeFormShowMoreParameters',
    characters: 'Show more options'
  },
  homeFormShowLessParameters: {
    name: 'homeFormShowLessParameters',
    characters: 'Show fewer options'
  },
  applicationMenuUserText: {
    name: 'applicationMenuUserText',
    characters: 'User'
  },
  applicationMenuFAQ: {
    name: 'applicationMenuFAQ',
    characters: 'FAQ'
  },
  homeFormDescribeObjectHeader: {
    name: 'homeFormDescribeObjectHeader',
    characters: 'Beskriv objektet'
  },
  homeFormDescribeObjectDescription: {
    name: 'homeFormDescribeObjectDescription',
    characters: 'Beskriv med korta meningar det som är viktigast med bostaden, läget och interiören'
  },
  homeFormPropertyInfoPlaceholder: {
    name: 'homeFormPropertyInfoPlaceholder',
    characters:
      'Example text (add your own here): \nCentral location in Stockholm. 140 m2 with high ceilings. Fireplace balcony and spacious inner yard. Kitchen renovated in 2021.',
    style: {}
  },
  settingsGetPremiumCTA: {
    name: 'settingsGetPremiumCTA',
    characters: 'Get Premium'
  },
  homeFormUserOverQuota1: {
    name: 'homeFormUserOverQuota1',
    characters: 'You have reached the maximum number of free trials (10). In order to continue using our service, '
  },
  homeFormUserOverQuota2: {
    name: 'homeFormUserOverQuota2',
    characters: 'please register a payment method to become a member'
  },
  homeFormResponseRegenerateButton: {
    name: 'homeFormResponseRegenerateButton',
    characters: 'Regenerate response'
  },
  modalUpsellDescription: {
    name: 'modalUpsellDescription',
    characters:
      'You have now reached your maximum number of free descriptions. Please sign up for a membership to continue using Gazelle.'
  },
  signInCodeLoginText2: {
    name: 'signInCodeLoginText2',
    characters: 'Haven’t received any email? Please check your spam folder and that the email address is correct.'
  },
  signInCodeSignupText1: {
    name: 'signInCodeSignupText1',
    characters: 'If XXX is a registered user, we have sent a login code via email. Please enter it here:'
  },
  signInCodeSignupText2: {
    name: 'signInCodeSignupText2',
    characters: 'Haven’t received any email? Please check your spam folder and that the email address is correct.'
  },
  settingsPersonalUpsellWidgetFree: {
    name: 'settingsPersonalUpsellWidgetFree',
    characters: 'As a free trial user, you can create 10 ads for free. Become a member to create unlimited ads.'
  },
  settingsPersonalUpsellWidgetPremium: {
    name: 'settingsPersonalUpsellWidgetPremium',
    characters: 'Thanks for supporting us!\nTo manage your membership or view invoices, click below.'
  },
  signInSignUpWithEmailFormCheckboxErrorMessage: {
    name: 'signInSignUpWithEmailFormCheckboxErrorMessage',
    characters: 'You must accept the terms of use to continue.'
  },
  jaguarStyleConsiceDescription: {
    name: 'jaguarStyleConsiceDescription',
    characters: 'Concise'
  },
  jaguarStyleSalesyDescription: {
    name: 'jaguarStyleSalesyDescription',
    characters: 'Salesy'
  },
  jaguarStyleDetailedDescription: {
    name: 'jaguarStyleDetailedDescription',
    characters: 'Detailed'
  },
  homeFormChooseSectionHeader: {
    name: 'homeFormChooseSectionHeader',
    characters: 'Choose Section'
  },
  homeFormChooseSectionDescription: {
    name: 'homeFormChooseSectionDescription',
    characters: 'An awesome text that describes the section options'
  },
  settingsPersonalExpiresAtLabel: {
    name: 'settingsPersonalExpiresAtLabel',
    characters: 'Expires at'
  }
}
