import styled from '@emotion/styled'
import React from 'react'
import { SecondaryFrameBG } from '../../../pure-js/libs/Colors'
import { Texts } from '../../../pure-js/libs/Texts'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { useNavigate } from '../hooks/useNavigate'
import { useSessions } from '../hooks/useSessions'
import { useUser } from '../hooks/useUser'
import { isSelectedPage, MenuItem, MenuItemInfo } from '../libs/MenuHelper'
import { hasCompletedIntroduction } from '../libs/SignedInMachine'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export function MenuItemRow({ menuItem }: { menuItem: MenuItem }) {
  const { data: user } = useUser()
  const { data: sessions = [] } = useSessions()
  const {
    imageKey,
    menuTextKey: textKey,
    routePath,
    ContainerComponent,
    enableInLeftMenu = true,
    enableWhenNotOnboarded = false,
    enableWhenNoSessions = true
  } = MenuItemInfo[menuItem]
  const isSelected = isSelectedPage({ window, menuItem })
  const [isHovering, setIsHovering] = React.useState(false)
  const styleTextKey = isSelected ? Texts.componentsAppMenuMenuDashboard : Texts.componentsAppMenuMenuCollaboration
  const navigate = useNavigate({ enablePreserveQueryParams: false })

  // TODO WRITE TEST, should disable menu item when user has not completed introduction
  let isDisabled = !enableWhenNotOnboarded && !hasCompletedIntroduction(user)
  if (!enableWhenNoSessions && sessions?.length === 0) isDisabled = true

  const onClick = () => !isDisabled && navigate(routePath)

  let Component = ContainerComponent || Container
  if (isDisabled) Component = DisabledContainer

  if (!enableInLeftMenu) return null
  return (
    <Component
      fullWidth
      direction="row"
      align="center"
      pointer
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
      onClick={onClick}
      isSelected={isSelected}
      fullPadding
      spacing={smallSpacing}
    >
      <Box right spacing={smallSpacing}>
        <FigmaImageContainer imageKey={imageKey} />
      </Box>
      <Box>
        <FigmaText styleTextKey={styleTextKey} textKey={textKey} link={isHovering} />
      </Box>
    </Component>
  )
}

const Container = styled(Box)<{ isSelected: boolean }>`
  border-radius: ${BorderRadixes.regular};
  background-color: ${(props) => (props.isSelected ? SecondaryFrameBG : 'inherit')};
`

const DisabledContainer = styled(Container)<{ isSelected: boolean }>`
  opacity: 0.5;
`
