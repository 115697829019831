import styled from '@emotion/styled'
import { Switch } from '@mui/material'
import dayjs from 'dayjs'
import invariant from 'invariant'
import React from 'react'
import { MainColor, MainFrameBG } from '../../../pure-js/libs/Colors'
import { Images } from '../../../pure-js/libs/Images'
import { Texts } from '../../../pure-js/libs/Texts'
import { SessionInfo } from '../../../pure-js/types/Session'
import config from '../Config'
import { BorderRadixes } from '../enums/BorderRadixes'
import { defaultSpacing, minSpacing, smallSpacing } from '../enums/Spacings'
import useAppState, { useUserId } from '../hooks/useAppState'
import useNotification from '../hooks/useNotification'
import { useUser } from '../hooks/useUser'
import { triggerReflectionEmail } from '../libs/CloudFunctionsApiHandler'
import { updateUser } from '../libs/DBApiHandler'
import { captureAndNotifyError } from '../libs/ErrorHelper'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { BIG_ICON_SIZE } from './Icons'
import Loader from './Loader'
import { getTextKeys } from '../libs/CollaborationProgressSessionPutIntoPractiseHelper'
import { CollaborationProgressSessionButtons } from './CollaborationProgressSessionButtons'

export const CollaborationProgressSessionPutIntoPractise = ({ sessionInfo }: { sessionInfo: SessionInfo }) => {
  const { data: user, isLoading } = useUser(useUserId())

  const onSwitchChange = (enableReflectionEmail: boolean) => {
    invariant(user, 'User should be defined')
    updateUser({ id: user.id, enableReflectionEmail, updatedAt: dayjs().format() })
  }

  const textKeys = getTextKeys(sessionInfo)

  return (
    <Box fullWidth>
      <Container fullWidth fullPadding>
        <HeaderContainer fullWidth fullPadding>
          <Box fullWidth direction="row" align="center">
            <FigmaImageContainer imageKey={Images.iconResults} />
            <Box left>
              <FigmaText textKey={Texts.myCollaborationProgress4TimeToReflectTimeToReflectHeader} />
            </Box>
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <FigmaText textKey={Texts.myCollaborationProgress4TimeToReflectTimeToReflectDescription} />
          </Box>
        </HeaderContainer>
        <Box top spacing={minSpacing} />
        <BodyContainer fullWidth fullPadding direction="row" align="center" justify="space-between">
          <Box direction="row" align="center">
            <Box>
              {isLoading ? (
                <Loader />
              ) : (
                <Switch
                  size="medium"
                  onChange={(e, checked) => onSwitchChange(checked)}
                  checked={user?.enableReflectionEmail || false}
                  color={'white' as any}
                />
              )}
            </Box>
            <Box left>
              <Box>
                <FigmaText textKey={Texts.myCollaborationProgress4TimeToReflectRemindMeTitle} />
              </Box>
              <Box top spacing={minSpacing}>
                <FigmaText textKey={Texts.myCollaborationProgress4TimeToReflectRemindMeDescription} />
              </Box>
            </Box>
          </Box>
          <Box visibility={config.enableSendTriggerReflectionEmailButton ? 'visible' : 'hidden'}>
            {TriggerEmailButton({ sessionInfo })}
          </Box>
        </BodyContainer>
        {textKeys.map((textKey) => (
          <Box fullWidth top spacing={smallSpacing}>
            <QuestionContainer fullPadding spacing={defaultSpacing} direction="row" align="center" fullWidth>
              <Box>
                <FigmaImageContainer imageKey={Images.iconResults} width={BIG_ICON_SIZE} />
              </Box>
              <Box left>
                <FigmaText styleTextKey={Texts.reflectionsReflectionQuestion1} textKey={textKey} />
              </Box>
            </QuestionContainer>
          </Box>
        ))}
        <Box fullWidth top>
          <CollaborationProgressSessionButtons sessionInfo={sessionInfo} />
        </Box>
      </Container>
    </Box>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.big};
  background-color: ${MainColor};
`

const InnerContainer = styled(Box)`
  background-color: ${MainFrameBG};
`

const HeaderContainer = styled(InnerContainer)`
  border-top-left-radius: ${BorderRadixes.moderate};
  border-top-right-radius: ${BorderRadixes.moderate};
`

const BodyContainer = styled(InnerContainer)`
  border-bottom-left-radius: ${BorderRadixes.moderate};
  border-bottom-right-radius: ${BorderRadixes.moderate};
`

const QuestionContainer = styled(InnerContainer)`
  border-radius: ${BorderRadixes.moderate};
`
function TriggerEmailButton({ sessionInfo }: { sessionInfo: SessionInfo }) {
  const { state } = useAppState()
  const { success } = useNotification()
  const [loading, setLoading] = React.useState(false)

  return (
    <Button
      buttonType="tertiary"
      text="Trigger Email"
      loading={loading}
      onClick={() =>
        Promise.resolve(setLoading(true))
          .then(() => triggerReflectionEmail({ sessionId: sessionInfo.session.id }, state))
          .then(() => success())
          .catch(captureAndNotifyError)
          .finally(() => setLoading(false))
      }
    />
  )
}
