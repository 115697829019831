import 'react-json-view-lite/dist/index.css'
import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import SignedInTemsAndConditions from './SignedInTemsAndConditions'
import Box from './Box'
import { ProgressLevelIcon } from './ProgressLevelIcon'
import { ProgressLevel } from '../../../pure-js/enums/ProgressLevel'

const json = {
  a: 1,
  b: 'example'
}

const App = () => {
  const props = { signedInState: {} } as SignedInViewProps
  return (
    <Box fullWidth>
      <ProgressLevelIcon progressLevel={ProgressLevel.muchBetter} />
      <ProgressLevelIcon progressLevel={ProgressLevel.better} />
      <ProgressLevelIcon progressLevel={ProgressLevel.same} />
      <ProgressLevelIcon progressLevel={ProgressLevel.worse} />
      <ProgressLevelIcon progressLevel={ProgressLevel.muchWorse} />
    </Box>
  )
}

export default App
