import debounce from 'lodash/debounce'
import { useCallback, useEffect, useState } from 'react'

const useResponsive = () => {
  // screen resolutions
  const [state, setState] = useState({
    isMobile: false,
    isTablet: false,
    isDesktop: false
  })

  // update the state on window resize
  const onResizeHandler = () => {
    const isMobile = window.innerWidth <= 699
    const isTablet = window.innerWidth >= 700 && window.innerWidth <= 1000
    const isDesktop = window.innerWidth > 1001

    setState({ isMobile, isTablet, isDesktop })
  }

  // debounce the resize call
  const debouncedHandleResize = debounce(onResizeHandler, 500)

  useEffect(() => {
    // update the state on the initial load
    onResizeHandler()

    // assign the event
    window.addEventListener('resize', debouncedHandleResize, false)

    return () => {
      // remove the event
      window.removeEventListener('resize', debouncedHandleResize, false)
    }
  }, [])

  return state
}

export default useResponsive
