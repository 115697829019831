import { addQueryParamsToUrl } from '../../../pure-js/libs/QueryparamHelper'

export const GAME_NAME = 'Intraction Game'
const GAME_URL = '/game/index.html'

export type GameParams = { sessionId: string; displayName: string; introduction?: boolean; teamDisplayName?: string }

export const getGameUrl = (p: GameParams) => {
  return addQueryParamsToUrl<GameParams>(GAME_URL, p)
}
