import { SignInState } from '../../../pure-js/types/SignInTypes'
import { DEFAULT_SIGN_IN_STATE } from '../libs/SignInHelper'
import { StorageKey, StorageType } from './useLocalStorageItemHelper'
import { useStorageItem } from './useStorageItem'

export default function useSignInState() {
  const { storageItem: localStorageItem, setStorageItem: setLocalStorageItem } = useStorageItem(
    StorageKey.SIGN_IN_STATE,
    DEFAULT_SIGN_IN_STATE,
    {
      storageType: StorageType.SESSION_STORAGE
    }
  )

  const setSignInState = (signInState: SignInState) => setLocalStorageItem(signInState)

  const signInState: SignInState = localStorageItem

  return { signInState, setSignInState }
}
