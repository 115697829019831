import RoutePath from '../../../pure-js/libs/RoutePath'
import { Texts } from '../../../pure-js/libs/Texts'
import { CollaborationSessionDetailsQueryParams } from '../../../pure-js/types/QueryParamTypes'
import { SessionInfo } from '../../../pure-js/types/Session'
import { useNavigate } from '../hooks/useNavigate'
import useQueryParams from '../libs/useQueryParams'
import Box from './Box'
import Button from './Button'
import { CollaborationSessionDetailsRouteParams } from '../libs/CollaborationProgressSessionButtonsHelper'

export function CollaborationProgressSessionButtons({ sessionInfo }: { sessionInfo: SessionInfo }) {
  const qp = useQueryParams<CollaborationSessionDetailsQueryParams>()
  let index = CollaborationSessionDetailsRouteParams.findIndex(
    (params) => params.skillTab === qp.skillTab && params.skill === qp.skill
  )
  if (index === -1) index = 0

  const prevRouteParams: CollaborationSessionDetailsQueryParams = CollaborationSessionDetailsRouteParams[index - 1]
  const nextRouteParams: CollaborationSessionDetailsQueryParams = CollaborationSessionDetailsRouteParams[index + 1]

  const navigate = useNavigate()

  const onClickPrevious = () =>
    navigate(RoutePath.COLLABORATION, { sessionId: sessionInfo.session.id, ...prevRouteParams })

  const onClickNext = () => navigate(RoutePath.COLLABORATION, { sessionId: sessionInfo.session.id, ...nextRouteParams })

  return (
    <Box fullWidth direction="row" align="center" justify="space-between">
      <Box visibility={prevRouteParams ? 'visible' : 'hidden'}>
        <Button textKey={Texts.myCollaborationProgressCtaPreviousPart} onClick={onClickPrevious} />
      </Box>
      <Box visibility={nextRouteParams ? 'visible' : 'hidden'}>
        <Button textKey={Texts.myCollaborationProgressCtaNextPart} onClick={onClickNext} />
      </Box>
    </Box>
  )
}
