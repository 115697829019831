import styled from '@emotion/styled'
import dayjs from 'dayjs'
import { MainFrameBG } from '../../../pure-js/libs/Colors'
import { DMMMHHmm } from '../../../pure-js/libs/Consts'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { Texts } from '../../../pure-js/libs/Texts'
import { DeprecatedTextKeys } from '../../../pure-js/libs/TextsDeprecated'
import { CollaborationSessionDetailsQueryParams } from '../../../pure-js/types/QueryParamTypes'
import { SessionInfo } from '../../../pure-js/types/Session'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, smallSpacing } from '../enums/Spacings'
import { useUsersForSlot } from '../hooks/QueryHooks'
import { useNavigate } from '../hooks/useNavigate'
import { useUser } from '../hooks/useUser'
import Box from './Box'
import Button from './Button'
import {
  DashboardMyTeamUserItemLoggedInUserWidget,
  DashboardMyTeamUserItemUserWidget
} from './DashboardMyTeamUserWidget'
import FigmaText from './FigmaText'

export function CollaborationProgressItem({ sessionInfo }: { sessionInfo: SessionInfo }) {
  const { data: user } = useUser()

  const { session, name, createdAt } = sessionInfo

  const { data: users = [] } = useUsersForSlot(session.slots[0])
  const otherUsers = users.filter((u) => u.id !== user.id).sort((a, b) => a.id.localeCompare(b.id))

  const date = dayjs(createdAt).format(DMMMHHmm)
  const navigate = useNavigate()

  const onClick = () =>
    navigate<CollaborationSessionDetailsQueryParams>(RoutePath.COLLABORATION, { sessionId: session.id })

  return (
    <>
      <Container fullWidth fullPadding fullHeight justify="space-between">
        <Box fullWidth>
          <Box fullWidth align="center" justify="space-between" direction="row">
            <FigmaText textKey={DeprecatedTextKeys.txtSessionLiteItemText} text={name} />
            <FigmaText textKey={Texts.myCollaborationProgressOverallProgressSessionDate} text={date} />
          </Box>
        </Box>
        <Box top spacing={smallSpacing} direction="row" fullWidth align="center" justify="space-between">
          <Button
            textKey={Texts.myCollaborationProgressOverallProgressSessionCtaView}
            spacing={bigSpacing}
            onClick={onClick}
          />
          <Box direction="row" align="center">
            <DashboardMyTeamUserItemLoggedInUserWidget user={user} />
            {otherUsers.map((user, i) => (
              <CollaborationProgressItemOtherUser uid={user.id} key={i} />
            ))}
          </Box>
        </Box>
      </Container>
    </>
  )
}

const Container = styled(Box)`
  border-top-left-radius: ${BorderRadixes.moderate};
  border-bottom-left-radius: ${BorderRadixes.moderate};
  background-color: ${MainFrameBG};
`

function CollaborationProgressItemOtherUser({ uid }: { uid: string }) {
  const { data: user } = useUser(uid)
  return <DashboardMyTeamUserItemUserWidget user={user} />
}
