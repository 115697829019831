import { Dashboard } from '../components/Dashboard'
import { Layout } from '../components/Layout'

export const DashboardPage = () => {
  return (
    <Layout enableMenu>
      <Dashboard />
    </Layout>
  )
}
