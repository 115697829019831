import invariant from 'invariant'

import { SignInState, SignInSteps } from '../../../pure-js/types/SignInTypes'
import { State } from '../hooks/useAppState'
import {
  createAuthenticationTokenForLoginToken,
  createLoginWithCodeToken,
  signUpUser
} from './CloudFunctionsApiHandler'
import { signInWithCustomToken } from './FirebaseHelper'
import { toBaseObject } from './Mapper'
import { DEFAULT_SIGN_IN_STATE } from './SignInHelper'

export const onPressContinue = (
  signInState: SignInState,
  state: State,
  e: {
    navigate: (path: string) => void
  },
  f = {
    createLoginWithCodeToken,
    signInWithCustomToken,
    createAuthenticationTokenForLoginToken,
    signUpUser
  }
): Promise<SignInState> =>
  Promise.resolve().then(async () => {
    switch (signInState.step) {
      case SignInSteps.SIGN_UP: {
        const { email, displayName, phone } = signInState.data
        invariant(email, '!email')
        // TODO WRITE TEST should signup user before creating login token
        await f.signUpUser({ email, displayName, phone }, state)
        const loginToken = await f.createLoginWithCodeToken({ email, ...toBaseObject(state) }, state)
        signInState.data.loginToken = loginToken
        return { ...signInState, step: SignInSteps.ENTER_CODE }
      }
      case SignInSteps.LANDING: {
        const { email } = signInState.data
        invariant(email, '!email')
        const loginToken = await f.createLoginWithCodeToken({ email, ...toBaseObject(state) }, state)
        signInState.data.loginToken = loginToken
        return { ...signInState, step: SignInSteps.ENTER_CODE }
      }
      case SignInSteps.ENTER_CODE: {
        const { code, loginToken } = signInState.data
        invariant(code, '!code')
        invariant(loginToken, '!loginToken')

        const createAuthenticationTokenForLoginTokenResponse = await f.createAuthenticationTokenForLoginToken(
          { ...loginToken, code },
          state
        )
        if (!createAuthenticationTokenForLoginTokenResponse.token)
          return { ...signInState, data: { ...signInState.data, createAuthenticationTokenForLoginTokenResponse } }

        await f.signInWithCustomToken(createAuthenticationTokenForLoginTokenResponse.token)

        return DEFAULT_SIGN_IN_STATE
      }

      default:
        throw new Error(`Unknown step: ${signInState.step}`)
    }
  })
