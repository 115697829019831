import Box from './Box'
import UserWidget from './UserWidget'

export function AppBarWithOnlyUserWidget() {
  return (
    <Box fullWidth>
      <Box fullWidth direction="row" align="center" justify="space-between">
        <Box />
        <Box>
          <UserWidget />
        </Box>
      </Box>
    </Box>
  )
}
