import { Layout } from '../components/Layout'
import { TheoryBehind } from '../components/TheoryBehind'

export const TheoryBehindPage = () => {
  return (
    <Layout enableMenu>
      <TheoryBehind />
    </Layout>
  )
}
