import { useEffect } from 'react'
import { Controller, useFieldArray } from 'react-hook-form'
import * as Yup from 'yup'
import { getArray } from '../../../pure-js/libs/Common'
import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { useForm } from '../hooks/useForm'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { EmailValidation } from '../libs/ValidationHelper'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldFormExperimental } from './TextFieldForm'
import { getNumberOfInvitesLeft } from './getNumberOfInvitesLeft'

const ValidationMessage = getFigmaText(Texts.onboardingStartPageInputEmailErrorWrongFormat)

export const ValidationSchema = Yup.object().shape({
  members: Yup.array().of(
    Yup.object().shape({
      email: EmailValidation(ValidationMessage).optional()
    })
  )
})

export default function SignedInInviteToTeamForm(props: SignedInViewProps) {
  const { signedInState } = props
  const members = getArray(getNumberOfInvitesLeft(signedInState)).map((_) => ({ email: '' }))

  const _onClick = ({ members }) => {
    const emailsToInvite = members.filter((member) => member.email).map((member) => member.email)
    return props.onPressContinue({ ...signedInState, data: { ...signedInState.data, emailsToInvite } })
  }

  const { control, handleSubmit } = useForm(ValidationSchema, {
    defaultValues: { members }
  })

  const { fields } = useFieldArray({
    control,
    name: 'members'
  })

  const onSubmit = handleSubmit(_onClick)

  return (
    <form onSubmit={onSubmit}>
      <Box align="center" fullWidth>
        {fields.map((field, index) => (
          <Box key={field.id} align="center" fullWidth top spacing={tinySpacing}>
            <Controller
              control={control}
              name={`members.${index}.email`}
              render={(props) => {
                return (
                  <TextFieldFormExperimental
                    {...props}
                    headerText={getFigmaText(Texts.onboardingInviteTeamMembersInputInviteEmailHeader)}
                    inputProps={{
                      placeholder: getFigmaText(Texts.onboardingInviteTeamMembersInputInviteEmailHelper),
                      disabled: !!members[index]?.email
                    }}
                  />
                )
              }}
            />
          </Box>
        ))}
        <Box top fullWidth>
          <Button fullWidth onClick={onSubmit} textKey={Texts.onboardingInviteTeamMembersCtaInviteMember}>
            <FigmaTextLoader textKey={Texts.onboardingInviteTeamMembersCtaInviteMember} loading={props.isLoading} />
          </Button>
        </Box>
        <Box top fullWidth spacing={smallSpacing} alignText="center" onClick={props.onClickLater} link>
          <FigmaText textKey={Texts.onboardingInviteTeamMembersCtaInviteLater} />
        </Box>
      </Box>
    </form>
  )
}
