import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { Texts } from '../../../pure-js/libs/Texts'
import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { bigSpacing, defaultSpacing, mediumSpacing, reallyBigSpacing, smallSpacing } from '../enums/Spacings'
import { LoginConatinerWidth } from '../libs/HardCodedSizes'
import { AppBarWithOnlyUserWidget } from './AppBarWithOnlyUserWidget'
import Box from './Box'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { SignInLandingIntractionLogo } from './SignInLandingIntractionLogo'
import SignedInJoinTeamForm from './SignedInJoinTeamForm'

export default function SignedInJoinTeam(props: SignedInViewProps) {
  const { enableCreateNewTeam = true } = props
  return (
    <Layout enableMenu>
      <Box fullPadding fullWidth>
        <AppBarWithOnlyUserWidget />
      </Box>
      <Box fullWidth align="center">
        <Box>
          <SignInLandingIntractionLogo />
        </Box>
        <Box top spacing={reallyBigSpacing}>
          <Container fullWidth>
            <Box right left fullWidth direction="row" justify="space-between" align="center">
              <Box pointer onClick={props.onClickBack}>
                {/** <FigmaImageContainer imageKey={Images.backIcon} /> **/}
              </Box>
              <Box></Box>
              <Box></Box>
            </Box>
            <Box align="center" right left spacing={reallyBigSpacing} fullWidth>
              <Box fullWidth top spacing={mediumSpacing}>
                <FigmaText textKey={Texts.onboardingConnectWithTeamTeamConnectContentHeader} />
              </Box>
              <Box top spacing={smallSpacing} fullWidth>
                <SignedInJoinTeamForm {...props} />
              </Box>
            </Box>
            {enableCreateNewTeam && (
              <Box top spacing={defaultSpacing} pointer onClick={props.onClickCrateTeam} fullWidth alignText="center">
                <FigmaText textKey={Texts.onboardingConnectWithTeamCtaCreateNewTeam} />
              </Box>
            )}
            <Box top />
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${MainColor};
  border-radius: ${BorderRadixes.big};
  width: ${LoginConatinerWidth}px;
`
