import { useState } from 'react'
import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { ENTER_KEYCODE } from '../../../pure-js/libs/Consts'
import { Team } from '../../../pure-js/types/Team'
import { useForm } from '../hooks/useForm'
import { updateTeam } from '../libs/DBApiHandler'
import { captureException } from '../libs/SentryHelper'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldFormExperimental } from './TextFieldForm'

const message = 'Enter name'
export const ValidationSchema = Yup.object().shape({
  name: Yup.string().required(message).typeError(message).min(2, message)
})

export default function DashboardMyTeamNameForm(props: { team: Team; onSuccess: () => unknown }) {
  const { team, onSuccess } = props
  const { name = '' } = team
  const [isLoading, setIsLoading] = useState(false)

  const defaultValues = { name }

  const _onClick = (values) =>
    Promise.resolve(updateTeam({ ...team, ...values }))
      .then(() => updateTeam({ ...team, ...values }))
      .then(onSuccess)
      .catch(captureException)
      .finally(() => setIsLoading(false))

  const { control, handleSubmit } = useForm(ValidationSchema, { defaultValues })

  const onSubmit = handleSubmit(_onClick)
  return (
    <form onSubmit={_onClick}>
      <Box align="center" fullWidth direction="row" justify="space-between">
        <Controller
          control={control}
          name="name"
          render={(props) => (
            <TextFieldFormExperimental
              {...props}
              field={{
                ...props.field,
                onKeyDown: (e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)
              }}
              headerText={getFigmaText(Texts.onboardingCreateNewTeamInputNewTeamNameHeader)}
            />
          )}
        />
        <Button onClick={onSubmit} textKey={Texts.otherPagesCtaUpdateProfile}>
          <FigmaTextLoader textKey={Texts.otherPagesCtaUpdateProfile} loading={isLoading} />
        </Button>
      </Box>
    </form>
  )
}
