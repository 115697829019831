import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import { Layout } from './Layout'
import { LoadingPageLoggedIn } from './LoadingPage'
import { Onboarding } from './Onboarding'

export const SignedInOnboarding = ({ isLoading }: SignedInViewProps) => {
  if (isLoading) return <LoadingPageLoggedIn />
  return (
    <Layout enableMenu>
      <Onboarding />
    </Layout>
  )
}
