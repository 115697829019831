import styled from '@emotion/styled'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { ColorsDeprecated as Colors } from '../../../pure-js/libs/ColorsDeprecated'
import { NOTIFICATION_WIDTH } from '../libs/HardCodedSizes'
import Box from './Box'
import React from 'react'
import FigmaText from './FigmaText'
import { NotificationCheckIcon, ClearIcon } from './Icons'
import { smallSpacing } from '../enums/Spacings'
import { LinkStyles } from './StyledComponents'
import { TextsDeprecated } from '../../../pure-js/libs/TextsDeprecated'
import { Black } from '../../../pure-js/libs/Colors'

export type NotificationProps = {
  textKey?: TextKey
  text?: string
  onClose?: () => void
  onClickText?: () => void
  icon?: React.FC<any>
  component?: React.FC<any>
  className?: string
  element?: React.ReactElement
}
export default function Notificaiton(props: NotificationProps) {
  const {
    textKey = TextsDeprecated.applicationNotificationNewObjectiveCreatedText2,
    text = '',
    onClose,
    onClickText,
    icon,
    className,
    element
  } = props
  return (
    <StyledNotification className={className} right>
      <Box direction="row" align="center" fullPadding spacing={smallSpacing}>
        <Box fullPadding spacing={smallSpacing}>
          {React.createElement(icon || NotificationCheckIcon)}
        </Box>
        <Box link={!!onClickText} onClick={onClickText}>
          {element || <FigmaText textKey={textKey} text={text} />}
        </Box>
      </Box>
      <StyledCloseIconContainer onClick={onClose}>
        <ClearIcon />
      </StyledCloseIconContainer>
    </StyledNotification>
  )
}

const StyledNotification = styled(Box)`
  background-color: ${Colors.white};
  border: 1px solid ${Black};
  box-sizing: border-box;
  box-shadow: 0px 4px 8px 4px rgba(0, 0, 0, 0.13);
  max-width: ${NOTIFICATION_WIDTH};
`

const StyledCloseIconContainer = styled.div`
  position: absolute;
  right: ${smallSpacing};
  ${LinkStyles}
`
