import styled from '@emotion/styled'
import React from 'react'
import { TextsDeprecated } from '../../../pure-js/libs/TextsDeprecated'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import ButtonBase, { ButtonBaseProps } from './ButtonBase'
import { ButtonProps } from './ButtonPrimary'
import FigmaTextLoader from './FigmaTextLoader'

export const ButtonSecondary: React.FC<ButtonProps> = (props) => {
  const {
    children,
    className,
    loading,
    textKey = TextsDeprecated.cancelButtonText,
    color = 'secondary',
    ...buttonProps
  } = props
  const styleTextKey =
    props.variant === 'outlined' ? Texts.onboardingStartPageInputEmailErrorWrongFormat : Texts.gameCenterEnterLobbyCta

  return (
    <StyledButton className={className} variant="contained" {...buttonProps} color={color}>
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader textKey={styleTextKey} text={props.text || getFigmaText(textKey)} loading={loading} />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(ButtonBase)<ButtonBaseProps>``
