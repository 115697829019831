import { SkillDevelopmentItemInfo } from './SkillDevelopmentItemInfo'
import { Session, SkillItemProgress } from '../types/Session'
import { Slot } from '../types/Slot'
import { GetSessionInfoOptions } from './getSessionInfo'

export type GetScoreFunction = (skillItemProgressItems: SkillItemProgress[], key: keyof SkillItemProgress) => number

export type GetScoreForSlotsFunction = (
  args: {
    uid?: string
    skillDevelopmentItemInfo: SkillDevelopmentItemInfo
    slots: Slot[]
    session: Session
  },
  f: GetSessionInfoOptions
) => number

export const sumScoresForSlots: GetScoreForSlotsFunction = (
  { uid, skillDevelopmentItemInfo: s, slots, session },
  f: GetSessionInfoOptions
): number =>
  slots
    .flatMap((slot) => slot.slotDataPoints)
    .filter((slotDataPoint) => !uid || slotDataPoint.uid === uid)
    .reduce(
      (a: number, dataPoint) => a + s.getValueForSlotDataPoint({ dataPoint, uid, slots, session }, f) * (s.weight || 1),
      0
    )

export const avgScoresForSlots: GetScoreForSlotsFunction = (
  { uid, skillDevelopmentItemInfo: s, slots, session },
  f: GetSessionInfoOptions
): number =>
  slots
    .flatMap((slot) => slot.slotDataPoints)
    .filter((slotDataPoint) => !uid || slotDataPoint.uid === uid)
    .reduce(
      (a: number, dataPoint, i, arr) =>
        a + (s.getValueForSlotDataPoint({ slots, dataPoint, uid, session }, f) * (s.weight || 1)) / arr.length,
      0
    )

export const sumSkillScore = (skillItemProgressItems: SkillItemProgress[], key: keyof SkillItemProgress): number => {
  return skillItemProgressItems.map((item) => item).reduce((a: number, b) => a + Number(b[key]) || 0, 0)
}
