export enum ProgressLevel {
  muchBetter = 'muchBetter',
  better = 'better',
  same = 'same',
  worse = 'worse',
  muchWorse = 'muchWorse'
}

export enum ProgressLevelLight {
  better = 'better',
  same = 'same',
  worse = 'worse'
}
