import React from 'react'
import Notificaiton, { NotificationProps } from './Notification'
import { TextsDeprecated } from '../../../pure-js/libs/TextsDeprecated'
import { NotificationErrorIcon } from './Icons'
import { ColorsDeprecated as Colors } from '../../../pure-js/libs/ColorsDeprecated'
import styled from '@emotion/styled'

export const _ErrorNotification = (props: Partial<NotificationProps>) => {
  return (
    <StyledErrorNotification
      {...props}
      icon={NotificationErrorIcon}
      textKey={props.textKey || TextsDeprecated.notificationWarningText}
      text={props.text}
    />
  )
}

const StyledErrorNotification = styled(Notificaiton)`
  border-color: ${Colors.error100};
`
