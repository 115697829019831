import styled from '@emotion/styled'
import { useNavigate } from 'react-router-dom'
import { MainColor, MainFrameBG } from '../../../pure-js/libs/Colors'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { DeprecatedTextKeys } from '../../../pure-js/libs/TextsDeprecated'
import { BorderRadixes } from '../enums/BorderRadixes'
import { MenuItem } from '../libs/MenuHelper'
import { Texts } from '../../../pure-js/libs/Texts'
import { AppBar } from './AppBar'
import Box from './Box'
import Button from './Button'
import { DashboardMyTeam } from './DashboardMyTeam'
import { DashboardOverallCollaboration } from './DashboardOverallCollaboration'
import FigmaText from './FigmaText'
import { PageContainer } from './PageContainer'

export const Dashboard = () => {
  const navigate = useNavigate()

  return (
    <PageContainer fullWidth fullPadding>
      <AppBar menuItem={MenuItem.dashboard} />
      <Box fullWidth top>
        <Container fullWidth fullPadding>
          <SecondaryContainer fullWidth direction="row">
            <Box fullWidth fullPadding>
              <DashboardOverallCollaboration />
            </Box>
            <Divider />
            <Box fullWidth fullPadding>
              <DashboardMyTeam />
            </Box>
          </SecondaryContainer>
          <Box fullWidth top>
            <SecondaryContainer fullWidth fullPadding>
              <Box fullWidth>
                <FigmaText textKey={Texts.dashboardTheoryBehindHeader} />
              </Box>
              <Box fullWidth top>
                <FigmaText textKey={Texts.dashboardTheoryBehindInfo} />
              </Box>
              <Box fullWidth top>
                <Button
                  buttonType="primary"
                  textKey={DeprecatedTextKeys.txtButtonReadMore}
                  variant="outlined"
                  onClick={() => navigate(RoutePath.THEORY_BEHIND)}
                />
              </Box>
            </SecondaryContainer>
          </Box>
        </Container>
      </Box>
    </PageContainer>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.big};
  background-color: ${MainColor};
`

const SecondaryContainer = styled(Box)`
  border-radius: ${BorderRadixes.big};
  background-color: ${MainFrameBG};
`

const Divider = styled(Box)`
  height: 100%;
  width: 2px;
  background-color: ${MainColor};
`
