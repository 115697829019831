import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { ENTER_KEYCODE } from '../../../pure-js/libs/Consts'
import { SignInViewProps } from '../../../pure-js/types/SignInTypes'
import { smallSpacing } from '../enums/Spacings'
import { useForm } from '../hooks/useForm'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { EmailValidation } from '../libs/ValidationHelper'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldFormExperimental } from './TextFieldForm'

export const ValidationSchema = Yup.object().shape({
  email: EmailValidation(getFigmaText(Texts.onboardingStartPageInputEmailErrorWrongFormat))
})

export default function LoginWithEmailForm(props: SignInViewProps) {
  const { signInState } = props
  const { data } = signInState
  const { email = '' } = data

  const _onClick = (values) => props.onPressContinue({ ...signInState, data: { ...signInState.data, ...values } })

  const { control, handleSubmit } = useForm(ValidationSchema, { defaultValues: { email } })

  const onSubmit = handleSubmit(_onClick)
  return (
    <form onSubmit={_onClick}>
      <Box align="center" fullWidth>
        <Controller
          control={control}
          name="email"
          render={(formProps) => (
            <Box fullWidth>
              <TextFieldFormExperimental
                {...formProps}
                field={{
                  ...formProps.field,
                  onKeyDown: (e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)
                }}
                headerText="Email"
              />
              <Box top fullWidth spacing={smallSpacing}>
                <Button
                  fullWidth
                  onClick={onSubmit}
                  textKey={Texts.onboardingStartPageCtaPrimaryOk}
                  isDisabled={formProps.field.value?.length === 0}
                >
                  <FigmaTextLoader textKey={Texts.onboardingStartPageCtaPrimaryOk} loading={props.isLoading} />
                </Button>
              </Box>
            </Box>
          )}
        />
      </Box>
    </form>
  )
}

export const SIGNIN_TEXT_FIELD_LENGTH = 310
