import RoutePath from '../../../pure-js/libs/RoutePath'
import { getDisplayNameFromEmail } from '../../../pure-js/libs/UserWidgetHelper'
import { User } from '../../../pure-js/types/User'
import { useNavigate } from '../hooks/useNavigate'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { DashboardMyTeamItemView } from './DashboardMyTeamItemView'
import {
  DashboardMyTeamUserItemLoggedInUserWidget,
  DashboardMyTeamUserItemNotLoggedInUserWidget,
  DashboardMyTeamUserItemUserWidget,
  DashboardMyTeamUserWidgetInviteUser,
  DashboardMyTeamsUserWidgetProps
} from './DashboardMyTeamUserWidget'

export const DashboardMyTeamUserItemUser = ({ user }: DashboardMyTeamsUserWidgetProps) => (
  <DashboardMyTeamItemView
    user={user}
    textKey={Texts.componentsTeamListTeamListOtherUserName}
    text={getDisplayName(user)}
    UserWidgetComponent={DashboardMyTeamUserItemUserWidget}
  />
)

export const DashboardMyTeamUserItemLoggedInUser = ({ user }: DashboardMyTeamsUserWidgetProps) => (
  <DashboardMyTeamItemView
    user={user}
    textKey={Texts.cssUserWidget}
    text={getDisplayName(user)}
    UserWidgetComponent={DashboardMyTeamUserItemLoggedInUserWidget}
    enableDelete={false}
  />
)

export const DashboardMyTeamUserItemNotLoggedInUser = ({ user }: DashboardMyTeamsUserWidgetProps) => (
  <DashboardMyTeamItemView
    user={user}
    textKey={Texts.componentsTeamListTeamListInvitedUserEmail}
    text={user.email || ''}
    isPending
    UserWidgetComponent={DashboardMyTeamUserItemNotLoggedInUserWidget}
  />
)

export const DashboardMyTeamUseritemInviteUser = ({ user }: DashboardMyTeamsUserWidgetProps) => {
  const navigate = useNavigate()
  return (
    <DashboardMyTeamItemView
      user={user}
      textKey={Texts.componentsTeamListTeamListInviteCta}
      text={getFigmaText(Texts.componentsTeamListTeamListInviteCta)}
      UserWidgetComponent={DashboardMyTeamUserWidgetInviteUser}
      onClick={() => navigate(RoutePath.INVITE)}
      enableDelete={false}
    />
  )
}

function getDisplayName(user: User): string {
  return user.displayName || getDisplayNameFromEmail(user.email) || ''
}
