import { Images } from './Images'
import {
  taskPerformanceAbilityUsed,
  taskPerformanceTimesCalledDrone as droneUsed,
  taskPerformanceHypothermia,
  taskPerformancePeopleHealed,
  taskPerformanceTimesUnlockedRobot as robotsUnlocked,
  taskPerformanceUridiumCollected
} from './SessionInfoHelper'
import { SkillDevelopmentItemInfo } from './SkillDevelopmentItemInfo'
import { Texts } from './Texts'

export enum SkillDevelopmentTaskPerformance {
  UridiumCollected = 'UridiumCollected',
  Hypothermia = 'Hypothermia',
  AbilityUsed = 'AbilityUsed',
  DroneUsed = 'DroneUsed',
  RobotsUnlocked = 'RobotsUnlocked',
  PeopleHealed = 'PeopleHealed'
}

export const SkillDevelopmentItemInfoTaskPerformance: {
  [property in SkillDevelopmentTaskPerformance]: SkillDevelopmentItemInfo
} = {
  [SkillDevelopmentTaskPerformance.UridiumCollected]: {
    textKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityTitleUridiumCollected,
    imageKey: Images.iconUridiumCollected,
    descriptionTextKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityInfoUridiumCollected,
    getValueForSlotDataPoint: ({ dataPoint }) => taskPerformanceUridiumCollected(dataPoint),
    weight: 1,
    metaDataPointKey: 'uridiumCollectedTotal'
  },
  [SkillDevelopmentTaskPerformance.Hypothermia]: {
    textKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityTitleHypothermia,
    imageKey: Images.iconHypothermia,
    descriptionTextKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityInfoHypothermia,
    getValueForSlotDataPoint: ({ dataPoint }) => taskPerformanceHypothermia(dataPoint),
    weight: 1,
    metaDataPointKey: 'timeParalyzed'
  },
  [SkillDevelopmentTaskPerformance.AbilityUsed]: {
    textKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityTitleAbilityUsed,
    imageKey: Images.iconAbilityUsed,
    descriptionTextKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityInfoAbilityUsed,
    getValueForSlotDataPoint: ({ dataPoint }) => taskPerformanceAbilityUsed(dataPoint),
    metaDataPointKey: 'timesUsedAbility',
    weight: 1
  },
  [SkillDevelopmentTaskPerformance.DroneUsed]: {
    textKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityTitleDroneUsed,
    imageKey: Images.iconDroneUsed,
    descriptionTextKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityInfoDroneUsed,
    getValueForSlotDataPoint: ({ dataPoint }) => droneUsed(dataPoint),
    weight: 1,
    metaDataPointKey: 'timesSuccessfullyGotDrone'
  },
  [SkillDevelopmentTaskPerformance.RobotsUnlocked]: {
    textKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityTitleRobotsUnlocked,
    imageKey: Images.iconRobotsUnlocked,
    descriptionTextKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityInfoRobotsUnlocked,
    getValueForSlotDataPoint: ({ dataPoint }) => robotsUnlocked(dataPoint),
    weight: 1,
    metaDataPointKey: 'timesUnlockedRobot'
  },
  [SkillDevelopmentTaskPerformance.PeopleHealed]: {
    textKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityTitlePeopleHealed,
    imageKey: Images.iconPeopleHealed,
    descriptionTextKey: Texts.myCollaborationProgress1TaskPerformanceCapabilityInfoPeopleHealed,
    getValueForSlotDataPoint: ({ dataPoint }) => taskPerformancePeopleHealed(dataPoint),
    weight: 1,
    metaDataPointKey: 'timesSharedResourcesToOthers'
  }
}
