import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing } from '../enums/Spacings'
import { SkillDevelopmentItemInfoTaskPerformance } from '../../../pure-js/libs/SkillDevelopmentItemInfoTaskPerformance'
import { SessionInfo } from '../../../pure-js/types/Session'
import Box from './Box'
import { CollaborationProgressSessionSkillDevelopmentItem } from './CollaborationProgressSessionSkillDevelopmentItem'

export function CollaborationProgressSessionTaskPerformanceEvents({ sessionInfo }: { sessionInfo: SessionInfo }) {
  return (
    <Box fullWidth>
      <Box fullWidth direction="row">
        <CollaborationProgressSessionSkillDevelopmentItem
          item={sessionInfo.skills.TaskPerformance.items.UridiumCollected}
          itemInfo={SkillDevelopmentItemInfoTaskPerformance.UridiumCollected}
          sessionInfo={sessionInfo}
        />
        <VerticalDivider />
        <CollaborationProgressSessionSkillDevelopmentItem
          item={sessionInfo.skills.TaskPerformance.items.Hypothermia}
          itemInfo={SkillDevelopmentItemInfoTaskPerformance.Hypothermia}
          sessionInfo={sessionInfo}
        />
        <VerticalDivider />
        <CollaborationProgressSessionSkillDevelopmentItem
          item={sessionInfo.skills.TaskPerformance.items.AbilityUsed}
          itemInfo={SkillDevelopmentItemInfoTaskPerformance.AbilityUsed}
          sessionInfo={sessionInfo}
        />
      </Box>
      <HorizontalDivider />
      <Box fullWidth direction="row">
        <StyledCollaborationProgressSessionSkillDevelopmentItemBottomLeft
          item={sessionInfo.skills.TaskPerformance.items.DroneUsed}
          itemInfo={SkillDevelopmentItemInfoTaskPerformance.DroneUsed}
          sessionInfo={sessionInfo}
        />
        <VerticalDivider />
        <CollaborationProgressSessionSkillDevelopmentItem
          item={sessionInfo.skills.TaskPerformance.items.RobotsUnlocked}
          itemInfo={SkillDevelopmentItemInfoTaskPerformance.RobotsUnlocked}
          sessionInfo={sessionInfo}
        />
        <VerticalDivider />
        <StyledCollaborationProgressSessionSkillDevelopmentItemBottomRight
          item={sessionInfo.skills.TaskPerformance.items.PeopleHealed}
          itemInfo={SkillDevelopmentItemInfoTaskPerformance.PeopleHealed}
          sessionInfo={sessionInfo}
        />
      </Box>
    </Box>
  )
}

export const VerticalDivider = styled(Box)`
  width: ${minSpacing};
  background-color: ${MainColor};
  height: 100%;
`

export const HorizontalDivider = styled(Box)`
  width: 100%;
  height: ${minSpacing};
  background-color: ${MainColor};
`

export const StyledCollaborationProgressSessionSkillDevelopmentItemBottomRight = styled(
  CollaborationProgressSessionSkillDevelopmentItem
)`
  border-bottom-right-radius: ${BorderRadixes.moderate};
`

export const StyledCollaborationProgressSessionSkillDevelopmentItemBottomLeft = styled(
  CollaborationProgressSessionSkillDevelopmentItem
)`
  border-bottom-left-radius: ${BorderRadixes.moderate};
`
