import styled from '@emotion/styled'
import invariant from 'invariant'
import { MainColor, MainFrameBG, White } from '../../../pure-js/libs/Colors'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { CollaborationSessionDetailsQueryParams } from '../../../pure-js/types/QueryParamTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { Skill } from '../../../pure-js/enums/Skill'
import { defaultSpacing, minSpacing, smallSpacing } from '../enums/Spacings'
import { useNavigate } from '../hooks/useNavigate'
import { Images } from '../../../pure-js/libs/Images'
import { ProgressLevelsInfo } from '../libs/ProgressLevelsInfo'
import { Texts } from '../../../pure-js/libs/Texts'
import useQueryParams from '../libs/useQueryParams'
import Box from './Box'
import { CollaborationProgressSessionButtons } from './CollaborationProgressSessionButtons'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { BIG_ICON_SIZE } from './Icons'
import { ProgressLevelIcon } from './ProgressLevelIcon'
import { SkillsInfo } from '../../../pure-js/libs/SkillsInfo'
import { getFigmaText } from '../libs/TextRepository'
import { capitalize } from '../../../pure-js/libs/Common'
import {
  CollaborationProgressSessionSkillDevelopmentProps,
  SkillInfoTransitionWeb
} from '../libs/SkillInfoTransitionWeb'
import { AllSkillInfoDevelopmentItemInfos } from '../../../pure-js/libs/SkillDevelopmentItemInfo'

export const CollaborationProgressSessionSkillDevelopment = ({
  sessionInfo
}: CollaborationProgressSessionSkillDevelopmentProps) => {
  const { previousSessions } = sessionInfo
  const qp = useQueryParams<CollaborationSessionDetailsQueryParams>()
  const navigate = useNavigate()
  const selectedSkill = qp.skill || Skill.Communication
  const skillInfo = SkillsInfo[selectedSkill]

  const { userProgressLevel, userTop1Potential } = sessionInfo.skills[selectedSkill]
  const { skillDevelopmentItemKey, recommendationLevel } = userTop1Potential

  const progressLevelTextKey =
    previousSessions.length > 0
      ? skillInfo.resultSummary2TextKeys[userProgressLevel]
      : skillInfo.resultSummary1TextKeys[userProgressLevel]

  const userTop1PotentialRecommendationTextKey =
    AllSkillInfoDevelopmentItemInfos[skillDevelopmentItemKey]?.recommendationTextKeys?.[recommendationLevel || '']

  /*
  if (selectedSkill === Skill.Communication)
    console.log('CollaborationProgressSessionSkillDevelopment', {
      sessionInfo,
      selectedSkill,
      skillInfo,
      userProgressLevel,
      userTop1Potential,
      progressLevelTextKey,
      userTop1PotentialRecommendationTextKey
    })
      */

  const progressLevelText = getFigmaText(progressLevelTextKey)
  const userTop1PotentialRecommendationText = userTop1PotentialRecommendationTextKey
    ? getFigmaText(userTop1PotentialRecommendationTextKey)
    : ''

  const Component = SkillInfoTransitionWeb[selectedSkill].Component || SkillInfoTransitionWeb.Communication.Component

  invariant(Component, 'Component is required')

  return (
    <Box fullWidth>
      <Container fullWidth fullPadding>
        <Box fullWidth direction="row" align="center">
          {[Skill.Communication, Skill.HelpingAndAssisting, Skill.DecisionMaking].map((skill, index, arr) => {
            const s = SkillsInfo[skill]
            const { textKey } = s
            const { userProgressLevel } = sessionInfo.skills[skill]
            const isSelected = skill === selectedSkill
            const onClick = () => navigate(RoutePath.COLLABORATION, { ...qp, skill })
            const headerStyleTextKey = isSelected
              ? Texts.cssCollaborationProgressSkillDevelopentSkillSelected
              : Texts.cssCollaborationProgressSkillDevelopentSkillUnselected

            const progressLevelStyleTextKey = isSelected
              ? Texts.cssCollaborationProgressSkillDevelopentSkillProgressLevelSelected
              : ProgressLevelsInfo[userProgressLevel].textKey

            const imageKey = isSelected ? s.imageKeyBlack : s.imageKey

            return (
              <Box fullWidth direction="row" align="center" fullHeight>
                <Box fullWidth align="center" fullHeight>
                  <SkillContainer
                    fullWidth
                    fullPadding
                    $isSelected={isSelected}
                    pointer
                    onClick={onClick}
                    fullHeight
                    multiline
                  >
                    <Box fullWidth direction="row" align="center">
                      <FigmaImageContainer imageKey={imageKey} width={BIG_ICON_SIZE} />
                      <Box left spacing={smallSpacing}>
                        <FigmaText styleTextKey={headerStyleTextKey} textKey={textKey} />
                      </Box>
                    </Box>
                    <Box fullWidth top spacing={smallSpacing}>
                      <Box fullWidth align="center" direction="row">
                        <ProgressLevelIcon progressLevel={userProgressLevel} size={BIG_ICON_SIZE} />
                        <Box left spacing={smallSpacing}>
                          <FigmaText
                            styleTextKey={progressLevelStyleTextKey}
                            textKey={ProgressLevelsInfo[userProgressLevel].textKey}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </SkillContainer>
                  {isSelected && <Triangle />}
                </Box>
                {index !== arr.length - 1 && <VerticalDivider right spacing={defaultSpacing} />}
              </Box>
            )
          })}
        </Box>
        <Box fullWidth top />
        <Box fullWidth>
          <HeroContainer fullWidth fullPadding>
            <Box fullWidth direction="row" align="center">
              <FigmaImageContainer imageKey={Images.iconResults} />
              <Box left>
                <FigmaText textKey={Texts.myCollaborationProgress2SkillsDevelopmentSkillsSectionSummaryTitle} />
              </Box>
            </Box>
            <Box fullWidth top spacing={smallSpacing}>
              <FigmaText
                textKey={Texts.myCollaborationProgress1TaskPerformanceCssTaskPerformanceResults}
                text={capitalize(progressLevelText)}
              />
              <Box top spacing={smallSpacing}>
                <FigmaText
                  textKey={Texts.myCollaborationProgress1TaskPerformanceCssTaskPerformanceResults}
                  text={capitalize(userTop1PotentialRecommendationText)}
                />
              </Box>
            </Box>
          </HeroContainer>
          <Box fullWidth top spacing={minSpacing} />
          <Box fullWidth>
            <Component sessionInfo={sessionInfo} />
          </Box>
        </Box>
        <Box fullWidth top>
          <CollaborationProgressSessionButtons sessionInfo={sessionInfo} />
        </Box>
      </Container>
    </Box>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.big};
  background-color: ${MainColor};
`

const SkillContainer = styled(Box)<{ $isSelected: boolean }>`
  border-radius: ${BorderRadixes.moderate};
  background-color: ${({ $isSelected }) => ($isSelected ? White : MainFrameBG)};
`

const HeroContainer = styled(Box)`
  border-top-left-radius: ${BorderRadixes.moderate};
  border-top-right-radius: ${BorderRadixes.moderate};
  background-color: ${MainFrameBG};
`

export const VerticalDivider = styled(Box)`
  background-color: ${MainColor};
  height: 100%;
`

const Triangle = styled(Box)`
  margin-left: -10px;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-top: 10px solid white;
`
