import styled from '@emotion/styled'
import React from 'react'
import { MainColor } from '../../../pure-js/libs/Colors'
import { USER_WIDGET_MENU_ID } from '../../../pure-js/libs/Consts'
import { getInitials } from '../../../pure-js/libs/UserWidgetHelper'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { useUser } from '../hooks/useUser'
import { isPropValid } from '../libs/EmotionHelper'
import { USER_WIDGET_SIZE } from '../libs/HardCodedSizes'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
import { PersonIcon } from './Icons'
import UserWidgetMenu from './UserWidgetMenu'

type Options = { enablePhoto?: boolean }

export default function UserWidget({ opts, disabled, size }: { opts?: Options; disabled?: boolean; size?: number }) {
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement>()
  const { data: user } = useUser()
  const onClick = (e) => !disabled && setAnchorEl(e.currentTarget)

  if (!user) return null
  return (
    <>
      <Box
        onClick={onClick}
        id={USER_WIDGET_MENU_ID /** used by keywords drawer, dont have two nodes in dom withthis id */}
      >
        <UserWidgetView userId={user.id} disabled={disabled} size={size} />
      </Box>
      <UserWidgetMenu anchorEl={anchorEl} onClose={() => setAnchorEl(undefined)} />
    </>
  )
}

export const UserWidgetView = ({
  userId,
  size = USER_WIDGET_SIZE,
  textKey = Texts.cssUserWidget,
  link = true,
  disabled
}: {
  userId: string
  size?: number
  textKey?: TextKey
  link?: boolean
  disabled?: boolean
}) => {
  return (
    <Container align="center" justify="center" pointer={link} size={size} disabled={disabled}>
      <UserWidgetContent userId={userId} size={size} textKey={textKey} />
    </Container>
  )
}

export const UserWidgetContent = ({ userId, size, textKey }: { userId: string; size: number; textKey: TextKey }) => {
  const { data: user } = useUser(userId)

  const initials = getInitials(user?.displayName || user?.email || '')

  if (initials) return <FigmaText textKey={textKey} text={initials} />

  return <PersonIcon size={size} />
}

const Container = styled(Box, { shouldForwardProp: isPropValid })<{
  size: number
  $backgroundColor?: string
  disabled?: boolean
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: ${({ size }) => size / 2}px;
  opacity: ${({ disabled }) => (disabled ? 0.5 : 1)};
  background-color: ${MainColor};
`
