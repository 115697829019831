/* eslint-disable no-restricted-globals */
import styled from '@emotion/styled'
import Divider from '@mui/material/Divider'
import Menu from '@mui/material/Menu'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { littleSpacing, mediumSpacing } from '../enums/Spacings'
import { useLogout } from '../hooks/useLogout'
import { useNavigate } from '../hooks/useNavigate'
import { useUser } from '../hooks/useUser'
import { withStopProgagation } from '../libs/HtmlHelper'
import { hasCompletedIntroduction } from '../libs/SignedInMachine'
import { Texts } from '../../../pure-js/libs/Texts'
import FigmaText from './FigmaText'
import { MenuItem } from './MenuItem'

type Props = {
  anchorEl?: HTMLElement
  onClose: (e?) => void
}

export default function UserWidgetMenu(props: Props) {
  const { anchorEl } = props
  const { data: user } = useUser()
  const navigate = useNavigate()

  const onLogout = () => {
    props.onClose()
    navigate(RoutePath.ROOT)
  }

  const logout = useLogout(onLogout)

  const onClose = withStopProgagation(props.onClose)

  function onClickJoinTeam() {
    navigate(RoutePath.JOIN_TEAM)
  }

  function onClickProfile() {
    navigate(RoutePath.PROFILE)
  }

  return (
    <div>
      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={onClose}>
        <MenuItemTitle textKey={Texts.cssUserWidget} text={user.displayName || user.email} />

        {hasCompletedIntroduction(user) && (
          <MenuItem textKey={Texts.componentsUserMenuUserProfile} onClick={onClickProfile} />
        )}

        <MenuItem textKey={Texts.componentsUserMenuJoinNewTeam} onClick={onClickJoinTeam} />

        <Divider />

        <MenuItem onClick={logout} textKey={Texts.componentsUserMenuLogOutUser} />
      </Menu>
    </div>
  )
}

const MenuItemTitle = styled(FigmaText)`
  padding: ${littleSpacing} ${mediumSpacing};
  display: block;
`
