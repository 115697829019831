import { MIN_SLOT_TIME_INTERVAL_MINUTES } from './Consts'
import { SessionWithSlots } from '../types/Session'
import { Slot } from '../types/Slot'
import { dayjs } from '../../transition-web/src/libs/DayJsHelper'

export function getElligebleSessions(sessions: SessionWithSlots[]): SessionWithSlots[] {
  return sessions.filter((s) => s.slots.filter(isElligbleSlot).length > 0)
}

export const isElligbleSlot = (s: Slot) => {
  return (
    s.actual_game_finish_time &&
    dayjs(s.actual_game_finish_time).diff(s.start_time, 'minutes') >= MIN_SLOT_TIME_INTERVAL_MINUTES
  )
}
