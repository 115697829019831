import styled from '@emotion/styled'
import { SecondayColor } from '../../../pure-js/libs/Colors'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { ImageKey, TextKey } from '../../../pure-js/types/Antiloop'
import Box, { BoxProps } from '../components/Box'
import config from '../Config'
import { defaultSpacing } from '../enums/Spacings'
import { State } from '../hooks/useAppState'
import { Images } from '../../../pure-js/libs/Images'
import { Texts } from '../../../pure-js/libs/Texts'

export const isSelectedPage = ({ window, menuItem }: { window: Window; menuItem: MenuItem }) => {
  const pathname = window.location.pathname
  const menuItemInfo = MenuItemInfo[menuItem]
  if (pathname === '/' && menuItemInfo.isDefault) return true
  return window.location.pathname === menuItemInfo.routePath
}

export enum MenuItem {
  dashboard = 'dashboard',
  collaboration = 'collaboration',
  onboarding = 'onboarding',
  profile = 'profile',
  theory = 'theory',
  playgame = 'playgame'
}

const PlayGameContainer = styled(Box)`
  border-radius: 100px;
  border: 1px solid ${SecondayColor};
  margin-top: ${defaultSpacing};
  padding-left: ${defaultSpacing};
`

export const MenuItemInfo: {
  [property in MenuItem]: {
    routePath: RoutePath
    imageKey: ImageKey
    menuTextKey: TextKey
    appBarTextKey: TextKey
    isDefault?: boolean
    ContainerComponent?: React.FC<BoxProps>
    onBeforeNavigation?: (state: State) => Promise<void>
    enableInLeftMenu?: boolean
    enableWhenNotOnboarded?: boolean
    enableWhenNoSessions?: boolean
  }
} = {
  dashboard: {
    routePath: RoutePath.DASHBOARD,
    imageKey: Images.menuIconDashboard,
    menuTextKey: Texts.componentsAppMenuMenuDashboard,
    appBarTextKey: Texts.dashboardDashboardHeader,
    isDefault: true
  },
  collaboration: {
    routePath: RoutePath.COLLABORATION,
    imageKey: Images.menuIconCollaborationProgress,
    menuTextKey: Texts.componentsAppMenuMenuCollaboration,
    appBarTextKey: Texts.myCollaborationProgressCollaborationProgressHeader,
    enableWhenNoSessions: false
  },
  onboarding: {
    routePath: RoutePath.ONBOARDING,
    imageKey: Images.menuIconOnboarding,
    menuTextKey: Texts.componentsAppMenuMenuOnboarding,
    appBarTextKey: Texts.componentsAppMenuMenuOnboarding,
    enableInLeftMenu: config.enableOnboarding,
    enableWhenNotOnboarded: true
  },
  profile: {
    routePath: RoutePath.PROFILE,
    imageKey: Images.menuIconOnboarding,
    menuTextKey: Texts.componentsAppMenuMenuProfile,
    appBarTextKey: Texts.otherPagesProfilePageHeader,
    enableInLeftMenu: false,
    enableWhenNotOnboarded: true
  },
  theory: {
    routePath: RoutePath.THEORY_BEHIND,
    imageKey: Images.menuIconTheoryBehind,
    menuTextKey: Texts.componentsAppMenuMenuTheTheoryBehind,
    appBarTextKey: Texts.componentsAppMenuMenuTheTheoryBehind,
    enableWhenNotOnboarded: true
  },
  playgame: {
    routePath: RoutePath.GAME_CENTER,
    imageKey: Images.menuIconPlayGame,
    menuTextKey: Texts.componentsAppMenuMenuPlayGame,
    appBarTextKey: Texts.gameCenterPlayGamePageHeader,
    ContainerComponent: PlayGameContainer
  }
}
