import styled from '@emotion/styled'

import { Skill } from '../../../pure-js/enums/Skill'
import { MainFrameBG } from '../../../pure-js/libs/Colors'
import {
  AllSkillInfoDevelopmentItemInfos,
  SkillInfoDevelopmentItemInfoKey
} from '../../../pure-js/libs/SkillDevelopmentItemInfo'
import { SessionInfo, SkillItemProgress } from '../../../pure-js/types/Session'
import { defaultSpacing, smallSpacing } from '../enums/Spacings'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { GaugeComponent } from './GuageComponent'

export function CollaborationProgressSessionSkillDevelopmentItemsDecisionMakingPrioritizedDecisionMaking({
  className,
  sessionInfo,
  skill,
  skillDevelopmentItemKey
}: {
  skill: Skill
  sessionInfo: SessionInfo
  className?: string
  skillDevelopmentItemKey: SkillInfoDevelopmentItemInfoKey
}) {
  const itemInfo = AllSkillInfoDevelopmentItemInfos[skillDevelopmentItemKey]
  const { textKey, descriptionTextKey, imageKey } = itemInfo

  const item: SkillItemProgress = sessionInfo.skills[skill].items[skillDevelopmentItemKey]

  const { userDisplayValue } = item

  return (
    <>
      <Coantiner fullWidth fullPadding className={className} fullHeight justify="space-between">
        <Box fullWidth>
          <Box fullWidth direction="row" align="center">
            <Box>
              <FigmaImageContainer imageKey={imageKey} />
            </Box>
            <Box left spacing={smallSpacing}>
              <FigmaText textKey={textKey} />
            </Box>
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <FigmaText textKey={descriptionTextKey} />
          </Box>
        </Box>

        <Box fullWidth top spacing={defaultSpacing}>
          <GaugeComponent value={userDisplayValue * 100} />
        </Box>
      </Coantiner>
    </>
  )
}

const Coantiner = styled(Box)`
  background-color: ${MainFrameBG};
`
