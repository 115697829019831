import styled from '@emotion/styled'
import { SkillDevelopmentItemInfo } from '../../../pure-js/libs/SkillDevelopmentItemInfo'
import { MainFrameBG, SecondaryText } from '../../../pure-js/libs/Colors'
import { Images } from '../../../pure-js/libs/Images'
import { Texts } from '../../../pure-js/libs/Texts'
import { toPercent } from '../../../pure-js/libs/getSessionInfo'
import { SessionInfo, SkillItemProgress } from '../../../pure-js/types/Session'
import { defaultSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useUser } from '../hooks/useUser'
import Box from './Box'
import { DashboardMyTeamUserItemLoggedInUserWidget, getInitialsForUser } from './DashboardMyTeamUserWidget'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { BIG_ICON_SIZE } from './Icons'
import { ProgressLevelIcon } from './ProgressLevelIcon'
import { HardcodedTextKeys } from '../libs/HardcodedTextKeys'

export function CollaborationProgressSessionSkillDevelopmentItem({
  item,
  itemInfo,
  className,
  sessionInfo
}: {
  item: SkillItemProgress
  itemInfo: SkillDevelopmentItemInfo
  sessionInfo: SessionInfo
  className?: string
}) {
  const { state } = useAppState()
  const { previousSessions } = sessionInfo
  const hasLastSession = previousSessions.length > 0
  const {
    textKey,
    descriptionTextKey,
    imageKey,
    enableAlways100Percent,
    enableYourScoreRatio = true,
    enableRelativeProgress = true
  } = itemInfo
  const { data: user } = useUser()
  const {
    teamRelativeProgressPercentage = 0,
    yourRelativeProgressPercentage = 0,
    teamRelativeProgressPercentageProgressLevel,
    yourRelativeProgressPercentageProgressLevel,
    yourScoreRatio,
    formattedTeamDisplayValue: teamDisplayValue,
    formattedUserDisplayValue: userDisplayValue
  } = item

  return (
    <>
      <Coantiner fullWidth fullPadding className={className} fullHeight justify="space-between">
        <Box fullWidth>
          <Box fullWidth direction="row" align="center">
            <Box>
              <FigmaImageContainer imageKey={imageKey} />
            </Box>
            <Box left spacing={smallSpacing}>
              <FigmaText textKey={textKey} />
            </Box>
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <FigmaText textKey={descriptionTextKey} />
          </Box>
        </Box>
        <Box fullWidth top spacing={defaultSpacing}>
          <Box fullWidth direction="row" align="center" justify="space-between">
            <Box direction="row" align="center">
              <Box>
                <FigmaImageContainer imageKey={Images.collaborationProgressSessionTaskPerformanceEventItemTeamIcon} />
              </Box>

              <Box left spacing={smallSpacing}>
                <FigmaText
                  textKey={Texts.cssCollaborationProgressSessionTaskPerformanceEventItemTeamData}
                  text={enableAlways100Percent ? '100%' : `${teamDisplayValue || 0}`}
                />
              </Box>
            </Box>
            <Box
              direction="row"
              align="center"
              visibility={
                !enableAlways100Percent &&
                hasLastSession &&
                teamRelativeProgressPercentage !== undefined &&
                teamRelativeProgressPercentageProgressLevel !== undefined
                  ? 'visible'
                  : 'hidden'
              }
            >
              <Box>
                <FigmaText
                  textKey={Texts.cssCollaborationProgressSessionTaskPerformanceEventItemTeamDataRelative}
                  text={`${teamRelativeProgressPercentage > 0 ? '+' : ''}${teamRelativeProgressPercentage.toFixed()} %`}
                />
              </Box>
              <Box left spacing={smallSpacing}>
                <ProgressLevelIcon size={BIG_ICON_SIZE} progressLevel={teamRelativeProgressPercentageProgressLevel} />
              </Box>
            </Box>
          </Box>
          <Box fullWidth top spacing={tinySpacing} direction="row" align="center" justify="space-between">
            <Box direction="row" align="center">
              <DashboardMyTeamUserItemLoggedInUserWidget
                user={getInitialsForUser(user) ? user : state.user}
                size={Images.collaborationProgressSessionTaskPerformanceEventItemTeamIcon.width}
              />
              <Box left spacing={smallSpacing} direction="row" align="center">
                <Box>
                  <FigmaText
                    textKey={Texts.cssCollaborationProgressSessionTaskPerformanceEventItemYourData}
                    text={userDisplayValue.toString()}
                  />
                </Box>
                {enableYourScoreRatio && (
                  <Box left spacing={smallSpacing}>
                    <FigmaText
                      textKey={HardcodedTextKeys.cssOrangeSmallText}
                      text={`(${toPercent(yourScoreRatio)} %)`}
                    />
                  </Box>
                )}
              </Box>
            </Box>
            <Box
              direction="row"
              align="center"
              visibility={
                enableRelativeProgress &&
                hasLastSession &&
                yourRelativeProgressPercentage !== undefined &&
                yourRelativeProgressPercentageProgressLevel !== undefined
                  ? 'visible'
                  : 'hidden'
              }
            >
              <Box>
                <FigmaText
                  textKey={Texts.cssCollaborationProgressSessionTaskPerformanceEventItemTeamDataRelative}
                  text={`${yourRelativeProgressPercentage > 0 ? '+' : ''}${yourRelativeProgressPercentage.toFixed()} %`}
                />
              </Box>
              <Box left spacing={smallSpacing}>
                <ProgressLevelIcon size={BIG_ICON_SIZE} progressLevel={yourRelativeProgressPercentageProgressLevel} />
              </Box>
            </Box>
          </Box>
        </Box>
      </Coantiner>
    </>
  )
}

const Coantiner = styled(Box)`
  background-color: ${MainFrameBG};
`
