import * as Auth from 'firebase/auth'
import { auth } from './Firebase'

export const signInWithPopup = (provider: Auth.AuthProvider) => Auth.signInWithPopup(auth, provider)

export const signInWithRedirect = (provider: Auth.AuthProvider) => Auth.signInWithRedirect(auth, provider)

export const signInWithEmailLink = (email: string, emailLink: string | undefined) =>
  Auth.signInWithEmailLink(auth, email, emailLink)

export const isSignInWithEmailLink = (emailLink: string) => Auth.isSignInWithEmailLink(auth, emailLink)

export const sendSignInLinkToEmail = (email: string, actionCodeSettings: Auth.ActionCodeSettings) =>
  Auth.sendSignInLinkToEmail(auth, email, actionCodeSettings)

export const fetchSignInMethodsForEmail = (email: string) => Auth.fetchSignInMethodsForEmail(auth, email)

export const signInWithCustomToken = (customToken: string) => Auth.signInWithCustomToken(auth, customToken)
