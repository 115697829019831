import Box from './Box'

export const Iframe = ({ url, name, ref }: { url: string; name: string; ref? }) => {
  return (
    <Box fullWidth fullHeight align="center" justify="center">
      <iframe
        ref={ref}
        title={name}
        src={url}
        width="100%"
        height="100%"
        style={{ border: 'none', textAlign: 'center', alignItems: 'center', display: 'flex' }}
      />
    </Box>
  )
}
