import styled from '@emotion/styled'
import { useState } from 'react'
import { MainColor } from '../../../pure-js/libs/Colors'
import { SignInState, SignInViewProps } from '../../../pure-js/types/SignInTypes'
import { AppBar } from '../components/AppBar'
import Box from '../components/Box'
import FigmaImageContainer from '../components/FigmaImageContainer'
import FigmaText from '../components/FigmaText'
import { Layout } from '../components/Layout'
import { LoadingPageLoggedIn } from '../components/LoadingPage'
import { PageContainer } from '../components/PageContainer'
import SignInSignUpForm from '../components/SignInSignUpForm'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing } from '../enums/Spacings'
import { useUserId } from '../hooks/useAppState'
import { LoginConatinerWidth } from '../libs/HardCodedSizes'
import { Images } from '../../../pure-js/libs/Images'
import { MenuItem } from '../libs/MenuHelper'
import { Texts } from '../../../pure-js/libs/Texts'
import { useNavigate } from 'react-router-dom'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { updateUser } from '../libs/DBApiHandler'
import { useUser } from '../hooks/useUser'

export const ProfilePage = () => {
  const { data: user, isLoading: isLoadingUser } = useUser(useUserId())
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const onPressContinue = ({ data: { email, displayName, phone } }: SignInState) =>
    Promise.resolve(setIsLoading(true))
      .then(() => user && updateUser({ ...user, email, displayName, phone }))
      .then(() => navigate(RoutePath.DASHBOARD))
      .finally(() => setIsLoading(false))

  const props = {
    isLoading,
    onPressContinue,
    signInState: { data: user as SignInViewProps['signInState']['data'] }
  } as SignInViewProps

  if (isLoadingUser) return <LoadingPageLoggedIn />

  return (
    <Layout enableMenu>
      <PageContainer fullWidth>
        <AppBar menuItem={MenuItem.profile} />
        <Box fullWidth align="center">
          <Box top spacing={reallyBigSpacing}>
            <Container fullWidth>
              <Box fullWidth direction="row" justify="space-between" align="center" fullPadding>
                <Box></Box>
                <Box>
                  <FigmaText
                    styleTextKey={Texts.onboardingCreateAccountCreateAccountHeader}
                    textKey={Texts.componentsUserMenuUserProfile}
                  />
                </Box>
                <Box onClick={() => navigate(-1)} pointer>
                  <FigmaImageContainer imageKey={Images.iconClosePage} />
                </Box>
              </Box>
              <Box align="center" right left spacing={reallyBigSpacing} fullWidth>
                <Box fullWidth>
                  <SignInSignUpForm {...props} buttonTextKey={Texts.onboardingStartPageCtaPrimaryOk} disableEditEmail />
                </Box>
              </Box>
              <Box top />
            </Container>
          </Box>
        </Box>
      </PageContainer>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${MainColor};
  border-radius: ${BorderRadixes.big};
  width: ${LoginConatinerWidth}px;
`
