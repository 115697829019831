import styled from '@emotion/styled'
import { BorderRadixes } from '../enums/BorderRadixes'
import { useUser } from '../hooks/useUser'
import { MenuItem, MenuItemInfo } from '../libs/MenuHelper'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import UserWidget from './UserWidget'
import FigmaImageContainer from './FigmaImageContainer'
import { Images } from '../../../pure-js/libs/Images'
import { smallSpacing } from '../enums/Spacings'
import config from '../Config'
import { LastOnboardingChapter } from '../libs/OnboardingChapterInfoItems'
import { useNavigate } from '../hooks/useNavigate'
import RoutePath from '../../../pure-js/libs/RoutePath'

export function AppBar({ menuItem }: { menuItem: MenuItem }) {
  const { appBarTextKey } = MenuItemInfo[menuItem]
  const { data: user, isLoading } = useUser()
  const { completedOnboardingSteps = [] } = user
  const navigate = useNavigate()
  return (
    <Box fullWidth>
      {!isLoading &&
        !completedOnboardingSteps.includes(LastOnboardingChapter) &&
        config.enableOnboarding &&
        menuItem !== MenuItem.onboarding && (
          <Box fullWidth bottom spacing={smallSpacing}>
            <OnboardingButton buttonType="secondary" fullWidth onClick={() => navigate(RoutePath.ONBOARDING)}>
              <Box align="center" direction="row">
                <Box>
                  <FigmaImageContainer imageKey={Images.imgDecisionMakingBlack} />
                </Box>
                <Box left>
                  <FigmaText textKey={Texts.onboardingCompleteYourOnboarding} />
                </Box>
              </Box>
            </OnboardingButton>
          </Box>
        )}
      <Box fullWidth direction="row" align="center" justify="space-between">
        <Box>
          <FigmaText styleTextKey={Texts.dashboardDashboardHeader} textKey={appBarTextKey} />
        </Box>
        <Box>
          <UserWidget />
        </Box>
      </Box>
    </Box>
  )
}

const OnboardingButton = styled(Button)`
  border-radius: ${BorderRadixes.small};
`
