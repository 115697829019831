import styled from '@emotion/styled'
import MaterialButton from '@mui/material/Button'
import { bigSpacing, tinySpacing } from '../enums/Spacings'

export type ButtonBaseProps = {
  spacing?: string
  verticalSpacing?: string
  isDisabled?: boolean
}

export default styled(MaterialButton, { shouldForwardProp: (prop) => prop !== 'verticalSpacing' })<ButtonBaseProps>`
  padding-left: ${({ spacing }) => spacing || bigSpacing};
  padding-right: ${({ spacing }) => spacing || bigSpacing};
  padding-top: ${({ verticalSpacing }) => verticalSpacing || tinySpacing};
  padding-bottom: ${({ verticalSpacing }) => verticalSpacing || tinySpacing};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  min-width: auto;
  text-transform: none;
  border-radius: 100px;
  opacity: ${({ isDisabled }) => (isDisabled ? 0.3 : 1)};
`
