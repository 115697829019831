import styled from '@emotion/styled'
import { MainColor, SecondayColor } from '../../../pure-js/libs/Colors'
import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { LoginConatinerWidth, SIGNED_IN_INVITE_USER_FORM_WIDTH } from '../libs/HardCodedSizes'
import { Images } from '../../../pure-js/libs/Images'
import { formatText, getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { SignInLandingIntractionLogo } from './SignInLandingIntractionLogo'

export default function SignedInInviteToTeamSuccess(props: SignedInViewProps) {
  const team = props.signedInState.data.team
  return (
    <Layout enableMenu>
      <Box fullWidth align="center">
        <Box top spacing={reallyBigSpacing}>
          <SignInLandingIntractionLogo />
        </Box>
        <Box top spacing={reallyBigSpacing}>
          <Container fullWidth>
            <Box fullWidth align="center" top>
              <Box width={`${SIGNED_IN_INVITE_USER_FORM_WIDTH}px`}>
                <Box fullWidth>
                  <FigmaText
                    textKey={Texts.onboardingInviteTeamMembersInviteSegmentHeader}
                    text={team?.name || getFigmaText(Texts.onboardingInviteTeamMembersInputInviteEmailHeader)}
                  />
                </Box>
                <Box fullWidth top spacing={smallSpacing} />
                <TeamNameContainer fullPadding spacing={tinySpacing} fullWidth>
                  <FigmaText
                    textKey={Texts.onboardingInviteTeamMembersInviteTeamInfo}
                    text={formatText(getFigmaText(Texts.onboardingInviteTeamMembersInviteTeamInfo), [team?.code])}
                  />
                </TeamNameContainer>
                <Box fullWidth top spacing={smallSpacing}>
                  <SuccessMessageContainer fullWidth fullPadding spacing={smallSpacing} alignText="center">
                    <FigmaText textKey={Texts.onboardingNotificationInvitesSent} />
                  </SuccessMessageContainer>
                </Box>
                <Box fullWidth top spacing={smallSpacing} align="center">
                  <Button
                    textKey={Texts.onboardingInviteTeamMembersCtaInviteGoToDashboard}
                    fullWidth
                    onClick={() => props.onPressContinue(props.signedInState)}
                  />
                </Box>
              </Box>
            </Box>
            <Box top />
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${MainColor};
  border-radius: ${BorderRadixes.big};
  width: ${LoginConatinerWidth}px;
  opacity: 0.8;
`

const TeamNameContainer = styled(Box)`
  border-radius: ${BorderRadixes.small};
  border: 1px dashed ${SecondayColor};
`

const SuccessMessageContainer = styled(Box)`
  border-radius: ${BorderRadixes.small};
  background-color: ${SecondayColor};
`
