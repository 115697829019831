import { DevIdsQueryParams } from '../../../pure-js/types/QueryParamTypes'
import useQueryParams from '../libs/useQueryParams'
import useFirebaseUser from './useFirebaseUser'
import { useUser } from './useUser'
import { isSuperUserEmail } from '../../../pure-js/libs/isSuperUser'

export const useTeamId = () => {
  const { data: user } = useUser()
  const qp = useQueryParams<DevIdsQueryParams>()
  const { data: firebaseUser } = useFirebaseUser()
  return isSuperUserEmail(firebaseUser) && qp.teamId ? qp.teamId : user?.team
}
