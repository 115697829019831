import { ImageKey, TextKey } from '../../../pure-js/types/Antiloop'
import { ProgressLevel } from '../../../pure-js/enums/ProgressLevel'
import { Images } from '../../../pure-js/libs/Images'
import { Texts } from '../../../pure-js/libs/Texts'

export const ProgressLevelsInfo: {
  [property in ProgressLevel]: {
    textKey: TextKey
    textKeySingleSession: TextKey
    arrowImageKey: ImageKey
    arrowWidthRatio: number
  }
} = {
  [ProgressLevel.muchBetter]: {
    textKey: Texts.componentsTrendTrendMajorUp,
    textKeySingleSession: Texts.componentsTrendResultGood,
    arrowImageKey: Images.majorUpArrow,
    arrowWidthRatio: 0.5
  },
  [ProgressLevel.better]: {
    textKey: Texts.componentsTrendTrendMinorUp,
    textKeySingleSession: Texts.componentsTrendResultGood,
    arrowImageKey: Images.minorUpArrow,
    arrowWidthRatio: 0.5
  },
  [ProgressLevel.same]: {
    textKey: Texts.componentsTrendResultOk,
    textKeySingleSession: Texts.componentsTrendResultOk,
    arrowImageKey: Images.unchangedArrow,
    arrowWidthRatio: 0.5
  },
  [ProgressLevel.worse]: {
    textKey: Texts.componentsTrendTrendMinorDown,
    textKeySingleSession: Texts.componentsTrendResultBad,
    arrowImageKey: Images.minorDownArrow,
    arrowWidthRatio: 0.5
  },
  [ProgressLevel.muchWorse]: {
    textKey: Texts.componentsTrendTrendMajorDown,
    textKeySingleSession: Texts.componentsTrendResultBad,
    arrowImageKey: Images.majorDownArrow,
    arrowWidthRatio: 0.5
  }
}
