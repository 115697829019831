import {
  SkillDevelopmentItemInfoCommunication,
  SkillDevelopmentItemCommunication
} from './SkillDevelopmentItemInfoCommunication'
import {
  SkillDevelopmentItemInfoDecisionMaking,
  SkillDevelopmentItemDecisionMaking
} from './SkillDevelopmentItemInfoDecisionMaking'
import { SkillDevelopmentItemInfoHelping, SkillDevelopmentItemHelping } from './SkillDevelopmentItemInfoHelping'
import { ImageKey, TextKey } from '../types/Antiloop'
import { Slot, SlotDataPoint } from '../types/Slot'
import { RecommendationLevels } from '../enums/RecommendationLevels'
import { Session, SkillItemProgress } from '../types/Session'
import { GetSessionInfoOptions } from './getSessionInfo'

type GetValueFunction = (
  {
    dataPoint,
    slots,
    uid,
    session
  }: {
    dataPoint: SlotDataPoint
    slots: Slot[]
    uid?: string
    session?: Session
  },
  f: GetSessionInfoOptions
) => number

export type SkillDevelopmentItemInfo = {
  textKey: TextKey
  imageKey: ImageKey
  descriptionTextKey: TextKey
  getValueForSlotDataPoint: GetValueFunction
  getDisplayValue?: GetValueFunction
  formatDisplayValue?: (n: number, f: GetSessionInfoOptions) => string
  weight: number
  enableAlways100Percent?: boolean
  metaDataPointKey?: keyof SlotDataPoint
  enableYourScoreRatio?: boolean
  enableRelativeProgress?: boolean
  recommendationTextKeys?: { [property in RecommendationLevels]: TextKey | undefined }
  calculatedBy?: {
    key: keyof SlotDataPoint
    weight: number
    getValueForSlotDataPoint: (p: SlotDataPoint, opts: GetSessionInfoOptions) => number
  }[]
  getRecommendationLevel?: (item: SkillItemProgress, slots: Slot[]) => RecommendationLevels | undefined
}

export type SkillInfoDevelopmentItemInfoKey =
  | SkillDevelopmentItemCommunication
  | SkillDevelopmentItemDecisionMaking
  | SkillDevelopmentItemHelping

export const AllSkillInfoDevelopmentItemInfos = {
  ...SkillDevelopmentItemInfoCommunication,
  ...SkillDevelopmentItemInfoHelping,
  ...SkillDevelopmentItemInfoDecisionMaking
}
