import { CloudFunction } from '../../../pure-js/enums/CloudFunction'
import config from '../Config'
import { State } from '../hooks/useAppState'
import * as Sentry from '../Sentry'
import { CloudFunctionErrorType } from './CloudFunctionError'
import { error } from './SlackHelper'

const IGNORED_ERRORS = [
  'Connection to Indexed Database server lost. Refresh the page to try again',
  'Failed to get document because the client is offline',
  'UnknownError Connection to Indexed Database server lost. Refresh the page to try again'
]

export const USER_EMAIL = 'userEmail'

export const captureException = (err) => {
  if (IGNORED_ERRORS.some((text) => err.message.includes(text))) return
  if (!config.enableSentry) return console.error(err)
  console.error(err)
  Sentry.captureException(err)
  Sentry.withScope((scope) => {
    if (err.message) error(err, scope.getUser())
  })
}

export const setSentryUser = (user: Sentry.User) => {
  Sentry.setUser(user)
  Sentry.setTag(USER_EMAIL, user.email)
}

export const captureServerError = (err: CloudFunctionErrorType, fnName: CloudFunction, state: State) => {
  if (!config.enableSentry) return
  return Sentry.withScope((scope) => {
    const fingerPrint = [fnName, err.code, err.message]

    if (err.details?.serverErrorCode) fingerPrint.push(err.details?.serverErrorCode)
    scope.setFingerprint(fingerPrint)

    if (state.user?.id)
      scope.setUser({ id: state.user?.id, email: state.user?.email || undefined, name: state.user?.displayName })

    const email = state.user?.email || state.firebaseUser?.email
    if (email) scope.setTag(USER_EMAIL, email)
    const exception = new Error(fingerPrint.join(' '))
    exception.name = 'RequestError'
    captureException(exception)
  })
}
