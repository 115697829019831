export const ONE_REM = 16
export const THREE_REM = ONE_REM * 3
export const SMALL_SPACING = 0.7
export const SMALL_SPACING_PX = SMALL_SPACING * ONE_REM
export const SMALL_SPACING_RADII = SMALL_SPACING_PX / 2

export const minSpacing = '2px'
export const tinySpacing = '0.35rem'
export const littleSpacing = '0.5rem'
export const smallSpacing = `${SMALL_SPACING}rem`
export const regularSpacing = '1rem'
export const defaultSpacing = '1.5rem'
export const mediumSpacing = '2.5rem'
export const bigSpacing = '3rem'
export const reallyBigSpacing = '6rem'
