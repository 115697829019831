import { getSessionInfo } from '../../../pure-js/libs/getSessionInfo'
import { SessionInfo } from '../../../pure-js/types/Session'
import { useSession } from './useSession'
import { useSessions } from './useSessions'
import { useUser } from './useUser'

export function useSessionInfo(sessionId: string): SessionInfo | undefined {
  const { data: user } = useUser()
  const sessionWithSlots = useSession(sessionId)
  const { data: sessionsWithSlots = [] } = useSessions()

  if (!sessionWithSlots) return undefined

  const sessionInfo = getSessionInfo({
    session: sessionWithSlots,
    uid: user?.id,
    sessionsWithSlots
  })
  return sessionInfo
}
