import { Skill } from '../../../pure-js/enums/Skill'
import { SessionInfo } from '../../../pure-js/types/Session'
import { CollaborationProgressSessionSkillDevelopmentItemsCommunication } from '../components/CollaborationProgressSessionSkillDevelopmentItemsCommunication'
import { CollaborationProgressSessionSkillDevelopmentItemsDecisionMaking } from '../components/CollaborationProgressSessionSkillDevelopmentItemsDecisionMaking'
import { CollaborationProgressSessionSkillDevelopmentItemsHelping } from '../components/CollaborationProgressSessionSkillDevelopmentItemsHelping'
import { CollaborationProgressSessionTaskPerformance } from '../components/CollaborationProgressSessionTaskPerformance'

export type CollaborationProgressSessionSkillDevelopmentProps = {
  sessionInfo: SessionInfo
}

export const SkillInfoTransitionWeb: {
  [property in Skill]: {
    Component?: React.FC<CollaborationProgressSessionSkillDevelopmentProps>
  }
} = {
  [Skill.TaskPerformance]: {
    Component: CollaborationProgressSessionTaskPerformance
  },
  [Skill.HelpingAndAssisting]: {
    Component: CollaborationProgressSessionSkillDevelopmentItemsHelping
  },
  [Skill.Communication]: {
    Component: CollaborationProgressSessionSkillDevelopmentItemsCommunication
  },
  [Skill.DecisionMaking]: {
    Component: CollaborationProgressSessionSkillDevelopmentItemsDecisionMaking
  }
}
