import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { SignInViewProps } from '../../../pure-js/types/SignInTypes'
import config from '../Config'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing, smallSpacing } from '../enums/Spacings'
import { LoginConatinerWidth } from '../libs/HardCodedSizes'
import { Images } from '../../../pure-js/libs/Images'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import ContinueWithButton from './ContinueWithButton'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { Layout } from './Layout'
import LoginWithEmailForm from './SignInLandingEmailForm'
import { SignInLandingIntractionLogo } from './SignInLandingIntractionLogo'

export default function SignInLanding(props: SignInViewProps) {
  return (
    <Layout>
      <Box fullWidth align="center">
        <Box top spacing={reallyBigSpacing}>
          <SignInLandingIntractionLogo />
        </Box>
        <Box top spacing={reallyBigSpacing}>
          <FigmaText textKey={Texts.onboardingStartPageLoginPageHeader} />
          <Box top spacing={smallSpacing}>
            <FigmaTextWithLinebreaks textKey={Texts.onboardingStartPageLoginPageSecondaryText} />
          </Box>
        </Box>
        <Box top spacing={reallyBigSpacing}>
          <Container fullWidth align="center" right left spacing={reallyBigSpacing}>
            <Box top spacing={smallSpacing}>
              <FigmaText textKey={Texts.onboardingStartPageLoginPageSectionHeader} />
            </Box>
            {config.enableGoogleLogin && (
              <Box top spacing={smallSpacing} fullWidth>
                <ContinueWithButton
                  onLogin={props.onLoginWithGoogle}
                  imageKey={Images.icons8Google1}
                  textKey={Texts.onboardingOtpPageCtaPrimaryVerifyCode}
                  text="Continue with Google"
                />
              </Box>
            )}
            <Box top spacing={smallSpacing} fullWidth>
              <LoginWithEmailForm {...props} />
            </Box>
            <Box top spacing={smallSpacing} pointer onClick={props.onClickSignUp}>
              <FigmaText textKey={Texts.onboardingStartPageCtaSecondaryCreateAccount} />
            </Box>
            <Box top spacing={smallSpacing} />
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${MainColor};
  border-radius: ${BorderRadixes.big};
  width: ${LoginConatinerWidth}px;
`
