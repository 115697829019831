import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { ENTER_KEYCODE } from '../../../pure-js/libs/Consts'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { SignInViewProps } from '../../../pure-js/types/SignInTypes'
import config from '../Config'
import { smallSpacing } from '../enums/Spacings'
import { useUserId } from '../hooks/useAppState'
import { useForm } from '../hooks/useForm'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { EmailValidation, IntractionPhoneValidation } from '../libs/ValidationHelper'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldFormExperimental } from './TextFieldForm'

const message = 'This field is mandatory'
export const ValidationSchema = Yup.object().shape({
  displayName: Yup.string().required(message).typeError(message).min(2, message),
  email: EmailValidation(),
  phone: IntractionPhoneValidation(message)
})

type Props = SignInViewProps & { buttonTextKey?: TextKey }

export default function SignInSignUpForm(props: Props) {
  const uid = useUserId()
  const { signInState } = props
  const { data } = signInState
  const { email = '', phone = '', displayName = '' } = data

  const defaultValues = { displayName, email, phone }

  const _onClick = (values) => props.onPressContinue({ ...signInState, data: { ...signInState.data, ...values } })

  const { control, handleSubmit } = useForm(ValidationSchema, { defaultValues })

  const onSubmit = handleSubmit(_onClick)

  const buttonTextKey = props.buttonTextKey || Texts.onboardingCreateAccountCtaCreateAccount

  return (
    <form onSubmit={_onClick}>
      <Box align="center" fullWidth>
        {config.enableUidOnProfilePage && (
          <Box fullWidth bottom spacing={smallSpacing}>
            <FigmaText textKey={Texts.onboardingCreateAccountInputEmailHeader} text={`uid: ${uid}`} />
          </Box>
        )}
        <Box fullWidth>
          <Controller
            control={control}
            name="displayName"
            render={(props) => (
              <TextFieldFormExperimental
                {...props}
                field={{
                  ...props.field,
                  onKeyDown: (e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)
                }}
                headerText={getFigmaText(Texts.onboardingCreateAccountInputDisplayNameHeader)}
              />
            )}
          />
        </Box>
        <Box fullWidth top>
          <Controller
            control={control}
            name="email"
            render={(p) => (
              <TextFieldFormExperimental
                {...p}
                field={{
                  ...p.field,
                  onChange: (e) => {
                    if (props.disableEditEmail) return
                    p.field.onChange(e)
                  },
                  onKeyDown: (e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)
                }}
                headerText={getFigmaText(Texts.onboardingCreateAccountInputEmailHeader)}
              />
            )}
          />
        </Box>
        <Box fullWidth top>
          <Controller
            control={control}
            name="phone"
            render={(props) => (
              <TextFieldFormExperimental
                {...props}
                field={{
                  ...props.field,
                  onKeyDown: (e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)
                }}
                headerText={getFigmaText(Texts.onboardingCreateAccountInputPhoneHeader)}
              />
            )}
          />
        </Box>
        <Box top fullWidth spacing={smallSpacing}>
          <Button fullWidth onClick={onSubmit} textKey={buttonTextKey}>
            <FigmaTextLoader textKey={buttonTextKey} loading={props.isLoading} />
          </Button>
        </Box>
      </Box>
    </form>
  )
}
