import { bigSpacing } from '../enums/Spacings'
import Box from './Box'
import { Layout } from './Layout'
import Loader from './Loader'

export function LoadingPageLoggedIn() {
  return (
    <Layout enableMenu>
      <LoadingPage />
    </Layout>
  )
}

export function LoadingPage() {
  return (
    <Box fullWidth fullPadding spacing={bigSpacing} align="center">
      <Loader />
    </Box>
  )
}
