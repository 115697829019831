import { Texts } from '../../../pure-js/libs/Texts'
import { SessionInfo } from '../../../pure-js/types/Session'

const AllTextKeys = Object.entries(Texts)
  .filter(([key]) => key.startsWith('reflectionsReflectionQuestion') && key !== 'reflectionsReflectionQuestion')
  .map(([key, value]) => value)
export const getTextKeys = ({ sessionNumber }: SessionInfo) => {
  return AllTextKeys.filter((x, i) => {
    const questionNumber = i + 1
    return questionNumber <= sessionNumber * 3 && questionNumber > (sessionNumber - 1) * 3
  })
}
