import styled from '@emotion/styled'
import React, { useEffect } from 'react'
import { AllSkillInfoDevelopmentItemInfos } from '../../../pure-js/libs/SkillDevelopmentItemInfo'
import { MainColor, MainFrameBG } from '../../../pure-js/libs/Colors'
import { Images } from '../../../pure-js/libs/Images'
import { Texts } from '../../../pure-js/libs/Texts'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { SessionInfo, SessionInfoPotential } from '../../../pure-js/types/Session'
import { BorderRadixes } from '../enums/BorderRadixes'
import { defaultSpacing, mediumSpacing, minSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { useUser } from '../hooks/useUser'
import { captureException } from '../libs/SentryHelper'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { BIG_ICON_SIZE } from './Icons'
import UserWidget from './UserWidget'
import { getFigmaText } from '../libs/TextRepository'
import { CollaborationProgressSessionButtons } from './CollaborationProgressSessionButtons'

export const CollaborationProgressSessionWhatToImprove = ({ sessionInfo }: { sessionInfo: SessionInfo }) => {
  const { userTop1Potential } = sessionInfo
  const sessionNumber = sessionInfo.sessionNumber
  const { data: user } = useUser()

  const userRecommendationTextKey = getTextKey(sessionInfo.userTop1Potential)

  const teamRecommendationTextKey = Texts['dynamicTxtTeamImprTeamImprForSession' + sessionNumber]

  useEffect(() => {
    if (!userRecommendationTextKey)
      captureException(
        new Error(
          'Cant show relevant user recommendation, sessionId: ' + sessionInfo?.session.id + ' user: ' + user?.id
        )
      )
  }, [])

  return (
    <Box fullWidth>
      <Box fullWidth>
        {userRecommendationTextKey && (
          <CollaborationProgressSessionWhatToImproveItem
            sessionInfo={sessionInfo}
            sessionInfoPotental={userTop1Potential}
            text={getFigmaText(userRecommendationTextKey)}
          />
        )}
      </Box>
      {teamRecommendationTextKey && (
        <Box top fullWidth>
          <CollaborationProgressSessionWhatToImproveItem
            headerTextKey={Texts.myCollaborationProgress3WhatToImproveWhatToImproveTeamHeader}
            sessionInfo={sessionInfo}
            IconComponent={() => (
              <FigmaImageContainer
                imageKey={Images.collaborationProgressSessionTaskPerformanceEventItemTeamIcon}
                width={BIG_ICON_SIZE * 2}
              />
            )}
            text={getFigmaText(teamRecommendationTextKey)}
            enableButtons
          />
        </Box>
      )}
    </Box>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.big};
  background-color: ${MainColor};
`

const InnerContainer = styled(Box)`
  background-color: ${MainFrameBG};
`

const HeaderContainer = styled(InnerContainer)`
  border-top-left-radius: ${BorderRadixes.moderate};
  border-top-right-radius: ${BorderRadixes.moderate};
`

const BodyContainer = styled(InnerContainer)`
  border-bottom-left-radius: ${BorderRadixes.moderate};
  border-bottom-right-radius: ${BorderRadixes.moderate};
`

type Props = {
  IconComponent?: React.FC
  headerTextKey?: TextKey
  sessionInfo: SessionInfo
  sessionInfoPotental?: SessionInfoPotential
}

function CollaborationProgressSessionWhatToImproveItem(props: Props & { text: string; enableButtons?: boolean }) {
  const { enableButtons } = props
  const {
    headerTextKey = Texts.myCollaborationProgress3WhatToImproveWhatToImprovePersonalHeader,
    IconComponent = UserWidget,
    text
  } = props

  return (
    <Container fullWidth fullPadding>
      <HeaderContainer fullWidth fullPadding>
        <Box fullWidth direction="row" align="center">
          <IconComponent />
          <Box left>
            <FigmaText
              styleTextKey={Texts.myCollaborationProgress4TimeToReflectTimeToReflectHeader}
              textKey={headerTextKey}
            />
          </Box>
        </Box>
      </HeaderContainer>
      <Box top spacing={minSpacing} />
      <BodyContainer fullWidth fullPadding direction="row" align="center" justify="space-between">
        {text && (
          <Box left spacing={tinySpacing} direction="row" align="center" fullWidth>
            <Box>
              <FigmaImageContainer imageKey={Images.iconResults} width={BIG_ICON_SIZE} />
            </Box>
            <Box left>
              <FigmaText textKey={Texts.reflectionsReflectionQuestion1} text={text} />
            </Box>
          </Box>
        )}
      </BodyContainer>
      {enableButtons && (
        <Box fullWidth top>
          <CollaborationProgressSessionButtons sessionInfo={props.sessionInfo} />
        </Box>
      )}
    </Container>
  )
}

const getTextKey = (sessionInfoPotental?: SessionInfoPotential) => {
  if (!sessionInfoPotental) return null

  if (!sessionInfoPotental.recommendationLevel) return null

  const sessionInfoDevelopmentItem = AllSkillInfoDevelopmentItemInfos[sessionInfoPotental?.skillDevelopmentItemKey]

  if (!sessionInfoDevelopmentItem) return null
  const textKey = sessionInfoDevelopmentItem.recommendationTextKeys?.[sessionInfoPotental.recommendationLevel]

  if (!textKey) return null

  return textKey
}
