import { CreateLoginTokenRequest } from '../types/types'
import { CreateAuthenticationTokenForLoginTokenResponse } from './CreateAuthenticationTokenForLoginTokenResponse'

export enum PreferredLoginMethod {
  MAIL = 'mail',
  GOOGLE = 'google',
  MICROSOFT = 'microsoft',
  NONE = 'none'
}

export type SignInState = {
  data: {
    email: string
    displayName?: string
    phone?: string
    code?: string
    loginToken?: CreateLoginTokenRequest
    createAuthenticationTokenForLoginTokenResponse?: CreateAuthenticationTokenForLoginTokenResponse
  }
  preferredLoginMethod: PreferredLoginMethod
  step: SignInSteps
}

export type SignInViewProps = {
  disableEditEmail?: boolean
  isLoading: boolean
  signInState: SignInState
  onPressContinue: (state: SignInState) => Promise<unknown>
  onTakeMeBack: () => unknown
  onLoginWithGoogle: () => unknown
  onLoginWithMicrosoft: () => unknown
  onLoginWithApple: () => unknown
  onClickBack: () => unknown
  onClickSignUp: () => unknown
}

export enum SignInSteps {
  LANDING = 'LANDING',
  SIGN_UP = 'SIGN_UP', // Deprecated plz remove
  ENTER_CODE = 'ENTER_CODE',
  DISABLED = 'DISABLED',
  ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIALS = 'ACCOUNT_EXISTS_WITH_DIFFERENT_CREDENTIALS'
}
