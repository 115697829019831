import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing } from '../enums/Spacings'
import { SessionInfo } from '../../../pure-js/types/Session'
import Box from './Box'
import { CollaborationProgressSessionSkillDevelopmentItem } from './CollaborationProgressSessionSkillDevelopmentItem'
import { SkillDevelopmentItemInfoCommunication } from '../../../pure-js/libs/SkillDevelopmentItemInfoCommunication'

export function CollaborationProgressSessionSkillDevelopmentItemsCommunication({
  sessionInfo
}: {
  sessionInfo: SessionInfo
}) {
  return (
    <Box fullWidth>
      <Box fullWidth direction="row">
        <CollaborationProgressSessionSkillDevelopmentItem
          item={sessionInfo.skills.Communication.items.AirTimeRelative}
          itemInfo={SkillDevelopmentItemInfoCommunication.AirTimeRelative}
          sessionInfo={sessionInfo}
        />
        <VerticalDivider />
        <CollaborationProgressSessionSkillDevelopmentItem
          item={sessionInfo.skills.Communication.items.ActiveListening}
          itemInfo={SkillDevelopmentItemInfoCommunication.ActiveListening}
          sessionInfo={sessionInfo}
        />
      </Box>
      <HorizontalDivider />
      <Box fullWidth direction="row">
        <StyledCollaborationProgressSessionSkillDevelopmentItemBottomLeft
          item={sessionInfo.skills.Communication.items.InteruptingOthers}
          itemInfo={SkillDevelopmentItemInfoCommunication.InteruptingOthers}
          sessionInfo={sessionInfo}
        />
        <VerticalDivider />
        <StyledCollaborationProgressSessionSkillDevelopmentItemBottomRight
          item={sessionInfo.skills.Communication.items.CommunicatingHowYouFeel}
          itemInfo={SkillDevelopmentItemInfoCommunication.CommunicatingHowYouFeel}
          sessionInfo={sessionInfo}
        />
      </Box>
    </Box>
  )
}

export const VerticalDivider = styled(Box)`
  width: ${minSpacing};
  background-color: ${MainColor};
  height: 100%;
`

export const HorizontalDivider = styled(Box)`
  width: 100%;
  height: ${minSpacing};
  background-color: ${MainColor};
`

export const StyledCollaborationProgressSessionSkillDevelopmentItemBottomRight = styled(
  CollaborationProgressSessionSkillDevelopmentItem
)`
  border-bottom-right-radius: ${BorderRadixes.moderate};
`

export const StyledCollaborationProgressSessionSkillDevelopmentItemBottomLeft = styled(
  CollaborationProgressSessionSkillDevelopmentItem
)`
  border-bottom-left-radius: ${BorderRadixes.moderate};
`
