import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing } from '../enums/Spacings'
import { SessionInfo } from '../../../pure-js/types/Session'
import Box from './Box'
import { CollaborationProgressSessionSkillDevelopmentItem } from './CollaborationProgressSessionSkillDevelopmentItem'
import { SkillDevelopmentItemInfoHelping } from '../../../pure-js/libs/SkillDevelopmentItemInfoHelping'

export function CollaborationProgressSessionSkillDevelopmentItemsHelping({
  sessionInfo
}: {
  sessionInfo: SessionInfo
}) {
  return (
    <Box fullWidth>
      <Box fullWidth direction="row">
        <StyledCollaborationProgressSessionSkillDevelopmentItemBottomLeft
          item={sessionInfo.skills.HelpingAndAssisting.items.ResourcesShared}
          itemInfo={SkillDevelopmentItemInfoHelping.ResourcesShared}
          sessionInfo={sessionInfo}
        />
        <VerticalDivider />
        <CollaborationProgressSessionSkillDevelopmentItem
          item={sessionInfo.skills.HelpingAndAssisting.items.AbilitiesShared}
          itemInfo={SkillDevelopmentItemInfoHelping.AbilitiesShared}
          sessionInfo={sessionInfo}
        />
        <StyledCollaborationProgressSessionSkillDevelopmentItemBottomRight
          item={sessionInfo.skills.HelpingAndAssisting.items.CoordinatingWithOthers}
          itemInfo={SkillDevelopmentItemInfoHelping.CoordinatingWithOthers}
          sessionInfo={sessionInfo}
        />
      </Box>
    </Box>
  )
}

export const VerticalDivider = styled(Box)`
  width: ${minSpacing};
  background-color: ${MainColor};
  height: 100%;
`

export const HorizontalDivider = styled(Box)`
  width: 100%;
  height: ${minSpacing};
  background-color: ${MainColor};
`

export const StyledCollaborationProgressSessionSkillDevelopmentItemBottomRight = styled(
  CollaborationProgressSessionSkillDevelopmentItem
)`
  border-bottom-right-radius: ${BorderRadixes.moderate};
`

export const StyledCollaborationProgressSessionSkillDevelopmentItemBottomLeft = styled(
  CollaborationProgressSessionSkillDevelopmentItem
)`
  border-bottom-left-radius: ${BorderRadixes.moderate};
`
