import styled from '@emotion/styled'
import { MainColor, MainFrameBG } from '../../../pure-js/libs/Colors'
import { minSpacing } from '../enums/Spacings'
import { SessionInfo } from '../../../pure-js/types/Session'
import Box from './Box'
import { CollaborationProgressSessionSkillsItem } from './CollaborationProgressItemSkillsItem'
import { Skill } from '../../../pure-js/enums/Skill'

export function CollaborationProgressSessionSkills({ sessionInfo }: { sessionInfo: SessionInfo }) {
  return (
    <Container fullWidth>
      <Box fullWidth direction="row">
        <SkillsContainer fullWidth right bottom spacing={minSpacing} fullHeight>
          <CollaborationProgressSessionSkillsItem sessionInfo={sessionInfo} skill={Skill.TaskPerformance} />
        </SkillsContainer>
        <SkillsContainer fullWidth left bottom spacing={minSpacing} right>
          <CollaborationProgressSessionSkillsItem sessionInfo={sessionInfo} skill={Skill.HelpingAndAssisting} right />
        </SkillsContainer>
      </Box>
      <Box fullWidth direction="row">
        <SkillsContainer fullWidth right top spacing={minSpacing}>
          <CollaborationProgressSessionSkillsItem sessionInfo={sessionInfo} skill={Skill.Communication} />
        </SkillsContainer>
        <SkillsContainer fullWidth left top spacing={minSpacing}>
          <CollaborationProgressSessionSkillsItem sessionInfo={sessionInfo} skill={Skill.DecisionMaking} right />
        </SkillsContainer>
      </Box>
    </Container>
  )
}

const Container = styled(Box)`
  background-color: ${MainFrameBG};
`

const SkillsContainer = styled(Box)`
  background-color: ${MainColor};
`
