import styled from '@emotion/styled'
import { MainColor, MainFrameBG } from '../../../pure-js/libs/Colors'
import { capitalize } from '../../../pure-js/libs/Common'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { SessionInfo } from '../../../pure-js/types/Session'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing, tinySpacing } from '../enums/Spacings'
import { Images } from '../../../pure-js/libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import { CollaborationProgressSessionTaskPerformanceEvents } from './CollaborationProgressSessionTaskPerformanceItems'
import { CollaborationProgressSessionButtons } from './CollaborationProgressSessionButtons'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { SkillsInfo } from '../../../pure-js/libs/SkillsInfo'

export const CollaborationProgressSessionTaskPerformance = ({ sessionInfo }: { sessionInfo: SessionInfo }) => {
  const { skills, previousSessions } = sessionInfo
  const { teamProgressLevel } = skills.TaskPerformance
  const skillInfo = SkillsInfo.TaskPerformance

  const progressLevelTextKeys = [
    previousSessions.length > 0
      ? skillInfo.resultSummary2TextKeys?.[teamProgressLevel]
      : skillInfo.resultSummary1TextKeys?.[teamProgressLevel]
  ]

  return (
    <Box fullWidth>
      <Container fullWidth fullPadding>
        <HeaderContainer fullWidth fullPadding>
          <Box fullWidth direction="row" align="center">
            <FigmaImageContainer imageKey={Images.iconResults} />
            <Box left>
              <FigmaText textKey={Texts.myCollaborationProgress1TaskPerformanceResultsSectionTitle} />
            </Box>
          </Box>
          <Box fullWidth top spacing={tinySpacing}>
            {progressLevelTextKeys
              .filter((t) => !!t)
              .map((progressLevelTextKey, k) => (
                <Box fullWidth top spacing={tinySpacing} key={k}>
                  <FigmaText
                    textKey={Texts.myCollaborationProgress1TaskPerformanceCssTaskPerformanceResults}
                    text={capitalize(getFigmaText(progressLevelTextKey as TextKey))}
                  />
                </Box>
              ))}
          </Box>
        </HeaderContainer>
        <Box fullWidth top spacing={minSpacing} />
        <CollaborationProgressSessionTaskPerformanceEvents sessionInfo={sessionInfo} />
        <Box fullWidth top>
          <CollaborationProgressSessionButtons sessionInfo={sessionInfo} />
        </Box>
      </Container>
    </Box>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.big};
  background-color: ${MainColor};
`

const HeaderContainer = styled(Box)`
  border-top-left-radius: ${BorderRadixes.moderate};
  border-top-right-radius: ${BorderRadixes.moderate};
  background-color: ${MainFrameBG};
`
