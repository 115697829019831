import styled from '@emotion/styled'
import { useEffect } from 'react'
import { OnboardingChapter } from '../../../pure-js/enums/OnboardingChapter'
import { MainColor, MainFrameBG, SecondaryFrameBG } from '../../../pure-js/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useUser } from '../hooks/useUser'
import { MenuItem } from '../libs/MenuHelper'
import { LastOnboardingChapter, OnboardingChapterInfoItems } from '../libs/OnboardingChapterInfoItems'
import { Texts } from '../../../pure-js/libs/Texts'
import { AppBar } from './AppBar'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { OnboardingChapterItem } from './OnboardingChapterItem'
import { PageContainer } from './PageContainer'

export const Onboarding = () => {
  const { state, setState } = useAppState()
  const { data: user, isLoading } = useUser()

  useEffect(() => {
    const { completedOnboardingSteps = [] } = user || {}
    let currentOnboardingChapter: OnboardingChapter | undefined | null = Object.values(OnboardingChapter).find(
      (chapter) => !completedOnboardingSteps.includes(chapter)
    )

    if (completedOnboardingSteps.includes(LastOnboardingChapter)) currentOnboardingChapter = null

    setState({
      ...state,
      currentOnboardingChapter
    })
  }, [isLoading])

  return (
    <PageContainer fullWidth fullPadding>
      <AppBar menuItem={MenuItem.onboarding} />
      <Box fullWidth top>
        <Container fullWidth fullPadding>
          {Object.values(OnboardingChapter).map((chapter, index) => (
            <Box fullWidth key={index}>
              <OnboardingChapterComponent chapter={chapter} index={index} />
            </Box>
          ))}
        </Container>
      </Box>
    </PageContainer>
  )
}

const OnboardingChapterComponent = ({ chapter, index }: { chapter: OnboardingChapter; index: number }) => {
  const { state, setState } = useAppState()
  const { currentOnboardingChapter = OnboardingChapter.Introduction } = state
  const { data: user } = useUser()
  const { completedOnboardingSteps = [] } = user || {}
  const item = OnboardingChapterInfoItems[chapter]
  const { headerTextKey, Component = OnboardingChapterItem, enabled = true } = item
  const isCurrentChapter = currentOnboardingChapter === chapter
  const isCompletedChapter = completedOnboardingSteps.includes(chapter)
  const badgeTextKey = isCompletedChapter
    ? Texts.onboardingOnbardingPageOnboardingStatusCompleted
    : Texts.onboardingOnbardingPageOnboardingStatusTodo

  const badgeButtonType = isCompletedChapter ? 'secondary' : 'white'

  if (!enabled) return null

  return (
    <Box fullWidth top spacing={index > 0 ? smallSpacing : '0px'} key={index}>
      <ChapterHeaderContainer
        fullWidth
        direction="row"
        fullPadding
        align="center"
        justify="space-between"
        onClick={() => setState({ ...state, currentOnboardingChapter: chapter })}
        pointer
      >
        <FigmaText textKey={headerTextKey} />
        <Button buttonType={badgeButtonType} textKey={badgeTextKey} />
      </ChapterHeaderContainer>
      <Divider />
      {isCurrentChapter && (
        <ChapterBodyContainer fullWidth direction="row" fullPadding>
          <Component chapter={chapter} />
        </ChapterBodyContainer>
      )}
    </Box>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background-color: ${MainColor};
`

const ChapterHeaderContainer = styled(Box)`
  background-color: ${MainFrameBG};
  border-radius: ${BorderRadixes.moderate} ${BorderRadixes.moderate} 0 0;
`

const ChapterBodyContainer = styled(Box)`
  background-color: ${SecondaryFrameBG};
  border-radius: 0 0 ${BorderRadixes.moderate} ${BorderRadixes.moderate};
`

const Divider = styled(Box)`
  height: 2px;
  width: 100%;
  background-color: ${MainColor};
`
