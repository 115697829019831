import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import { SignInState, SignInViewProps } from '../../../pure-js/types/SignInTypes'
import useAppState from '../hooks/useAppState'
import SignInSignUp from './SignInSignUp'

export default function SignedInSignUpForm(props: SignedInViewProps) {
  const { signedInState } = props
  const { state } = useAppState()
  const { email } = state.user
  const { displayName, phone } = props.signedInState.data

  const p = {
    signInState: { data: { email, displayName, phone } } as SignInState,
    onPressContinue: ({ data }) => props.onPressContinue({ ...signedInState, data: { ...signedInState.data, ...data } })
  } as SignInViewProps

  return <SignInSignUp {...p} disableEditEmail />
}
