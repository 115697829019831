import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { ENTER_KEYCODE } from '../../../pure-js/libs/Consts'
import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import { smallSpacing } from '../enums/Spacings'
import { useForm } from '../hooks/useForm'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import { createUniqueTeamName } from '../libs/createUniqueTeamId'
import Box from './Box'
import Button from './Button'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldFormExperimental } from './TextFieldForm'

const message = 'Enter name'
export const ValidationSchema = Yup.object().shape({
  teamName: Yup.string().required(message).typeError(message).min(2, message)
})

export default function SignedInCreateTeamForm(props: SignedInViewProps) {
  const { signedInState } = props
  const { data } = signedInState
  const { teamName = createUniqueTeamName() } = data

  const _onClick = (values) => props.onPressContinue({ ...signedInState, data: { ...signedInState.data, ...values } })

  const { control, handleSubmit } = useForm(ValidationSchema, { defaultValues: { teamName } })

  const onSubmit = handleSubmit(_onClick)
  return (
    <form onSubmit={_onClick}>
      <Box align="center" fullWidth>
        <Controller
          control={control}
          name="teamName"
          render={(props) => (
            <TextFieldFormExperimental
              {...props}
              field={{
                ...props.field,
                onKeyDown: (e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)
              }}
              headerText={getFigmaText(Texts.onboardingCreateNewTeamInputNewTeamNameHeader)}
            />
          )}
        />
        <Box top fullWidth spacing={smallSpacing}>
          <Button fullWidth onClick={onSubmit} textKey={Texts.onboardingCreateNewTeamCtaCreateNewTeam}>
            <FigmaTextLoader textKey={Texts.onboardingCreateNewTeamCtaCreateNewTeam} loading={props.isLoading} />
          </Button>
        </Box>
      </Box>
    </form>
  )
}
