import { CloudFunction } from '../../../pure-js/enums/CloudFunction.js';
import { ServerErrorDetails } from '../../../pure-js/types/ServerTypes.js'
import { State } from '../hooks/useAppState.js'

export type CloudFunctionErrorType = { code: string; message: string; details?: ServerErrorDetails }

export default class CloudFunctionError extends Error {
  cloudFunctionError: CloudFunctionErrorType
  fnName: CloudFunction
  state: State

  constructor(cloudFunctionError: CloudFunctionErrorType, fnName: CloudFunction, state: State) {
    super(cloudFunctionError.message)
    this.cloudFunctionError = cloudFunctionError
    this.fnName = fnName
    this.state = state
  }
}
