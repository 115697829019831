import { ProgressLevel } from '../enums/ProgressLevel'
import { SessionWithSlots } from '../types/Session'
import { SkillInfoItem } from './SkillsInfo'

const URIDIUM_COLLECTED_LEVEL_NEUTRAL = 1
const URIDIUM_COLLECTED_LEVEL_GOOD_TEAM = 50
const URIDIUM_COLLECTED_LEVEL_GOOD_USER = 25

export type GetProgressLevelProps = {
  skillScore: number
  otherSkillScores: number[] // TODO move to prevSkillScore instead
  skillInfoItem: SkillInfoItem
  session: SessionWithSlots
  uid?: string
  shouldLog?: boolean
}

export function getUserSkillScoreProgressLevelTaskPerformance(p: GetProgressLevelProps): ProgressLevel {
  const { skillScore, otherSkillScores } = p
  if (!skillScore) return ProgressLevel.same
  if (otherSkillScores.length === 0) return getProgressLevelFirstSessionTaskPerformance(p)

  const meanSkillScore = otherSkillScores.reduce((acc, skillScore, i, arr) => acc + skillScore / arr.length, 0)

  return getProgressLevelSecondSessionTaskPerformance((skillScore - meanSkillScore) / meanSkillScore)
}

export function getProgressLevelForSkillDevelopment({
  skillScore,
  otherSkillScores
}: GetProgressLevelProps): ProgressLevel {
  if (!skillScore) return ProgressLevel.same
  if (otherSkillScores.length === 0) return getProgressLevelFirstSessionSkillDevelopment(skillScore)

  const meanSkillScore = otherSkillScores.reduce((acc, skillScore, i, arr) => acc + skillScore / arr.length, 0)

  return getProgressLevelSecondSessionSkillDevelopment(skillScore - meanSkillScore)
}

export function getProgressLevelFirstSessionTaskPerformance({ skillScore, uid }: GetProgressLevelProps): ProgressLevel {
  if (uid && skillScore > URIDIUM_COLLECTED_LEVEL_GOOD_USER) return ProgressLevel.muchBetter
  if (!uid && skillScore >= URIDIUM_COLLECTED_LEVEL_GOOD_TEAM) return ProgressLevel.muchBetter
  if (skillScore >= URIDIUM_COLLECTED_LEVEL_NEUTRAL) return ProgressLevel.same
  return ProgressLevel.muchWorse
}

export function getProgressLevelSecondSessionTaskPerformance(scoreDifference: number): ProgressLevel {
  if (scoreDifference > 0.2) {
    return ProgressLevel.muchBetter
  } else if (scoreDifference > 0.1) {
    return ProgressLevel.better
  } else if (scoreDifference > -0.1) {
    return ProgressLevel.same
  } else if (scoreDifference > -0.2) {
    return ProgressLevel.worse
  } else {
    return ProgressLevel.muchWorse
  }
}

export function getProgressLevelFirstSessionSkillDevelopment(scoreDifference: number): ProgressLevel {
  switch (true) {
    case scoreDifference > 0.9:
      return ProgressLevel.muchBetter
    case scoreDifference > 0.1:
      return ProgressLevel.same
    default:
      return ProgressLevel.worse
  }
}

export function getProgressLevelSecondSessionSkillDevelopment(scoreDifference: number): ProgressLevel {
  if (scoreDifference > 0.1) {
    return ProgressLevel.muchBetter
  } else if (scoreDifference >= 0.025) {
    return ProgressLevel.better
  } else if (scoreDifference >= -0.025) {
    return ProgressLevel.same
  } else if (scoreDifference >= -0.1) {
    return ProgressLevel.worse
  } else {
    return ProgressLevel.muchWorse
  }
}
