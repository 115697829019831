import MaterialMenuItem, { MenuItemProps as MaterialMenuItemProps } from '@mui/material/MenuItem'
import { ReactElement } from 'react'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { smallSpacing } from '../enums/Spacings'
import Box from './Box'
import FigmaTextLoader from './FigmaTextLoader'
import { Texts } from '../../../pure-js/libs/Texts'

export type MenuItemProps = MaterialMenuItemProps & {
  button?: boolean
  textKey?: TextKey
  text?: string
  leftElement?: ReactElement
  element?: ReactElement
  rightElement?: ReactElement
  topElement?: ReactElement
  className?: string
  loading?: boolean
}

export const MenuItem = (props: MenuItemProps) => {
  const { textKey, leftElement, text, topElement, rightElement, className, loading, element, ...menuProps } = props
  return (
    <MaterialMenuItem {...menuProps}>
      <Box>
        <Box align="center" direction="row">
          <Box right spacing={leftElement ? smallSpacing : '0'}>
            {leftElement}
          </Box>
          <Box className={className}>
            {topElement}
            {element || (
              <FigmaTextLoader
                styleTextKey={Texts.componentsUserMenuUserProfile}
                textKey={textKey}
                text={text}
                loading={loading}
              />
            )}
          </Box>
          <Box>{rightElement}</Box>
        </Box>
      </Box>
    </MaterialMenuItem>
  )
}
