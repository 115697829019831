import styled from '@emotion/styled'
import { Skill } from '../../../pure-js/enums/Skill'
import { MainFrameBG } from '../../../pure-js/libs/Colors'
import { Images } from '../../../pure-js/libs/Images'
import {
  AllSkillInfoDevelopmentItemInfos,
  SkillInfoDevelopmentItemInfoKey
} from '../../../pure-js/libs/SkillDevelopmentItemInfo'
import { Texts } from '../../../pure-js/libs/Texts'
import { SessionInfo, SkillItemProgress } from '../../../pure-js/types/Session'
import { defaultSpacing, smallSpacing } from '../enums/Spacings'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { SkillDevelopmentItemDecisionMakingRiskProfileFormattedValues } from '../../../pure-js/libs/SkillDevelopmentItemInfoDecisionMaking'
import { DashboardMyTeamUserItemLoggedInUserWidget, getInitialsForUser } from './DashboardMyTeamUserWidget'
import { useUser } from '../hooks/useUser'
import useAppState from '../hooks/useAppState'

export function CollaborationProgressSessionSkillDevelopmentItemsDecisionMakingRiskProfile({
  className,
  sessionInfo,
  skill,
  skillDevelopmentItemKey
}: {
  skill: Skill
  sessionInfo: SessionInfo
  className?: string
  skillDevelopmentItemKey: SkillInfoDevelopmentItemInfoKey
}) {
  const { data: user } = useUser()
  const { state } = useAppState()
  const i = AllSkillInfoDevelopmentItemInfos[skillDevelopmentItemKey]
  const { imageKey, textKey } = i

  const item: SkillItemProgress = sessionInfo.skills[skill].items[skillDevelopmentItemKey]

  const { formattedUserDisplayValue } = item

  const descriptionTextKey = {
    [SkillDevelopmentItemDecisionMakingRiskProfileFormattedValues.Low]: Texts.riskProfileRiskProfile1Text,
    [SkillDevelopmentItemDecisionMakingRiskProfileFormattedValues.Medium]: Texts.riskProfileRiskProfileText2,
    [SkillDevelopmentItemDecisionMakingRiskProfileFormattedValues.High]: Texts.riskProfileRiskProfileText3
  }[formattedUserDisplayValue]

  return (
    <>
      <Coantiner fullWidth fullPadding className={className} fullHeight justify="space-between">
        <Box fullWidth>
          <Box fullWidth direction="row" align="center">
            <Box>
              <FigmaImageContainer imageKey={imageKey} />
            </Box>
            <Box left spacing={smallSpacing}>
              <FigmaText textKey={textKey} />
            </Box>
          </Box>
          <Box fullWidth top spacing={smallSpacing}>
            <FigmaText textKey={i.descriptionTextKey} />
          </Box>
        </Box>
        <Box fullWidth top spacing={defaultSpacing}>
          <Box fullWidth direction="row" align="center" justify="space-between">
            <Box direction="row" align="center">
              <Box>
                <DashboardMyTeamUserItemLoggedInUserWidget
                  user={getInitialsForUser(user) ? user : state.user}
                  size={Images.collaborationProgressSessionTaskPerformanceEventItemTeamIcon.width}
                />
              </Box>

              <Box left spacing={smallSpacing}>
                <FigmaText
                  textKey={Texts.cssCollaborationProgressSessionTaskPerformanceEventItemYourData}
                  text={formattedUserDisplayValue}
                />
              </Box>
            </Box>
          </Box>
          {descriptionTextKey && (
            <Box top>
              <FigmaText styleTextKey={Texts.riskProfileRiskProfile1Text} textKey={descriptionTextKey} />
            </Box>
          )}
        </Box>
      </Coantiner>
    </>
  )
}

const Coantiner = styled(Box)`
  background-color: ${MainFrameBG};
`
