import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing } from '../enums/Spacings'
import { SessionInfo } from '../../../pure-js/types/Session'
import Box from './Box'
import { CollaborationProgressSessionSkillDevelopmentItem } from './CollaborationProgressSessionSkillDevelopmentItem'
import {
  SkillDevelopmentItemDecisionMaking,
  SkillDevelopmentItemInfoDecisionMaking
} from '../../../pure-js/libs/SkillDevelopmentItemInfoDecisionMaking'
import { CollaborationProgressSessionSkillDevelopmentItemsDecisionMakingPrioritizedDecisionMaking } from './CollaborationProgressSessionSkillDevelopmentItemsDecisionMakingPrioritizedDecisionMaking'
import { Skill } from '../../../pure-js/enums/Skill'
import { CollaborationProgressSessionSkillDevelopmentItemsDecisionMakingRiskProfile } from './CollaborationProgressSessionSkillDevelopmentItemsDecisionMakingRiskProfile'

export function CollaborationProgressSessionSkillDevelopmentItemsDecisionMaking({
  sessionInfo
}: {
  sessionInfo: SessionInfo
}) {
  return (
    <Box fullWidth>
      <Box fullWidth direction="row">
        <StyledCollaborationProgressSessionSkillDevelopmentItemsDecisionMakingRiskProfileBottomLeft
          skill={Skill.DecisionMaking}
          skillDevelopmentItemKey={SkillDevelopmentItemDecisionMaking.RiskProfile}
          sessionInfo={sessionInfo}
        />
        <VerticalDivider />
        <CollaborationProgressSessionSkillDevelopmentItemsDecisionMakingPrioritizedDecisionMaking
          skill={Skill.DecisionMaking}
          skillDevelopmentItemKey={SkillDevelopmentItemDecisionMaking.PrioritizedDecisionMaking}
          sessionInfo={sessionInfo}
        />
        <StyledCollaborationProgressSessionSkillDevelopmentItemBottomRight
          item={sessionInfo.skills.DecisionMaking.items.TimeManagement}
          itemInfo={SkillDevelopmentItemInfoDecisionMaking.TimeManagement}
          sessionInfo={sessionInfo}
        />
      </Box>
    </Box>
  )
}

export const VerticalDivider = styled(Box)`
  width: ${minSpacing};
  background-color: ${MainColor};
  height: 100%;
`

export const HorizontalDivider = styled(Box)`
  width: 100%;
  height: ${minSpacing};
  background-color: ${MainColor};
`

export const StyledCollaborationProgressSessionSkillDevelopmentItemBottomRight = styled(
  CollaborationProgressSessionSkillDevelopmentItem
)`
  border-bottom-right-radius: ${BorderRadixes.moderate};
`

export const StyledCollaborationProgressSessionSkillDevelopmentItemsDecisionMakingRiskProfileBottomLeft = styled(
  CollaborationProgressSessionSkillDevelopmentItemsDecisionMakingRiskProfile
)`
  border-bottom-left-radius: ${BorderRadixes.moderate};
`
