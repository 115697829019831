export const TiertaryColor = '#D55252'
export const SecondayColor = '#FFAB40'
export const SecondaryText = '#9BC0D3'
export const MainColor = '#1A2A34'
export const MainFrameBG = '#2F4655'
export const SecondaryFrameBG = '#345062'
export const MainColorLighter = '#3E6273'
export const DivDivBG = SecondaryFrameBG
export const Black = '#000000'
export const White = '#FFFFFF'

export const PrivacyPolicyBgColor = '#D9D9D9'
