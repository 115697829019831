import { TextsDeprecated } from '../../../pure-js/libs/TextsDeprecated'
import { bigSpacing, reallyBigSpacing } from '../enums/Spacings'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { SignInLandingIntractionLogo } from './SignInLandingIntractionLogo'

export default function ErrorBoundaryView() {
  return (
    <Layout>
      <Box fullWidth fullPadding align="center" spacing={bigSpacing} fullHeight>
        <Box top spacing={reallyBigSpacing}>
          <SignInLandingIntractionLogo />
        </Box>
        <Box top>
          <FigmaText
            textKey={Texts.onboardingStartPageLoginPageHeader}
            text="Intraction is currently down for maintenence"
          />
        </Box>
        <Box top>
          <FigmaText
            textKey={Texts.onboardingStartPageLoginPageSecondaryText}
            text={getFigmaText(TextsDeprecated.errorMaintenanceDescription)}
          />
        </Box>
      </Box>
    </Layout>
  )
}
