import { ProgressLevel } from '../../../pure-js/enums/ProgressLevel'
import { Skill } from '../../../pure-js/enums/Skill'
import { getProgressLevelForSkillDevelopment } from '../../../pure-js/libs/ProgressLevelHelper'
import { SkillsInfo } from '../../../pure-js/libs/SkillsInfo'
import { Texts } from '../../../pure-js/libs/Texts'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { SessionInfo } from '../../../pure-js/types/Session'

export enum CollaborationProgressAreas {
  OverallProgress = 'OverallProgress',
  Communication = 'Communication',
  HelpingAndAssisting = 'HelpingAndAssisting',
  DecisionMaking = 'DecisionMaking',
  TaskPerformance = 'TaskPerformance',
  GeneralTip = 'GeneralTip',
  SessionSummary = 'SessionSummary'
}

type GetTextKeyForCollaborationSkillAreaArgs = {
  sessionInfo: SessionInfo
  prevSessionInfo?: SessionInfo
}

type CollaborationProgressAreaInfoItem = {
  session1TextKeys: {
    [property in ProgressLevel]: TextKey
  }
  session2TextKeys: {
    [property in ProgressLevel]: TextKey
  }
  getTextKey: (arg: GetTextKeyForCollaborationSkillAreaArgs) => TextKey
}

export const CollaborationProgressAreaInfo: {
  [property in CollaborationProgressAreas]: CollaborationProgressAreaInfoItem
} = {
  [CollaborationProgressAreas.OverallProgress]: {
    session1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1OverallProgressTipSession1Good,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1OverallProgressTipSession1Good,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1OverallProgressSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1OverallProgressSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1OverallProgressSession1Bad
    },
    session2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2OverallProgressMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2OverallProgressSlightlyBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2OverallProgressNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2OverallProgressSlightlyWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2OverallProgressMuchWorse
    },
    getTextKey: ({ sessionInfo, prevSessionInfo }) =>
      getTextKeyForUserOverallScore({
        sessionInfo,
        prevSessionInfo,
        collaborationProgressAreaInfoItem: CollaborationProgressAreaInfo[CollaborationProgressAreas.OverallProgress]
      })
  },
  [CollaborationProgressAreas.Communication]: {
    session1TextKeys: SkillsInfo.Communication.myCollaborationProgress1TextKeys,
    session2TextKeys: SkillsInfo.Communication.myCollaborationProgress2TextKeys,
    getTextKey: ({ sessionInfo, prevSessionInfo }) =>
      getTextKeyForSkillDevelopment({
        sessionInfo,
        prevSessionInfo,
        skill: Skill.Communication
      })
  },
  [CollaborationProgressAreas.HelpingAndAssisting]: {
    session1TextKeys: SkillsInfo.HelpingAndAssisting.myCollaborationProgress1TextKeys,
    session2TextKeys: SkillsInfo.HelpingAndAssisting.myCollaborationProgress2TextKeys,
    getTextKey: ({ sessionInfo, prevSessionInfo }) =>
      getTextKeyForSkillDevelopment({ sessionInfo, prevSessionInfo, skill: Skill.HelpingAndAssisting })
  },
  [CollaborationProgressAreas.DecisionMaking]: {
    session1TextKeys: SkillsInfo.DecisionMaking.myCollaborationProgress1TextKeys,
    session2TextKeys: SkillsInfo.DecisionMaking.myCollaborationProgress2TextKeys,
    getTextKey: ({ sessionInfo, prevSessionInfo }) =>
      getTextKeyForSkillDevelopment({ sessionInfo, prevSessionInfo, skill: Skill.DecisionMaking })
  },
  [CollaborationProgressAreas.TaskPerformance]: {
    session1TextKeys: SkillsInfo.TaskPerformance.myCollaborationProgress1TextKeys,
    session2TextKeys: SkillsInfo.TaskPerformance.myCollaborationProgress2TextKeys,
    getTextKey: ({ sessionInfo, prevSessionInfo }) =>
      getTextKeyForSkillDevelopment({ sessionInfo, prevSessionInfo, skill: Skill.TaskPerformance })
  },
  [CollaborationProgressAreas.GeneralTip]: {
    session1TextKeys: {
      // These are wrong
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1GeneralTipSession1Neutral,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1GeneralTipSession1Neutral,
      //
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1GeneralTipSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1GeneralTipSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1GeneralTipSession1Bad
    },
    session2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2GeneralTipMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2GeneralTipMuchBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2GeneralTipNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2GeneralTipSlightlyWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2GeneralTipMuchWorse
    },
    getTextKey: ({ sessionInfo, prevSessionInfo }) =>
      getTextKeyForUserOverallScore({
        sessionInfo,
        prevSessionInfo,
        collaborationProgressAreaInfoItem: CollaborationProgressAreaInfo[CollaborationProgressAreas.GeneralTip]
      })
  },
  /* [CollaborationProgressAreas.UserOverallScore]: {
    session1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1OverallProgressTipSession1Good,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1OverallProgressTipSession1Good,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1OverallProgressSession1Neutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1OverallProgressSession1Bad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1OverallProgressSession1Bad
    },
    session2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2OverallProgressMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2OverallProgressSlightlyBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2OverallProgressNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2OverallProgressSlightlyWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2OverallProgressMuchWorse
    },
    getTextKey: ({ sessionInfo, prevSessionInfo }) =>
      getTextKeyForUserOverallScore({
        sessionInfo,
        prevSessionInfo,
        collaborationProgressAreaInfoItem: CollaborationProgressAreaInfo[CollaborationProgressAreas.UserOverallScore]
      })
  },*/
  [CollaborationProgressAreas.SessionSummary]: {
    session1TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp1Mcp1SessionSummaryGood,
      [ProgressLevel.better]: Texts.dynamicTxtMcp1Mcp1SessionSummaryGood,
      [ProgressLevel.same]: Texts.dynamicTxtMcp1Mcp1SessionSummaryNeutral,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp1Mcp1SessionSummaryBad,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp1Mcp1SessionSummaryBad
    },
    session2TextKeys: {
      [ProgressLevel.muchBetter]: Texts.dynamicTxtMcp2Mcp2SessionSummaryMuchBetter,
      [ProgressLevel.better]: Texts.dynamicTxtMcp2Mcp2SessionSummarySlightlyBetter,
      [ProgressLevel.same]: Texts.dynamicTxtMcp2Mcp2SessionSummaryNoChange,
      [ProgressLevel.worse]: Texts.dynamicTxtMcp2Mcp2SessionSummarySlightlyWorse,
      [ProgressLevel.muchWorse]: Texts.dynamicTxtMcp2Mcp2SessionSummaryMuchWorse
    },
    getTextKey: ({ sessionInfo, prevSessionInfo }) =>
      getTextKeyForUserOverallScore({
        sessionInfo,
        prevSessionInfo,
        collaborationProgressAreaInfoItem: CollaborationProgressAreaInfo[CollaborationProgressAreas.SessionSummary]
      })
  }
}

const getTextKeyForUserOverallScore = ({
  sessionInfo,
  prevSessionInfo,
  collaborationProgressAreaInfoItem
}: GetTextKeyForCollaborationSkillAreaArgs & {
  collaborationProgressAreaInfoItem: CollaborationProgressAreaInfoItem
}) => {
  const progressLevel = sessionInfo.userProgressLevel

  return prevSessionInfo
    ? collaborationProgressAreaInfoItem.session2TextKeys[progressLevel]
    : collaborationProgressAreaInfoItem.session1TextKeys[progressLevel]
}

const getTextKeyForSkillDevelopment = ({
  sessionInfo,
  prevSessionInfo,
  skill
}: GetTextKeyForCollaborationSkillAreaArgs & { skill: Skill }) => {
  const skillInfoItem = SkillsInfo[skill]
  const skillScore = sessionInfo.skills[skill].userSkillScore
  const otherSkillScores = prevSessionInfo ? [prevSessionInfo.skills[skill].userSkillScore] : []
  const progressLevel = getProgressLevelForSkillDevelopment({
    skillScore,
    otherSkillScores,
    skillInfoItem,
    session: sessionInfo.session
  })

  return prevSessionInfo
    ? skillInfoItem.myCollaborationProgress2TextKeys[progressLevel]
    : skillInfoItem.myCollaborationProgress1TextKeys[progressLevel]
}
