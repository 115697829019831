/* eslint-disable camelcase */
export const AT_C1 = 60000
export const AT_C2 = 220000
export const AT_C3 = 90000
export const ATR_C1 = 0.2
export const ATR_C2 = 0.3
export const ATR_C3 = 0.25
export const TT_C1 = 20
export const TT_C2 = 150
export const TT_C3 = 0.33
export const TT_C4 = 20
export const RCPT_C1 = 70
export const RCPT_C2 = 70
export const OLT_C1 = 80
export const OLT_C2 = 80
export const nCPLX_C1 = -0.6
export const nCPLX_C2 = 1.5
export const nCPLX_C3 = 0.7768
export const nCPLX_C4 = 0.23
export const ShR_C1 = 6
export const ShR_C2 = 6
export const ShA_C1 = 15
export const ShA_C2 = 15
export const ShI_C1 = 15
export const ShI_C2 = 15
export const PS_C1 = 1.8
export const PS_C2 = 2.5
export const PS_C3 = 1.8
export const PS_C4 = 2.4
export const PS_C5 = 0.8
export const PITS_C1 = 3
export const PITS_C2 = 0.33
export const PITS_C3 = 1
export const TP_C1 = 150000
export const TP_C2 = 150000
export const nTFWR_C1 = 0
export const nTFWR_C2 = 12
export const nTFWR_C3 = 0.2
export const nTFWR_C4 = 6
export const URFR_C1 = 1
export const URFRAM_C1 = 1
export const ITI_C1 = 400000
export const ITI_C2 = 400000
export const ITO_C1 = 300000
export const ITO_C2 = 300000
export const TAMP_C1 = 0.1
export const TAMP_C2 = 0.2
export const TAMP_C3 = 0.15

export const communicatingAirtimeWeight = 0.1
export const communicatingAirtimeRelativeWeight = 0.27
export const communicatingTimesTalkedWeight = 0.16
export const communicatingActiveListeningWeight = 0.16
export const communicatingInteruptingOthersWeight = 0.16
export const communicatingHowYouFeelWeight = 0.15

export const helpingResourcesSharedWeight = 0.3
export const helpingAbilitySharedWeight = 0.2
export const helpingCoordinatingWithOthersWeight = 0.5

export const decisionMakingRiskProfilePathSelectedScoreWeight = 0.8
export const decisionMakingRiskProfilePathInitiatedTogetherSummariesWeight = 0.2

export const decisionMakingPrioritizedDecisionMakingTimeParalyzedWeight = 0.45
export const decisionMakingPrioritizedDecisionMakingRobotFailRateAtMapWeight = 0.1
export const decisionMakingPrioritizedDecisionMakingNumberOfTanksFullWhenReturningWeight = 0.1
export const decisionMakingPrioritizedDecisionMakingRobotFailRateWeight = 0.35

export const decisionMakingTimeManagementInactiveTimeInside = 0.3
export const decisionMakingTimeManagementInactiveTimeOutsideWeight = 0.7

export const decisionMakingRiskProfileWeight = 0.2
export const decisionMakingPrioritizedDecisionMakingWeight = 0.4
export const decisionMakingTimeManagementWeight = 0.4
