import styled from '@emotion/styled'
import { Drawer } from '@mui/material'
import { MainColor } from '../../../pure-js/libs/Colors'
import { Images } from '../../../pure-js/libs/Images'
import { bigSpacing } from '../enums/Spacings'
import { MENU_WIDTH } from '../libs/HardCodedSizes'
import { MenuItem } from '../libs/MenuHelper'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import { MenuItemRow } from './MenuItemRow'

export function Menu() {
  return (
    <Drawer
      variant="permanent"
      open
      sx={{
        '& .MuiDrawer-paper': {
          borderRight: 'none',
          backgroundColor: MainColor
        }
      }}
    >
      <Container fullPadding align="center">
        <Box>
          <FigmaImageContainer imageKey={Images.pngLogo} fullWidth />
        </Box>
        <Box top spacing={bigSpacing}>
          {Object.values(MenuItem)
            .map((menuItem, key) => <MenuItemRow key={key} menuItem={menuItem} />)
            .filter((c) => !!c)}
        </Box>
      </Container>
    </Drawer>
  )
}

const Container = styled(Box)`
  background-color: ${MainColor};
  height: 100vh;
  width: ${MENU_WIDTH}px;
`
