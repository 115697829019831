import { collection, doc, getDoc, getDocs, query, setDoc, updateDoc } from 'firebase/firestore'
import { CollectionGroups, Collections } from '../../../pure-js/libs/Collections.js'
import { mapQueryResponse, toBaseObject } from '../../../pure-js/libs/FirebaseStorageClientBaseHelper.js'
import { Questionnaire, QuestionnaireAnswer } from '../../../pure-js/types/Questionnaire.js'
import { CreateSessionRequest, Session } from '../../../pure-js/types/Session.js'
import { Slot, SlotDataPoint, UserSlotDataPointWithId } from '../../../pure-js/types/Slot.js'
import { Team } from '../../../pure-js/types/Team.js'
import { PartialUser, User } from '../../../pure-js/types/User.js'
import { CreateTeamRequest, Id } from '../../../pure-js/types/types.js'
import { getUsersForTeamIdQuery } from '../hooks/QueryHooks.js'
import { State } from '../hooks/useAppState.js'
import { db } from './Firebase.js'
import * as Repository from './FirebaseStorageClientForWebApp.js'
import { GetSessionsArgs } from './GetSessionsArgs.js'

export const updateUser = (user: PartialUser): Promise<unknown> =>
  setDoc(doc(db, `${Collections.USERS}/${user.id}`), user, { merge: true })

export const updateTeamIdForUser = (team: string | null, user: Partial<User>): Promise<unknown> =>
  updateDoc(doc(db, `${Collections.USERS}/${user.id}`), 'team', team)

export const getUser = (id: Id): Promise<User | undefined> =>
  getDoc(doc(db, `${Collections.USERS}/${id}`)).then(mapQueryResponse)

export const getTeam = (id: Id): Promise<Team | undefined> =>
  Repository.getObject(id, Collections.TEAMS) as Promise<Team | undefined>

export const updateTeam = (t: Team): Promise<Team> => Repository.updateObject(t, Collections.TEAMS)
export const createTeam = (t: CreateTeamRequest, state: State): Promise<Team> =>
  Repository.createObject(t, Collections.TEAMS, state) as Promise<Team>

export const getSessions = (teamId: string) =>
  getDocs(getSessionsForTeamIdQuery(teamId)).then(mapQueryResponse) as Promise<Session[]>

export const upsertSession = (s: CreateSessionRequest) =>
  setDoc(
    doc(db, `${Collections.TEAMS}/${s.teamId}/${CollectionGroups.SESSIONS}/${s.id}`),
    { ...toBaseObject(), ...s },
    {
      merge: true
    }
  )

export function getSessionsForTeamIdQuery(teamId: string | undefined) {
  return query(collection(db, `${Collections.TEAMS}/${teamId}/${CollectionGroups.SESSIONS}`))
}

export const getSlotsForSessions = async ({ sessionIds = [], teamId }: GetSessionsArgs): Promise<Slot[]> => {
  if (!teamId) return []
  if (sessionIds.length === 0) return []

  return Promise.all(
    sessionIds.map((sessionId) =>
      getDocs(
        collection(
          db,
          `${Collections.TEAMS}/${teamId}/${CollectionGroups.SESSIONS}/${sessionId}/${CollectionGroups.SLOTS}`
        )
      )
        .then(mapQueryResponse)
        .then((rows: Slot[]) => rows.map((row): Slot => ({ ...row, sessionId })))
        .then(async (slots) => {
          const slotDataPoints = await getUserDataPointMapsForSlots({
            slotIds: slots.map((s) => s.id),
            teamId,
            sessionId
          })
          return slots.map((slot) => ({
            ...slot,
            slotDataPoints: slotDataPoints.filter((dp) => dp.slotId === slot.id)
          }))
        })
    )
  ).then((rows) => rows.flat())
}

export const getUserDataPointMapsForSlots = async ({
  slotIds = [],
  teamId,
  sessionId
}: {
  slotIds: string[]
  teamId?: string
  sessionId?: string
}): Promise<SlotDataPoint[]> => {
  if (!teamId) return []
  if (!sessionId) return []
  return Promise.all(
    slotIds.map((slotId) =>
      getDocs(
        collection(
          db,
          `${Collections.TEAMS}/${teamId}/${CollectionGroups.SESSIONS}/${sessionId}/${CollectionGroups.SLOTS}/${slotId}/${CollectionGroups.DATA_POINTS}`
        )
      )
        .then(mapQueryResponse)
        .then((rows: UserSlotDataPointWithId[]) => rows.map((row): SlotDataPoint => ({ ...row, uid: row.id, slotId })))
    )
  ).then((slotDataPoints) => slotDataPoints.flat())
}

export const getUsersForTeam = (teamId: string): Promise<User[]> =>
  getDocs(getUsersForTeamIdQuery(teamId)).then(mapQueryResponse)

export const updateQuestionnaire = async (questionare: Questionnaire): Promise<Questionnaire> => {
  return setDoc(getQuesionaireQuery(questionare), questionare).then(() => questionare)
}

export const getQuesionaireQuery = ({ id }: { id: string }) => {
  return doc(db, Collections.QUESTIONARES, id)
}

export const updateQuestionareAnswer = async (
  questionnaireAnswer: QuestionnaireAnswer,
  questionaireId: string
): Promise<QuestionnaireAnswer> =>
  setDoc(
    getQuestionareAnswerQuery({ questionaireId, questionId: questionnaireAnswer.questionId }),
    questionnaireAnswer
  ).then(() => questionnaireAnswer)

export const getQuestionareAnswerQuery = ({
  questionId,
  questionaireId
}: {
  questionId: string
  questionaireId: string
}) => doc(db, Collections.QUESTIONARES, questionaireId || 'noId', CollectionGroups.QUESTIONS, questionId || 'noId')
