import { collection, doc, query, where } from 'firebase/firestore'
import { CollectionGroups, Collections } from '../../../pure-js/libs/Collections.js'
import { Questionnaire, QuestionnaireAnswer } from '../../../pure-js/types/Questionnaire.js'
import { Session } from '../../../pure-js/types/Session.js'
import { Slot } from '../../../pure-js/types/Slot.js'
import { Team } from '../../../pure-js/types/Team.js'
import { User } from '../../../pure-js/types/User.js'
import { getSessionsForTeamIdQuery } from '../libs/DBApiHandler.js'
import { db } from '../libs/Firebase.js'
import { getObjectQuery } from '../libs/FirebaseStorageClientForWebApp.js'
import { QuestionareQueryInputParams, getQuesionaireQuery, getQuestionareAnswerQuery } from '../libs/ProcessHandler.js'
import { useFirestoreQuery } from './useFirestoreQuery.js'

export const _useUser = (id: string) =>
  useFirestoreQuery<User>(doc(db, `${Collections.USERS}/${id}`), { fnName: 'useUser', enabled: !!id })

export const useUsersForTeam = (teamId?: string) =>
  useFirestoreQuery<User[]>(query(collection(db, Collections.USERS), where('team', '==', teamId || '')), {
    fnName: 'userUsersForTeam',
    enabled: !!teamId
  })

export const useUsersForSlot = (s?: Slot) =>
  useFirestoreQuery<User[]>(query(collection(db, Collections.USERS), where('id', 'in', s?.userIds || ['no-id'])), {
    fnName: 'userUsersForTeam',
    enabled: (s?.userIds || []).length > 1
  })

export const useTeam = (id?: string) =>
  useFirestoreQuery<Team>(getObjectQuery(id || 'noTeamId', Collections.TEAMS), {
    fnName: 'useTeam',
    enabled: !!id
  })

export const _useSession = (sessionId?: string, teamId?: string) =>
  useFirestoreQuery<Session>(
    doc(db, `${Collections.TEAMS}/${teamId || 'noTeamId'}/${CollectionGroups.SESSIONS}/${sessionId || 'noSessionId'}`),
    {
      fnName: 'useSession',
      enabled: !!sessionId && !!teamId
    }
  )

export const useUsersForTeamId = (teamId?: string) =>
  useFirestoreQuery<User[]>(getUsersForTeamIdQuery(teamId), {
    fnName: 'userUsersForTeamId',
    enabled: !!teamId
  })

export const _useSlots = (o: { sessionId?: string; teamId?: string }) =>
  useFirestoreQuery<Slot[]>(
    query(
      collection(
        db,
        `${Collections.TEAMS}/${o.teamId}/${CollectionGroups.SESSIONS}/${o.sessionId}/${CollectionGroups.SLOTS}`
      )
    ),
    {
      fnName: 'useSlots',
      enabled: Object.values(o).every(Boolean)
    }
  )

export const _useSessions = (teamId?: string) =>
  useFirestoreQuery<Session[]>(getSessionsForTeamIdQuery(teamId), {
    fnName: 'useSessions',
    enabled: !!teamId,
    enableLogOfPermissionFailures: false
  })

export function getUsersForTeamIdQuery(teamId: string | undefined) {
  return query(collection(db, Collections.USERS), where('team', '==', teamId || ''))
}

export const useQuestionaire = (q: QuestionareQueryInputParams) =>
  useFirestoreQuery<Questionnaire>(getQuesionaireQuery(q), {
    fnName: 'useQuestionaire'
  })

export const useQuestionaireAnswer = ({ questionaireId, questionId }) =>
  useFirestoreQuery<QuestionnaireAnswer>(getQuestionareAnswerQuery({ questionaireId, questionId }), {
    fnName: 'useQuestionaireAnswer',
    enabled: !!questionaireId && !!questionId
  })
