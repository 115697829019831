import invariant from 'invariant'

const RGBA_REGEX = /^rgba\((\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d{1,3}%?),\s*(\d*(?:\.\d+)?)\)$/

// convert RGBA color data to hex
export function rgba2hex(text: string) {
  const rows = text.match(RGBA_REGEX)
  invariant(rows, '!rows')
  const [res, r, g, b, a]: string[] = rows
  return rgbToHexN(parseInt(r), parseInt(g), parseInt(b))
}

function rgbToHexN(r, g, b) {
  const outParts = [r.toString(16), g.toString(16), b.toString(16)]

  // Pad single-digit output values
  outParts.forEach(function (part, i) {
    if (part.length === 1) {
      outParts[i] = '0' + part
    }
  })

  return '#' + outParts.join('')
}
