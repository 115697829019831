import RoutePath from '../../../pure-js/libs/RoutePath'
import { State } from '../hooks/useAppState'
import { hasCompletedIntroduction } from '../libs/SignedInMachine'

export const onLoginToIntraction = async (state: State, { navigate, window }) => {
  const { user } = state

  if (window.location.pathname !== RoutePath.ROOT) return

  if (!hasCompletedIntroduction(user)) return navigate(RoutePath.ONBOARDING)

  if (!user.lastSessionId) return navigate(RoutePath.DASHBOARD)

  if (hasCompletedIntroduction(user)) return navigate(RoutePath.COLLABORATION)
}
