import styled from '@emotion/styled'
import { CollaborationProgressSessionHeaderSkillsTabs } from '../../../pure-js/enums/CollaborationProgressSessionHeaderSkillsTabs'
import { Skill } from '../../../pure-js/enums/Skill'
import { SecondaryFrameBG } from '../../../pure-js/libs/Colors'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { Texts } from '../../../pure-js/libs/Texts'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { CollaborationSessionDetailsQueryParams } from '../../../pure-js/types/QueryParamTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { useNavigate } from '../hooks/useNavigate'
import useQueryParams from '../libs/useQueryParams'
import Box from './Box'
import {
  CollaborationSessionDetailsRouteParams,
  getCollaborationSessionDetailsRouteParams
} from '../libs/CollaborationProgressSessionButtonsHelper'
import FigmaText from './FigmaText'

export function CollaborationProgressSessionHeaderSkills() {
  const navigate = useNavigate()
  const qp = useQueryParams<CollaborationSessionDetailsQueryParams>()

  return (
    <Box fullWidth direction="row" align="center">
      {Object.entries(Items).map(([skillTab, { textKey }], key) => {
        const seledtedSkillTab = qp.skillTab || Skill.TaskPerformance
        const isSelected = skillTab === seledtedSkillTab
        const routeParms =
          CollaborationSessionDetailsRouteParams.find((p) => p.skillTab === skillTab) ||
          getCollaborationSessionDetailsRouteParams({
            skillTab: skillTab as CollaborationProgressSessionHeaderSkillsTabs
          })

        const Component = isSelected ? Container : Box

        return (
          <Box
            key={key}
            right
            onClick={() => navigate(RoutePath.COLLABORATION, routeParms)}
            pointer
            style={{ opacity: isSelected ? 1 : 0.5 }}
          >
            <Component fullPadding spacing={smallSpacing}>
              <FigmaText
                styleTextKey={
                  isSelected
                    ? Texts.myCollaborationProgressSessionDetailsSessionDetailsTabTitleSkillsDevelopment
                    : Texts.myCollaborationProgressSessionDetailsSessionDetailsTabTitleTaskPerformance
                }
                textKey={textKey}
              />
            </Component>
          </Box>
        )
      })}
    </Box>
  )
}

const Items: {
  [property in CollaborationProgressSessionHeaderSkillsTabs]: {
    textKey: TextKey
  }
} = {
  [CollaborationProgressSessionHeaderSkillsTabs.TaskPerformance]: {
    textKey: Texts.myCollaborationProgressSessionDetailsSessionDetailsTabTitleTaskPerformance
  },
  [CollaborationProgressSessionHeaderSkillsTabs.SkillsDevelopment]: {
    textKey: Texts.myCollaborationProgressSessionDetailsSessionDetailsTabTitleSkillsDevelopment
  },
  [CollaborationProgressSessionHeaderSkillsTabs.WhatToImprove]: {
    textKey: Texts.myCollaborationProgressSessionDetailsSessionDetailsTabTitleWhatToImprove
  },
  [CollaborationProgressSessionHeaderSkillsTabs.PutIntoPractice]: {
    textKey: Texts.myCollaborationProgressSessionDetailsSessionDetailsTabTitlePutIntoPractice
  }
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.regular};
  background-color: ${SecondaryFrameBG};
`
