import { CollaborationProgressSessionHeaderSkillsTabs } from '../../../pure-js/enums/CollaborationProgressSessionHeaderSkillsTabs'
import { Skill } from '../../../pure-js/enums/Skill'
import { CollaborationSessionDetailsQueryParams } from '../../../pure-js/types/QueryParamTypes'

export const getCollaborationSessionDetailsRouteParams = (p: CollaborationSessionDetailsQueryParams) => ({
  skill: undefined,
  ...p
})
const getParams = getCollaborationSessionDetailsRouteParams

export const CollaborationSessionDetailsRouteParams: CollaborationSessionDetailsQueryParams[] = [
  getParams({ skillTab: CollaborationProgressSessionHeaderSkillsTabs.TaskPerformance }),
  getParams({ skillTab: CollaborationProgressSessionHeaderSkillsTabs.SkillsDevelopment, skill: Skill.Communication }),
  // eslint-disable-next-line prettier/prettier
  getParams({ skillTab: CollaborationProgressSessionHeaderSkillsTabs.SkillsDevelopment, skill: Skill.HelpingAndAssisting }),
  getParams({ skillTab: CollaborationProgressSessionHeaderSkillsTabs.SkillsDevelopment, skill: Skill.DecisionMaking }),
  getParams({ skillTab: CollaborationProgressSessionHeaderSkillsTabs.WhatToImprove }),
  getParams({ skillTab: CollaborationProgressSessionHeaderSkillsTabs.PutIntoPractice })
]
