import { Slot, SlotDataPoint } from '../../../pure-js/types/Slot.js'
import { getUserDataPointMapsForSlots } from '../libs/DBApiHandler.js'
import { _useSlots } from './QueryHooks.js'
import { useAsyncFunction } from './useAsyncFunction.js'

export const useSlots = ({ sessionId, teamId }: { sessionId?: string; teamId?: string }): Slot[] => {
  const q = _useSlots({ sessionId, teamId })
  const slotIds = q.data?.map((slot) => slot.id) || []
  const { data: slotDataPoints = [] } = useAsyncFunction<SlotDataPoint[]>(
    () => getUserDataPointMapsForSlots({ slotIds, teamId, sessionId }),
    {
      enabled: slotIds.length > 0 && !!teamId && !!sessionId,
      key: `getUserDataPointMapsForSlots-${slotIds.join('-')}-${teamId}-${sessionId}`
    }
  )

  if (!sessionId) return []

  return (
    q.data?.map((slot) => ({
      ...slot,
      sessionId,
      slotDataPoints: slotDataPoints?.filter((dp) => dp.slotId === slot.id) || []
    })) || []
  )
}
