import styled from '@emotion/styled'
import React from 'react'
import { minBorderRadius } from '../enums/BorderRadixesDeprecated'
import { defaultSpacing, minSpacing } from '../enums/Spacings'
import { ColorsDeprecated as Colors } from '../../../pure-js/libs/ColorsDeprecated'
import { getFigmaText } from '../libs/TextRepository'
import { TextsDeprecated } from '../../../pure-js/libs/TextsDeprecated'
import Box from './Box'
import { ButtonProps } from './ButtonPrimary'
import FigmaTextLoader from './FigmaTextLoader'
import { Black } from '../../../pure-js/libs/Colors'

const ButtonEmptyState: React.FC<ButtonProps> = (props) => {
  const { children, className, textKey, loading, spacing, onClick } = props
  const text = props.text || (textKey ? getFigmaText(textKey) : undefined)

  return (
    <StyledButton className={className} spacing={spacing} pointer alignText="left" onClick={onClick as any}>
      {children || (
        <FigmaTextLoader
          textKey={TextsDeprecated.genericDialogButtonSecondaryButtonText}
          text={text}
          loading={loading}
        />
      )}
    </StyledButton>
  )
}

const StyledButton = styled(Box)<{ spacing?: string }>`
  background: ${Colors.white};
  padding-bottom: ${minSpacing};
  padding-top: ${minSpacing};
  padding-left: ${({ spacing }) => spacing || defaultSpacing};
  padding-right: ${({ spacing }) => spacing || defaultSpacing};

  border: 1px dashed ${Black};
  border-radius: ${minBorderRadius};
  :hover {
    background-color: ${Colors.neutral10};
  }
`

export default ButtonEmptyState
