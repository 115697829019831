import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { ProgressLevel } from '../../../pure-js/enums/ProgressLevel'
import { USER_WIDGET_SIZE } from '../libs/HardCodedSizes'
import { ProgressLevelsInfo } from '../libs/ProgressLevelsInfo'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'

export const ProgressLevelIcon = ({
  size = USER_WIDGET_SIZE,
  progressLevel
}: {
  progressLevel?: ProgressLevel
  size?: number
}) => {
  if (!progressLevel) return null
  const { arrowImageKey, arrowWidthRatio } = ProgressLevelsInfo[progressLevel]
  return (
    <Container align="center" justify="center" size={size}>
      <FigmaImageContainer imageKey={arrowImageKey} width={size * arrowWidthRatio} />
    </Container>
  )
}

const Container = styled(Box)<{
  size: number
  $backgroundColor?: string
  disabled?: boolean
}>`
  width: ${({ size }) => size}px;
  height: ${({ size }) => size}px;
  border-radius: 50%;
  background-color: ${MainColor};
`
