import { onAuthStateChanged, User } from 'firebase/auth'
import { useEffect, useState } from 'react'
import { auth } from '../libs/Firebase'

export default function useFirebaseUser() {
  const [isLoading, setIsLoading] = useState(true)
  const [user, setUser] = useState<User>()

  useEffect(() => {
    const listener = onAuthStateChanged(auth, (user) => {
      setUser(user || undefined)
      setIsLoading(false)
    })
    return () => {
      listener()
    }
  }, [auth])

  return { isLoading, data: user }
}
