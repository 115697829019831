import invariant from 'invariant'
import { OnboardingChapter } from '../../../pure-js/enums/OnboardingChapter'
import { getRandomCode } from '../../../pure-js/libs/Common'
import { toBaseObject } from '../../../pure-js/libs/FirebaseStorageClientBaseHelper'
import { SignedInQueryParams } from '../../../pure-js/types/QueryParamTypes'
import { DEFAULT_SIGNED_IN_STATE, SignedInState, SignedInSteps } from '../../../pure-js/types/SignedInTypes'
import { User } from '../../../pure-js/types/User'
import { AppContext, State } from '../hooks/useAppState'
import { inviteUsers, joinTeam, setCustomUserClaimsForLoggedInUser } from './CloudFunctionsApiHandler'
import { getTeam, getUser, updateTeam, updateUser } from './DBApiHandler'
import { auth } from './Firebase'

export const onPressContinue = (
  {
    signedInState,
    appContext
  }: {
    signedInState: SignedInState
    appContext: AppContext
  },
  f = { getTeam, updateTeam, updateUser, joinTeam, inviteUsers, setCustomUserClaimsForLoggedInUser, auth }
): Promise<SignedInState> =>
  Promise.resolve().then(async () => {
    const { state, setState } = appContext
    switch (signedInState.step) {
      case SignedInSteps.TERMS_AND_CONDITIONS: {
        await f.updateUser({ id: state.user.id, privacyPolicyAgree: true })

        if (state.user.team && signedInState.data.redirectToPlayWithOnboarding)
          return { ...signedInState, step: SignedInSteps.ONBOARDING }

        if (!state.user.team) return { ...signedInState, step: SignedInSteps.JOIN_TEAM }

        if (!hasCompletedIntroduction(state.user)) return { ...signedInState, step: SignedInSteps.ONBOARDING }

        return { ...signedInState, step: SignedInSteps.GO_TO_DASHBOARD }
      }

      case SignedInSteps.SIGN_UP_FORM: {
        const { displayName, phone } = signedInState.data
        await f.updateUser({ id: state.user.id, displayName, phone })

        if (!state.user.privacyPolicyAgree) return { ...signedInState, step: SignedInSteps.TERMS_AND_CONDITIONS }

        return { ...signedInState, step: SignedInSteps.JOIN_TEAM }
      }

      case SignedInSteps.JOIN_TEAM: {
        const { teamCode } = signedInState.data
        invariant(teamCode, '!teamCode')
        const joinTeamResponse = await f.joinTeam({ teamCode }, state)
        const { result } = joinTeamResponse

        if (result !== 'success')
          return { ...signedInState, data: { ...signedInState.data, step: SignedInSteps.JOIN_TEAM, joinTeamResponse } }

        await f.setCustomUserClaimsForLoggedInUser(state)
        await f.auth.currentUser?.getIdToken(true)

        if (!hasCompletedIntroduction(state.user)) return { ...signedInState, step: SignedInSteps.ONBOARDING }

        return { ...signedInState, step: SignedInSteps.GO_TO_DASHBOARD }
      }

      case SignedInSteps.CREATE_NEW_TEAM: {
        invariant(signedInState.data.teamName, '!teamName')
        const teamName = signedInState.data.teamName
        const team = await f.updateTeam({
          name: teamName,
          ...toBaseObject(),
          code: getRandomCode()
        })

        const user = { id: state.user.id, team: team.id }
        await f.updateUser(user)
        await f.setCustomUserClaimsForLoggedInUser(state)
        await f.auth.currentUser?.getIdToken(true)

        const existingTeamMembers = [user as User]

        setState({ ...state, user: { ...state.user, ...user } })

        return {
          ...signedInState,
          data: { ...signedInState.data, team, existingTeamMembers },
          step: SignedInSteps.INVITE_TO_TEAM
        }
      }

      case SignedInSteps.INVITE_TO_TEAM: {
        const { emailsToInvite: emails, team } = signedInState.data
        invariant(team, '!team')
        await f.inviteUsers({ emails, team: team?.id }, state)
        return { ...signedInState, step: SignedInSteps.INVITE_TO_TEAM_SUCCESS }
      }

      case SignedInSteps.INVITE_TO_TEAM_SUCCESS: {
        // TODO WRITE TEST: should change url to /onboarding ofter inviting users
        return { ...signedInState, step: SignedInSteps.ONBOARDING }
      }

      case SignedInSteps.DASHBOARD: {
        return DEFAULT_SIGNED_IN_STATE
      }

      default:
        throw new Error(`Unknown step: ${signedInState.step}`)
    }
  })

export async function getFirstStep(
  {
    state,
    queryParams
  }: {
    state: State
    queryParams: SignedInQueryParams
  },
  f = { getTeam, getUser }
): Promise<SignedInSteps | undefined> {
  const user = await f.getUser(state.user.id)

  if (!hasCompleteUserInfo(user)) return SignedInSteps.SIGN_UP_FORM

  if (!user?.privacyPolicyAgree) return SignedInSteps.TERMS_AND_CONDITIONS

  const teamId = user.team
  if (!teamId) return SignedInSteps.JOIN_TEAM

  const team = await f.getTeam(teamId)

  if (!team) return SignedInSteps.JOIN_TEAM

  if (!!queryParams.teamCode && queryParams.teamCode !== team?.code) return SignedInSteps.JOIN_TEAM

  if (queryParams.redirectToPlayWithOnboarding) return SignedInSteps.ONBOARDING

  if (!hasCompletedIntroduction(user)) return SignedInSteps.ONBOARDING

  return
}

export const hasCompletedIntroduction = (u?: User): boolean => {
  if (!u?.team) return false
  return u?.completedOnboardingSteps?.includes(OnboardingChapter.Introduction) || false
}

export const hasCompleteUserInfo = (u?: User): boolean => {
  return !!u?.displayName && !!u?.email
}
