import styled from '@emotion/styled'
import dayjs from 'dayjs'
import toast from 'react-hot-toast'
import { MainColor, MainFrameBG } from '../../../pure-js/libs/Colors'
import { toPx } from '../../../pure-js/libs/Common'
import { Texts } from '../../../pure-js/libs/Texts'
import { CollaborationSessionDetailsQueryParams } from '../../../pure-js/types/QueryParamTypes'
import { Session, SessionInfo } from '../../../pure-js/types/Session'
import { BorderRadixes } from '../enums/BorderRadixes'
import { minSpacing, smallSpacing } from '../enums/Spacings'
import { useSessionInfo } from '../hooks/useSessionInfo'
import { useSessions } from '../hooks/useSessions'
import { CollaborationProgressAreaInfo, CollaborationProgressAreas } from '../libs/CollaborationProgressHelper'
import { MenuItem } from '../libs/MenuHelper'
import useQueryParams from '../libs/useQueryParams'
import { AppBar } from './AppBar'
import Box from './Box'
import { CollaborationProgressItem } from './CollaborationProgressItem'
import { CollaborationProgressSessionSkills } from './CollaborationProgressItemSkills'
import { CollaborationProgressSession } from './CollaborationProgressSession'
import { Dashboard } from './Dashboard'
import FigmaText from './FigmaText'
import FigmaTextArray from './FigmaTextArray'
import { LoadingPageLoggedIn } from './LoadingPage'
import { PageContainer } from './PageContainer'
import { ProgressLevelIcon } from './ProgressLevelIcon'

export const CollaborationProgress = () => {
  const qp = useQueryParams<CollaborationSessionDetailsQueryParams>()
  const { data: sessions = [], isLoading } = useSessions()

  if (qp.sessionId) return <CollaborationProgressSession sessionId={qp.sessionId} skillTab={qp.skillTab} />

  if (isLoading) return <LoadingPageLoggedIn />

  if (sessions.length === 0 && !isLoading) return <Dashboard />

  return <CollaborationProgressView sessions={sessions} />
}

const CollaborationProgressView = ({ sessions }: { sessions: Session[] }) => {
  sessions = sessions.sort((a, b) => dayjs(b.createdAt).diff(dayjs(a.createdAt)))
  const session = sessions[0]
  const lastSession = sessions[1]

  const sessionInfo = useSessionInfo(session.id) as SessionInfo
  const prevSessionInfo = useSessionInfo(lastSession?.id) as SessionInfo

  if (!sessionInfo) return <LoadingPageLoggedIn />

  const descriptionTextKeys = Object.values(CollaborationProgressAreas).map((area) =>
    CollaborationProgressAreaInfo[area].getTextKey({ sessionInfo, prevSessionInfo })
  )

  return (
    <PageContainer fullWidth fullPadding>
      <AppBar menuItem={MenuItem.collaboration} />
      <Box fullWidth top>
        <Container fullWidth fullPadding>
          <InnerContainer fullWidth direction="row" fullPadding>
            <Box>
              <ProgressLevelIcon progressLevel={sessionInfo.userProgressLevel} />
            </Box>
            <Box left>
              <FigmaText textKey={Texts.myCollaborationProgressOverallProgressHeader} />
              <Box top spacing={smallSpacing}>
                <FigmaTextArray
                  figmaTextPropSets={descriptionTextKeys.map((textKey) => ({
                    styleTextKey: Texts.cssCollaborationProgressOverallDescription,
                    textKey
                  }))}
                />
              </Box>
            </Box>
          </InnerContainer>
          <Box fullWidth>
            {sessions.map((s, i) => (
              <CollaborationProgressRow session={s} key={i} />
            ))}
          </Box>
        </Container>
      </Box>
    </PageContainer>
  )
}

const CollaborationProgressRow = ({ session }: { session: Session }) => {
  const sessionInfo = useSessionInfo(session.id) as SessionInfo
  if (!sessionInfo) return null

  return (
    <>
      <Box fullWidth top direction="row">
        <Box width="50%" fullHeight>
          <CollaborationProgressItem sessionInfo={sessionInfo} />
        </Box>
        <Box width="50%" left spacing={`${toPx(minSpacing) * 2}px`}>
          <CollaborationProgressSessionSkills sessionInfo={sessionInfo} />
        </Box>
      </Box>
    </>
  )
}

export const copySessionInfoToClipBoard = (_sessioninfo: SessionInfo) => {
  if (!navigator.clipboard) return
  const { session } = _sessioninfo

  const sessionInfo = { ..._sessioninfo, id: session.id, session: undefined }

  toast(`Coppied sessionInfo for session: ${sessionInfo.id} to clipboard`, {
    icon: '✂️'
  })

  navigator.clipboard.writeText(JSON.stringify(sessionInfo, undefined, 2))
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.big};
  background-color: ${MainColor};
`

const InnerContainer = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background-color: ${MainFrameBG};
`
