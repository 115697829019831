import styled from '@emotion/styled'
import React from 'react'
import { Black } from '../../../pure-js/libs/Colors'
import { ColorsDeprecated as Colors } from '../../../pure-js/libs/ColorsDeprecated'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { bigSpacing, tinySpacing } from '../enums/Spacings'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import { ButtonProps } from './ButtonPrimary'
import FigmaTextLoader from './FigmaTextLoader'

const ButtonWhite: React.FC<ButtonProps> = (props) => {
  const { children, className, loading, verticalSpacing, onClick } = props
  return (
    <StyledButton className={className} spacing={verticalSpacing} pointer onClick={onClick as any}>
      <Box align="center" fullWidth>
        {children || (
          <FigmaTextLoader
            textKey={Texts.onboardingAsessmentQuestionsCtaNextQuestion}
            text={props.text || getFigmaText(props.textKey as TextKey)}
            loading={loading}
          />
        )}
      </Box>
    </StyledButton>
  )
}

const StyledButton = styled(Box)<{ spacing?: string }>`
  padding-left: ${({ spacing }) => spacing || bigSpacing};
  padding-right: ${({ spacing }) => spacing || bigSpacing};
  padding-top: ${tinySpacing};
  padding-bottom: ${tinySpacing};
  width: ${({ fullWidth }) => (fullWidth ? '100%' : 'fit-content')};
  background-color: ${Colors.white};
  border: 1px solid ${Black};
  border-radius: 100px;
`

export default ButtonWhite
