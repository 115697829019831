import styled from '@emotion/styled'
import { MainColor } from '../../../pure-js/libs/Colors'
import { SignInViewProps } from '../../../pure-js/types/SignInTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing } from '../enums/Spacings'
import { LoginConatinerWidth } from '../libs/HardCodedSizes'
import { Images } from '../../../pure-js/libs/Images'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import { SignInLandingIntractionLogo } from './SignInLandingIntractionLogo'
import SignInSignUpForm from './SignInSignUpForm'

export default function SignInSignUp(props: SignInViewProps) {
  return (
    <Layout>
      <Box fullWidth align="center">
        <Box top spacing={reallyBigSpacing}>
          <SignInLandingIntractionLogo />
        </Box>
        <Box top spacing={reallyBigSpacing}>
          <Container fullWidth>
            <Box top right left fullWidth direction="row" justify="space-between" align="center">
              <Box pointer onClick={props.onClickBack}>
                <FigmaImageContainer imageKey={Images.backIcon} />
              </Box>
              <Box></Box>
              <Box></Box>
            </Box>
            <Box fullWidth alignText="center">
              <FigmaText textKey={Texts.onboardingCreateAccountCreateAccountHeader} />
            </Box>
            <Box align="center" right left spacing={reallyBigSpacing} fullWidth>
              <Box top fullWidth>
                <SignInSignUpForm {...props} />
              </Box>
            </Box>
            <Box top />
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${MainColor};
  border-radius: ${BorderRadixes.big};
  width: ${LoginConatinerWidth}px;
`
