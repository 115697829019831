import styled from '@emotion/styled'
import { MainFrameBG } from '../../../pure-js/libs/Colors'
import { BorderRadixes } from '../enums/BorderRadixes'
import { SMALL_SPACING_PX, smallSpacing, tinySpacing } from '../enums/Spacings'
import { ProgressLevelsInfo } from '../libs/ProgressLevelsInfo'
import Box from './Box'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { SkillsInfo } from '../../../pure-js/libs/SkillsInfo'
import { BaseSessionInfoSkillItem, SessionInfo } from '../../../pure-js/types/Session'
import { ProgressLevelIcon } from './ProgressLevelIcon'
import { Skill } from '../../../pure-js/enums/Skill'

export const CollaborationProgressSessionSkillsItem = ({
  skill,
  sessionInfo,
  right
}: {
  skill: Skill
  sessionInfo: SessionInfo
  right?: boolean
}) => {
  const sessionInfoSkilleItem = sessionInfo.skills[skill] as BaseSessionInfoSkillItem
  const { userProgressLevel } = sessionInfoSkilleItem
  const { textKey: skillsInfoTextKey, imageKey } = SkillsInfo[skill]
  const progressLevel = ProgressLevelsInfo[userProgressLevel]
  const textKey = sessionInfo.previousSessions.length > 0 ? progressLevel.textKey : progressLevel.textKeySingleSession

  const ContainerComponent = right ? RightContainer : Container

  return (
    <ContainerComponent fullWidth fullPadding fullHeight>
      <Box fullWidth direction="row" align="center">
        <FigmaImageContainer imageKey={imageKey} />
        <Box left spacing={tinySpacing}>
          <FigmaText textKey={skillsInfoTextKey} />
        </Box>
      </Box>
      <Box top spacing={smallSpacing}>
        <Box fullWidth direction="row" align="center">
          <ProgressLevelIcon progressLevel={userProgressLevel} size={SMALL_SPACING_PX * 1.7} />
          <Box left spacing={tinySpacing}>
            <FigmaText textKey={textKey} />
          </Box>
        </Box>
      </Box>
    </ContainerComponent>
  )
}

const Container = styled(Box)`
  background-color: ${MainFrameBG};
`

const RightContainer = styled(Container)`
  border-top-right-radius: ${BorderRadixes.moderate};
  border-bottom-right-radius: ${BorderRadixes.moderate};
`
