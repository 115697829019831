enum RoutePath {
  ROOT = '/',
  SANDBOX = '/sandbox',

  // MenuItems
  DASHBOARD = '/dashboard',
  COLLABORATION = '/collaboration',
  PROFILE = '/profile',
  THEORY_BEHIND = '/theory-behind',
  ONBOARDING = '/onboarding',
  INVITE = '/invite',
  GAME_CENTER = '/game-center',
  PLAY_GAME_WITHOUT_INTRODUCTION = '/playgame-without-introduction',
  PLAY_GAME_WITH_INTRODUCTION = '/playgame-with-introduction',
  JOIN_TEAM = '/join-team'
}

export default RoutePath
