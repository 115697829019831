export enum CloudFunction {
  createAuthenticationTokenForLoginToken = 'createAuthenticationTokenForLoginToken',
  createLoginWithCodeToken = 'createLoginWithCodeToken',
  inviteUsers = 'inviteUsers',
  signUpUser = 'signUpUser',
  joinTeam = 'joinTeam',
  deleteUser = 'deleteUser',
  triggerReflectionEmail = 'triggerReflectionEmail',
  setCustomUserClaimsForLoggedInUser = 'setCustomUserClaimsForLoggedInUser'
}
