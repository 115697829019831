import { CollaborationProgressSessionHeaderSkillsTabs } from '../../../pure-js/enums/CollaborationProgressSessionHeaderSkillsTabs'
import { SessionInfo } from '../../../pure-js/types/Session'
import { useSessionInfo } from '../hooks/useSessionInfo'
import Box from './Box'
import { CollaborationProgressSessionHeader } from './CollaborationProgressSessionHeader'
import { CollaborationProgressSessionHeaderSkills } from './CollaborationProgressSessionHeaderSkills'
import { CollaborationProgressSessionPutIntoPractise } from './CollaborationProgressSessionPutIntoPractise'
import { CollaborationProgressSessionSkillDevelopment } from './CollaborationProgressSessionSkillDevelopment'
import { CollaborationProgressSessionTaskPerformance } from './CollaborationProgressSessionTaskPerformance'
import { CollaborationProgressSessionWhatToImprove } from './CollaborationProgressSessionWhatToImprove'
import { LoadingPageLoggedIn } from './LoadingPage'
import { PageContainer } from './PageContainer'

export function CollaborationProgressSession({
  sessionId,
  skillTab
}: {
  sessionId: string
  skillTab?: CollaborationProgressSessionHeaderSkillsTabs
}) {
  const sessionInfo = useSessionInfo(sessionId)
  const Component = useComponent({ skillTab: skillTab })

  if (!sessionInfo) return <LoadingPageLoggedIn />

  return (
    <PageContainer fullWidth fullPadding>
      <CollaborationProgressSessionHeader sessionInfo={sessionInfo} />
      <Box fullWidth top>
        <CollaborationProgressSessionHeaderSkills />
      </Box>
      <Box fullWidth top>
        <Component sessionInfo={sessionInfo} />
      </Box>
    </PageContainer>
  )
}

const useComponent = ({
  skillTab
}: {
  skillTab?: CollaborationProgressSessionHeaderSkillsTabs
}): React.FC<{ sessionInfo: SessionInfo }> => {
  if (skillTab === CollaborationProgressSessionHeaderSkillsTabs.TaskPerformance)
    return CollaborationProgressSessionTaskPerformance
  if (skillTab === CollaborationProgressSessionHeaderSkillsTabs.SkillsDevelopment)
    return CollaborationProgressSessionSkillDevelopment
  if (skillTab === CollaborationProgressSessionHeaderSkillsTabs.WhatToImprove)
    return CollaborationProgressSessionWhatToImprove
  if (skillTab === CollaborationProgressSessionHeaderSkillsTabs.PutIntoPractice)
    return CollaborationProgressSessionPutIntoPractise

  return CollaborationProgressSessionTaskPerformance
}
