import { JoinTeamResponse } from './JoinTeamResponse'
import { SignedInQueryParams } from './QueryParamTypes'
import { Team } from './Team'
import { User } from './User'

export type SignedInState = {
  data: {
    teamName?: string
    team?: Team
    existingTeamMembers: User[]
    emailsToInvite: string[]
    joinTeamResponse?: JoinTeamResponse
    displayName?: string
    phone?: string
  } & SignedInQueryParams
  step: SignedInSteps
}

export type SignedInViewProps = {
  isLoading: boolean
  signedInState: SignedInState
  enableCreateNewTeam?: boolean
  onPressContinue: (state: SignedInState) => Promise<unknown>
  onClickBack: () => unknown
  onClickLater: () => unknown
  onClickCrateTeam: () => unknown
}

export enum SignedInSteps {
  DASHBOARD = 'DASHBOARD',
  TERMS_AND_CONDITIONS = 'TERMS_AND_CONDITIONS',
  CREATE_NEW_TEAM = 'CREATE_NEW_TEAM',
  INVITE_TO_TEAM = 'INVITE_TO_TEAM',
  JOIN_TEAM = 'JOIN_TEAM',
  INVITE_TO_TEAM_SUCCESS = 'INVITE_TO_TEAM_SUCCESS',
  GO_TO_DASHBOARD = 'GO_TO_DASHBOARD',
  ONBOARDING = 'ONBOARDING',
  SIGN_UP_FORM = 'SIGN_UP_FORM'
}

export const DEFAULT_SIGNED_IN_STATE: SignedInState = {
  data: {
    existingTeamMembers: [],
    emailsToInvite: []
  },
  step: SignedInSteps.DASHBOARD
}
