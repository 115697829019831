import invariant from 'invariant'
import React, { useEffect, useMemo, useState } from 'react'
import eventEmitter, { Events } from '../../../pure-js/libs/EventEmitter'
import { SignedInQueryParams } from '../../../pure-js/types/QueryParamTypes'
import {
  DEFAULT_SIGNED_IN_STATE,
  SignedInState,
  SignedInSteps,
  SignedInViewProps
} from '../../../pure-js/types/SignedInTypes'
import useAppState from '../hooks/useAppState'
import { getTeam, getUsersForTeam } from '../libs/DBApiHandler'
import { getFirstStep as getFirstSignedInStep, onPressContinue } from '../libs/SignedInMachine'
import onUnhandledPromiseRejection from '../libs/onUnhandledPromiseRejection'
import useQueryParams from '../libs/useQueryParams'
import SignedInCreateTeam from './SignedInCreateTeam'
import { SignedInDashboard } from './SignedInDashboard'
import { SignedInGoToDashboard } from './SignedInGoToDashboard'
import SignedInInviteToTeam from './SignedInInviteToTeam'
import SignedInInviteToTeamSuccess from './SignedInInviteToTeamSuccess'
import SignedInJoinTeam from './SignedInJoinTeam'
import { SignedInOnboarding } from './SignedInOnboarding'
import SignedInSignUpForm from './SignedInSignUpForm'
import SignedInTemsAndConditions from './SignedInTemsAndConditions'

const getViews = (): { [property in SignedInSteps]: React.FC<SignedInViewProps> } => ({
  [SignedInSteps.DASHBOARD]: SignedInDashboard,
  [SignedInSteps.GO_TO_DASHBOARD]: SignedInGoToDashboard,
  [SignedInSteps.TERMS_AND_CONDITIONS]: SignedInTemsAndConditions,
  [SignedInSteps.SIGN_UP_FORM]: SignedInSignUpForm,
  [SignedInSteps.JOIN_TEAM]: SignedInJoinTeam,
  [SignedInSteps.CREATE_NEW_TEAM]: SignedInCreateTeam,
  [SignedInSteps.INVITE_TO_TEAM]: SignedInInviteToTeam,
  [SignedInSteps.INVITE_TO_TEAM_SUCCESS]: SignedInInviteToTeamSuccess,
  [SignedInSteps.ONBOARDING]: SignedInOnboarding
})

type SignedInProps = {
  step?: SignedInSteps
  defaultStep?: SignedInSteps
  enableCreateNewTeam?: boolean
  onClickLater?: () => unknown
}

export default function SignedInPage(props: SignedInProps) {
  const { enableCreateNewTeam } = props
  const context = useAppState()

  const [isLoading, setIsLoading] = React.useState(false)

  const { signedInState, setSignedInState, isLoading: isLoadingSignedInState } = useSignedInState(props)

  const Views = useMemo(() => getViews(), []) // https://intraction-ab.sentry.io/issues/5753769723/?project=4507678285103104&query=is%3Aunresolved+issue.priority%3A%5Bhigh%2C+medium%5D&referrer=issue-stream&statsPeriod=24h&stream_index=0

  const onError = (err) => {
    onUnhandledPromiseRejection(err)
    setSignedInState(DEFAULT_SIGNED_IN_STATE)
    eventEmitter.emit(Events.NEW_SERVER_ERROR)
  }

  const goToDefaultRoute = () => {
    const step = getDefaultStep(props)
    setSignedInState({ ...DEFAULT_SIGNED_IN_STATE, step })
  }

  const signedInViewProps: SignedInViewProps = {
    isLoading: isLoading || isLoadingSignedInState,
    enableCreateNewTeam,
    signedInState,
    onClickLater: props.onClickLater || goToDefaultRoute,
    onClickCrateTeam: () => setSignedInState({ ...signedInState, step: SignedInSteps.CREATE_NEW_TEAM }),
    onClickBack: () => {
      if (signedInState.step === SignedInSteps.CREATE_NEW_TEAM)
        return setSignedInState({ ...signedInState, step: SignedInSteps.JOIN_TEAM })
      goToDefaultRoute()
    },
    onPressContinue: (signedInState: SignedInState) =>
      Promise.resolve(setIsLoading(true))
        .then(() => onPressContinue({ signedInState, appContext: context }))
        .then((signInState: SignedInState) => setSignedInState(signInState))
        .catch(onError)
        .finally(() => setIsLoading(false))
  }

  const component: React.FC<SignedInViewProps> = Views[signedInViewProps.signedInState.step]
  invariant(component, `Cant find SignedIn Step for %s`, signedInViewProps.signedInState.step)

  return React.createElement(component, signedInViewProps)
}

const useSignedInState = (props: SignedInProps) => {
  const queryParams = useQueryParams<SignedInQueryParams>()
  const [isLoading, setIsLoading] = React.useState(true)

  const { state } = useAppState()

  const [signedInState, setSignedInState] = useState<SignedInState>({
    ...DEFAULT_SIGNED_IN_STATE,
    data: { ...DEFAULT_SIGNED_IN_STATE.data, ...queryParams },
    step: getDefaultStep(props)
  })

  useEffect(() => {
    Promise.resolve(setIsLoading(true))
      .then(async () => {
        const teamId = state.user?.team

        const team = teamId ? await getTeam(teamId) : undefined
        const existingTeamMembers = teamId ? await getUsersForTeam(teamId) : []

        const firstStep = await getFirstSignedInStep({ state, queryParams })
        if (!props.step && firstStep) signedInState.step = firstStep

        setSignedInState({
          ...signedInState,
          data: { ...signedInState.data, team, existingTeamMembers }
        })
      })
      .catch(onUnhandledPromiseRejection)
      .finally(() => setIsLoading(false))
  }, [])

  return { signedInState, setSignedInState, isLoading }
}

function getDefaultStep(props: SignedInProps): SignedInSteps {
  return props.step || props.defaultStep || DEFAULT_SIGNED_IN_STATE.step
}
