import { CollaborationProgress } from '../components/CollaborationProgress'
import { Layout } from '../components/Layout'

export const CollaborationProgressPage = () => {
  return (
    <Layout enableMenu>
      <CollaborationProgress />
    </Layout>
  )
}
