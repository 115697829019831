import { RecommendationLevels } from '../enums/RecommendationLevels'
import { SkillItemProgress } from '../types/Session'
import { ONE_SECOND } from './Consts'
import { toPercent } from './getSessionInfo'
import { Images } from './Images'
import {
  decisionMakingPrioritizedDecisionMakingNumberOfTanksFullWhenReturningWeight,
  decisionMakingPrioritizedDecisionMakingRobotFailRateAtMapWeight,
  decisionMakingPrioritizedDecisionMakingRobotFailRateWeight,
  decisionMakingPrioritizedDecisionMakingTimeParalyzedWeight,
  decisionMakingPrioritizedDecisionMakingWeight,
  decisionMakingRiskProfilePathInitiatedTogetherSummariesWeight,
  decisionMakingRiskProfilePathSelectedScoreWeight,
  decisionMakingRiskProfileWeight,
  decisionMakingTimeManagementInactiveTimeInside,
  decisionMakingTimeManagementInactiveTimeOutsideWeight,
  decisionMakingTimeManagementWeight
} from './SessionInfoConstants'
import {
  decisionMakingPrioritizedDecisionMaking,
  decisionMakingRiskProfile,
  decisionMakingTimeManagement,
  inactiveTimeInside,
  inactiveTimeOutside,
  numberOfTanksFullWhenReturning,
  pathInitiatedTogetherSummaries,
  pathSelectedScore,
  timeParalyzed,
  unlockRobotFailRate,
  unlockRobotFailRateAtMap
} from './SessionInfoHelper'
import { SkillDevelopmentItemInfo } from './SkillDevelopmentItemInfo'
import {
  getNumberOfUserIds,
  getTeamInactiveTimeRatio,
  getUserInactiveTimeRatio
} from './SkillDevelopmentItemInfoDecisionMakingHelper'
import { Texts } from './Texts'

export enum SkillDevelopmentItemDecisionMaking {
  RiskProfile = 'RiskProfile',
  PrioritizedDecisionMaking = 'PrioritizedDecisionMaking',
  TimeManagement = 'TimeManagement'
}

export enum SkillDevelopmentItemDecisionMakingRiskProfileFormattedValues {
  Low = 'Low',
  Medium = 'Medium',
  High = 'High'
}

export const SkillDevelopmentItemInfoDecisionMaking: {
  [property in SkillDevelopmentItemDecisionMaking]: SkillDevelopmentItemInfo
} = {
  [SkillDevelopmentItemDecisionMaking.RiskProfile]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleRiskProfile,
    imageKey: Images.imgRiskProfileWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoRiskProfile,
    getValueForSlotDataPoint: ({ dataPoint }) => decisionMakingRiskProfile(dataPoint),
    getDisplayValue: ({ dataPoint: p, slots }) => p.pathSelectedScore / slots.length,
    formatDisplayValue: (pathSelectedScore) => {
      switch (true) {
        case pathSelectedScore < 1.66:
          return SkillDevelopmentItemDecisionMakingRiskProfileFormattedValues.Low
        case pathSelectedScore < 2.33:
          return SkillDevelopmentItemDecisionMakingRiskProfileFormattedValues.Medium
        default:
          return SkillDevelopmentItemDecisionMakingRiskProfileFormattedValues.High
      }
    },
    weight: decisionMakingRiskProfileWeight,
    calculatedBy: [
      {
        key: 'pathSelectedScore',
        weight: decisionMakingRiskProfilePathSelectedScoreWeight,
        getValueForSlotDataPoint: pathSelectedScore
      },
      {
        key: 'pathInitiatedTogetherSummariesCount',
        weight: decisionMakingRiskProfilePathInitiatedTogetherSummariesWeight,
        getValueForSlotDataPoint: pathInitiatedTogetherSummaries
      }
    ]
  },
  [SkillDevelopmentItemDecisionMaking.PrioritizedDecisionMaking]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitlePrioritizedDecision,
    imageKey: Images.imgPrioritizedDecisionMakingWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoPrioritizedDecision,
    getValueForSlotDataPoint: ({ dataPoint }) => decisionMakingPrioritizedDecisionMaking(dataPoint),
    getDisplayValue: ({ dataPoint: p, slots }) => decisionMakingPrioritizedDecisionMaking(p) / slots.length,
    formatDisplayValue: (n) => n.toFixed(3),
    weight: decisionMakingPrioritizedDecisionMakingWeight,
    calculatedBy: [
      {
        key: 'timeParalyzed',
        weight: decisionMakingPrioritizedDecisionMakingTimeParalyzedWeight,
        getValueForSlotDataPoint: timeParalyzed
      },
      {
        key: 'totalNumberOfTanksFilledWhenReturning',
        weight: decisionMakingPrioritizedDecisionMakingNumberOfTanksFullWhenReturningWeight,
        getValueForSlotDataPoint: numberOfTanksFullWhenReturning
      },
      {
        key: 'unlockRobotFailRate',
        weight: decisionMakingPrioritizedDecisionMakingRobotFailRateWeight,
        getValueForSlotDataPoint: unlockRobotFailRate
      },
      {
        key: 'unlockRobotFailRateAtMap',
        weight: decisionMakingPrioritizedDecisionMakingRobotFailRateAtMapWeight,
        getValueForSlotDataPoint: unlockRobotFailRateAtMap
      }
    ],
    recommendationTextKeys: {
      [RecommendationLevels.tooHigh]: undefined,
      [RecommendationLevels.slightlyHigh]: undefined,
      [RecommendationLevels.slightlyLow]: Texts.dynamicTxtRecDmRecDmPdmSlightlyLow,
      [RecommendationLevels.tooLow]: Texts.dynamicTxtRecDmRecDmPdmTooLow
    },
    getRecommendationLevel: (p: SkillItemProgress) => {
      // const score = p.userDisplayValue
      const score = p.yourScore

      switch (true) {
        case score < 0.5:
          return RecommendationLevels.tooLow
        case score < 1:
          return RecommendationLevels.slightlyLow
        default:
          return undefined
      }
    }
  },
  [SkillDevelopmentItemDecisionMaking.TimeManagement]: {
    textKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityTitleTimeManagement,
    imageKey: Images.imgCommunicationRecieptWhite,
    descriptionTextKey: Texts.myCollaborationProgress2SkillsDevelopmentCapabilityInfoTimeManagement,
    getValueForSlotDataPoint: ({ dataPoint }) => decisionMakingTimeManagement(dataPoint),
    getDisplayValue: ({ slots, uid, dataPoint, session }, f) => {
      const userIds = getNumberOfUserIds(slots)

      // TODO REMOVE
      // const log = (...args) => session?.id === 'udx5bqn62' && console.log(...args)

      // TODO FIX TECH DEBT, the devision here with userids is a proof that we dont have the right abstraction, we need to refactor this
      if (!uid) return getTeamInactiveTimeRatio({ slots }) / userIds
      return getUserInactiveTimeRatio({ slots, uid })
    },
    formatDisplayValue: (n, f) => `${toPercent(n)} %`,
    weight: decisionMakingTimeManagementWeight,
    enableYourScoreRatio: false,
    enableRelativeProgress: false,
    calculatedBy: [
      {
        key: 'inactiveTimeInside',
        weight: decisionMakingTimeManagementInactiveTimeInside,
        getValueForSlotDataPoint: inactiveTimeInside
      },
      {
        key: 'inactiveTimeOutside',
        weight: decisionMakingTimeManagementInactiveTimeOutsideWeight,
        getValueForSlotDataPoint: inactiveTimeOutside
      }
    ],
    recommendationTextKeys: {
      [RecommendationLevels.tooHigh]: undefined,
      [RecommendationLevels.slightlyHigh]: undefined,
      [RecommendationLevels.slightlyLow]: Texts.dynamicTxtRecDmRecDmTmSlightlyLow,
      [RecommendationLevels.tooLow]: Texts.dynamicTxtRecDmRecDmTmTooLow
    },
    getRecommendationLevel: ({ yourScoreNormalized }) => {
      switch (true) {
        case yourScoreNormalized < 0.66:
          return RecommendationLevels.tooLow
        case yourScoreNormalized < 1:
          return RecommendationLevels.slightlyLow
        default:
          return undefined
      }
    }
  }
}
