import styled from '@emotion/styled'
import invariant from 'invariant'
import { useState } from 'react'
import { MainColor, MainFrameBG, SecondaryFrameBG } from '../../../pure-js/libs/Colors'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { BorderRadixes } from '../enums/BorderRadixes'
import { smallSpacing } from '../enums/Spacings'
import { MenuItem } from '../libs/MenuHelper'
import { Texts } from '../../../pure-js/libs/Texts'
import { AppBar } from './AppBar'
import Box from './Box'
import FigmaText from './FigmaText'
import FigmaTextWithLinebreaks from './FigmaTextWithLinebreaks'
import { PageContainer } from './PageContainer'
import { getFigmaText } from '../libs/TextRepository'
import { capitalize } from '../../../pure-js/libs/Common'

export const TheoryBehind = () => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  return (
    <PageContainer fullWidth fullPadding>
      <AppBar menuItem={MenuItem.theory} />
      <Box fullWidth top>
        <Container fullWidth fullPadding>
          {TheoryBehindItems.map((item, index) => (
            <Box fullWidth key={index} pointer onClick={() => setCurrentIndex(index)}>
              <TheoryBehindChapterComponent {...item} index={index} currentIndex={currentIndex} />
            </Box>
          ))}
        </Container>
      </Box>
    </PageContainer>
  )
}

type TheoryBehindItemProps = TheoryBehindItem & {
  index: number
  currentIndex: number
}

const TheoryBehindChapterComponent = ({
  headerTextKey,
  descriptionTextKey,
  index,
  currentIndex
}: TheoryBehindItemProps) => {
  invariant(index !== undefined, 'Index is undefined')
  const isCurrent = index === currentIndex

  return (
    <Box fullWidth top spacing={index > 0 ? smallSpacing : '0px'} key={index || ''}>
      <ChapterHeaderContainer fullWidth fullPadding pointer>
        <FigmaText textKey={headerTextKey} text={capitalize(getFigmaText(headerTextKey).toLocaleLowerCase())} />
      </ChapterHeaderContainer>
      <Divider />
      {(isCurrent || true) && (
        <ChapterBodyContainer fullWidth fullPadding>
          <FigmaTextWithLinebreaks textKey={descriptionTextKey} />
        </ChapterBodyContainer>
      )}
    </Box>
  )
}

const Container = styled(Box)`
  border-radius: ${BorderRadixes.moderate};
  background-color: ${MainColor};
`

const ChapterHeaderContainer = styled(Box)`
  background-color: ${MainFrameBG};
  border-radius: ${BorderRadixes.moderate} ${BorderRadixes.moderate} 0 0;
`

const ChapterBodyContainer = styled(Box)`
  background-color: ${SecondaryFrameBG};
  border-radius: 0 0 ${BorderRadixes.moderate} ${BorderRadixes.moderate};
`

const Divider = styled(Box)`
  height: 2px;
  width: 100%;
  background-color: ${MainColor};
`

type TheoryBehindItem = {
  headerTextKey: TextKey
  descriptionTextKey: TextKey
}

const TheoryBehindItems: TheoryBehindItem[] = [
  // 2 comes before 1 because of IN-258
  { headerTextKey: Texts.txtTheoryBehindHeader2, descriptionTextKey: Texts.txtTheoryBehindText2 },
  { headerTextKey: Texts.txtTheoryBehindHeader1, descriptionTextKey: Texts.txtTheoryBehindText1 },
  { headerTextKey: Texts.txtTheoryBehindHeader3, descriptionTextKey: Texts.txtTheoryBehindText3 }
]
