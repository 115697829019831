import ReactGaugeComponent from 'react-gauge-component'

export const GaugeComponent = ({ value }: { value: number }) => (
  <ReactGaugeComponent
    arc={{
      subArcs: [
        {
          limit: 20,
          color: '#F9D423', // Ljusgul
          showTick: true
        },
        {
          limit: 40,
          color: '#F4C242', // Guldgul
          showTick: true
        },
        {
          limit: 60,
          color: '#A3D85B', // Limegrön
          showTick: true
        },
        {
          limit: 100,
          color: '#53B86C', // Stark grön
          showTick: true
        }
      ]
    }}
    value={value}
  />
)
