/* eslint-disable prettier/prettier */
import { CloudFunction } from '../../../pure-js/enums/CloudFunction.js'
import { SignUpUserRequest } from '../../../pure-js/types/User.js'
import { CreateLoginTokenRequest, InviteUsersRequest, LoginWithCodeToken } from '../../../pure-js/types/types.js'
import { State } from '../hooks/useAppState.js'
import { callFunction } from './CloudFunctionsApiHandlerHelper.js'
import { JoinTeamRequest } from '../../../pure-js/types/JoinTeamRequest.js'
import { JoinTeamResponse } from '../../../pure-js/types/JoinTeamResponse.js'
import { DeleteUserRequest } from '../../../pure-js/types/DeleteUserRequest.js'
import { TriggerReflectionEmailRequest } from '../../../pure-js/types/TriggerReflectionEmailRequest.js'
import { CreateAuthenticationTokenForLoginTokenResponse } from '../../../pure-js/types/CreateAuthenticationTokenForLoginTokenResponse.js'

export const createAuthenticationTokenForUid = (req: string, state: State): Promise<string> => { throw new Error('Not implemented')}

export const createAuthenticationTokenForLoginToken = (req: LoginWithCodeToken, state: State): Promise<CreateAuthenticationTokenForLoginTokenResponse> => callFunction(CloudFunction.createAuthenticationTokenForLoginToken, state, req)

export const createLoginWithCodeToken = (req: CreateLoginTokenRequest, state: State): Promise<CreateLoginTokenRequest> => callFunction(CloudFunction.createLoginWithCodeToken, state, req)

export const inviteUsers = (req: InviteUsersRequest, state: State): Promise<string> => callFunction(CloudFunction.inviteUsers, state, req)

export const signUpUser = (req: SignUpUserRequest, state: State): Promise<string> => callFunction(CloudFunction.signUpUser, state, req)

export const joinTeam = (req: JoinTeamRequest, state: State): Promise<JoinTeamResponse> => callFunction(CloudFunction.joinTeam, state, req)

export const deleteUser = (req: DeleteUserRequest, state: State): Promise<unknown> => callFunction(CloudFunction.deleteUser, state, req)

export const triggerReflectionEmail = (req: TriggerReflectionEmailRequest, state: State): Promise<unknown> => callFunction(CloudFunction.triggerReflectionEmail, state, req)

export const setCustomUserClaimsForLoggedInUser = (state: State): Promise<unknown> => callFunction(CloudFunction.setCustomUserClaimsForLoggedInUser, state)

