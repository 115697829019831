import React from 'react'
import useIsMobile from '../hooks/useIsMobile.js'
import FigmaText, { FigmaTextProps } from './FigmaText.js'
import { TextStyles } from '../../../pure-js/libs/TextStyles.js'
import { TextKey } from '../../../pure-js/types/Antiloop.js'
import { ColorsDeprecated } from '../../../pure-js/libs/ColorsDeprecated.js'
import { Variant } from '../../pure/enums/Variants.js'

type Props = FigmaTextProps & {
  type: keyof typeof TextStyles
  color?: string
  opacity?: number
  Component?: (props: React.PropsWithChildren<Props>) => JSX.Element
  fontFamily?: string
  loading?: boolean
}

export const FigmaTextWithStyle = (props: React.PropsWithChildren<Props>) => {
  const { color, type, opacity = 1, Component = FigmaText } = props
  const isMobile = useIsMobile()
  const textStyle = TextStyles[type].style[isMobile ? Variant.Mobile : Variant.Desktop]
  const styleTextKey: TextKey = {
    ...TextStyles[type],
    style: {
      ...textStyle,
      fontFamily: props.fontFamily || textStyle.fontFamily,
      color: ColorsDeprecated[color || 'primary100'],
      opacity
    }
  }

  return <Component {...props} styleTextKey={styleTextKey} />
}
