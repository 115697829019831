import { useEffect } from 'react'
import { State } from './useAppState'
import { useStorageItem } from './useStorageItem'
import useQueryParams from '../libs/useQueryParams.js'
import { StorageKey, StorageType } from './useLocalStorageItemHelper'

export const useSessionState = (state: State) => {
  const queryParams = useQueryParams()

  const sessionState = getSessionState(queryParams, state)

  const { storageItem: session, setStorageItem: setSession } = useStorageItem(
    StorageKey.SESSION,
    { ...sessionState } as Partial<State>,
    {
      storageType: StorageType.SESSION_STORAGE
    }
  )

  useEffect(() => {
    setSession({})
  }, [])

  return session
}

export const getSessionState = (queryParams: any, state: State): Partial<State> => {
  const sessionState: Partial<State> = {}
  return sessionState
}
