import styled from '@emotion/styled'
import { reallyBigSpacing, smallSpacing } from '../enums/Spacings'
import useAppState from '../hooks/useAppState'
import { useUser } from '../hooks/useUser'
import { OnboardingChapterInfoItemProps, OnboardingChapterInfoItems } from '../libs/OnboardingChapterInfoItems'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import { onClickCompleteChapter } from '../libs/onClickCompleteChapter'

export function OnboardingChapterItem({ chapter }: OnboardingChapterInfoItemProps) {
  const { data: user } = useUser()
  const appStateContext = useAppState()
  const item = OnboardingChapterInfoItems[chapter]
  const { videoUrl, descriptionTextKeys = [], buttonTextKey } = item

  const onClick = () => onClickCompleteChapter({ user, chapter, appStateContext })

  return (
    <Box fullWidth align="center" left right spacing={reallyBigSpacing}>
      <VideoContainer fullWidth align="center">
        <video width="100%" src={videoUrl} controls />
      </VideoContainer>
      {descriptionTextKeys.map((descriptionTextKey, key) => (
        <Box fullWidth top spacing={smallSpacing} key={key}>
          <FigmaText textKey={descriptionTextKey} />
        </Box>
      ))}
      <Box top spacing={smallSpacing}>
        <Button buttonType="secondary" textKey={buttonTextKey} onClick={() => onClick()} />
      </Box>
    </Box>
  )
}

const VideoContainer = styled(Box)`
  max-width: 700px;
`
