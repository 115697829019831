import { useState } from 'react'
import { User } from '../../../pure-js/types/User'
import { useTeam, useUsersForTeamId } from '../hooks/QueryHooks'
import { useUser } from '../hooks/useUser'
import { TEAM_USER_LIST_ITEM_WIDTH } from '../libs/HardCodedSizes'
import { Images } from '../../../pure-js/libs/Images'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import {
  DashboardMyTeamUserItemLoggedInUser,
  DashboardMyTeamUserItemNotLoggedInUser,
  DashboardMyTeamUserItemUser,
  DashboardMyTeamUseritemInviteUser
} from './DashboardMyTeamItemViews'
import DashboardMyTeamNameForm from './DashboardMyTeamNameForm'
import {
  DashboardMyTeamUserItemLoggedInUserWidget,
  DashboardMyTeamUserItemNotLoggedInUserWidget,
  DashboardMyTeamUserItemUserWidget,
  DashboardMyTeamsUserWidgetProps
} from './DashboardMyTeamUserWidget'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { smallSpacing } from '../enums/Spacings'
import { getNumberOfInvitesLeftForUsers } from './getNumberOfInvitesLeft'

export function DashboardMyTeam() {
  const { data: user } = useUser()

  const { data: team } = useTeam(user?.team)
  const { name = '' } = team || {}
  const { data: users = [] } = useUsersForTeamId(user?.team)

  const [isEditingTeamName, setIsEditingTeamName] = useState(false)

  const loggedInUid = user.id

  const onClickEditTeamName = () => {
    setIsEditingTeamName(true)
  }

  return (
    <Box fullWidth>
      {!isEditingTeamName && (
        <Box fullWidth direction="row" justify="space-between" align="center">
          <FigmaText
            textKey={Texts.dashboardMyTeamHeader}
            text={`${getFigmaText(Texts.dashboardMyTeamHeader)} "${name}"`}
          />
          <Box onClick={onClickEditTeamName} pointer>
            <FigmaImageContainer imageKey={Images.imgEdit} />
          </Box>
        </Box>
      )}

      {isEditingTeamName && team && (
        <DashboardMyTeamNameForm team={team} onSuccess={() => setIsEditingTeamName(false)} />
      )}

      <Box direction="row" style={{ flexWrap: 'wrap' }} align="center">
        {users
          .reduce((a, user, i) => {
            const { Component } = useComponent({ user, loggedInUid })

            if (!Component) return a

            a.push(<DashboardMyTeamUserItem key={i} user={user} Component={Component} />)

            return a
          }, [] as JSX.Element[])
          .filter((e) => !!e)}
        {getNumberOfInvitesLeftForUsers(users) > 0 && (
          <DashboardMyTeamUserItem Component={DashboardMyTeamUseritemInviteUser} user={user as any} />
        )}
      </Box>
    </Box>
  )
}

function DashboardMyTeamUserItem({
  Component,
  user
}: {
  Component: React.FC<DashboardMyTeamsUserWidgetProps>
  user: User
}) {
  const q = useUser(user?.id)

  return (
    <Box right spacing={smallSpacing}>
      <Box top width={`${TEAM_USER_LIST_ITEM_WIDTH}px`}>
        <Component user={q.data} />
      </Box>
    </Box>
  )
}

export const useComponent = ({
  loggedInUid,
  user
}: {
  loggedInUid: string
  user: User
}): {
  Component?: React.FC<DashboardMyTeamsUserWidgetProps>
  UserWidgetComponent?: React.FC<DashboardMyTeamsUserWidgetProps>
} => {
  if (loggedInUid === user.id)
    return {
      Component: DashboardMyTeamUserItemLoggedInUser,
      UserWidgetComponent: DashboardMyTeamUserItemLoggedInUserWidget
    }
  if (!user.privacyPolicyAgree)
    return {
      Component: DashboardMyTeamUserItemNotLoggedInUser,
      UserWidgetComponent: DashboardMyTeamUserItemNotLoggedInUserWidget
    }

  return { Component: DashboardMyTeamUserItemUser, UserWidgetComponent: DashboardMyTeamUserItemUserWidget }
}
