import { getElligebleSessions } from '../../../pure-js/libs/getElligebleSessions'
import { SessionWithSlots } from '../../../pure-js/types/Session'
import { getSlotsForSessions } from '../libs/DBApiHandler'
import { _useSessions } from './QueryHooks'
import { Query, useAsyncFunction } from './useAsyncFunction'
import { useTeamId } from './useTeamId'

export const useSessions = (): Query<SessionWithSlots[]> => {
  const teamId = useTeamId()
  const { data: sessions = [], isLoading: isLoadingSessions } = _useSessions(teamId)

  const sessionIds = sessions.map((session) => session.id)
  const query = useAsyncFunction(() => getSlotsForSessions({ sessionIds, teamId }), {
    enabled: !!teamId && sessionIds.length > 0,
    key: sessionIds.map((id) => id).join(',')
  })

  const isLoading = query.isLoading || isLoadingSessions

  let sessionsWithSlots = sessions.map((session) => ({
    ...session,
    slots: query.data?.filter((slot) => slot.sessionId === session.id) || []
  }))

  sessionsWithSlots = getElligebleSessions(sessionsWithSlots)

  return { ...query, isLoading, data: sessionsWithSlots }
}
