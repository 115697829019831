import { POST } from '../../pure-js/libs/Consts.js'
import { SlackChannels } from '../../pure-js/types/SlackChannels.js'
import config from './Config.js'

export const slack = (mrkDown: string, channel: SlackChannels, extra?: any): Promise<any> => {
  return fetch(config.slackWebHookUrl, {
    method: POST,
    body: JSON.stringify({
      blocks: [
        {
          type: 'section',
          text: {
            type: 'mrkdwn',
            text: mrkDown
          }
        }
      ],
      channel,
      ...extra
    })
  }).catch(
    () => ({}) // if slack borks then unhandled promise rejection will throw slack messag => infinite loop => slack turns off integration
  )
}
