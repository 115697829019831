import { useMediaQuery } from '@mui/material'
import useDeviceDetect from './useDeviceDetect'

const DESKTOP_MIN_WIDTH = '640px'

export default function useIsMobile(minWidth: string = DESKTOP_MIN_WIDTH) {
  const { isMobile } = useDeviceDetect()
  const hasMobileWidth = !useMediaQuery(`screen and (min-width: ${minWidth})`)
  if (!isMobile) return false
  return isMobile || hasMobileWidth
}

export const useIsDesktop = () => !useIsMobile()
