import { signOut } from 'firebase/auth'
import { auth } from '../libs/Firebase'
import useAppState, { DEFAULT_STATE } from './useAppState'
import onUnhandledPromiseRejection from '../libs/onUnhandledPromiseRejection'

export function useLogout(onLogout?: () => void) {
  const { setState } = useAppState()

  return () => {
    onLogout?.()
    setState(DEFAULT_STATE)

    return signOut(auth).catch(onUnhandledPromiseRejection)
  }
}
