import styled from '@emotion/styled'
import { MainColor, SecondayColor } from '../../../pure-js/libs/Colors'
import { Texts } from '../../../pure-js/libs/Texts'
import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import { BorderRadixes } from '../enums/BorderRadixes'
import { reallyBigSpacing, smallSpacing, tinySpacing } from '../enums/Spacings'
import { LoginConatinerWidth, SIGNED_IN_INVITE_USER_FORM_WIDTH } from '../libs/HardCodedSizes'
import { formatText, getFigmaText } from '../libs/TextRepository'
import Box from './Box'
import FigmaText from './FigmaText'
import { Layout } from './Layout'
import Loader from './Loader'
import { SignInLandingIntractionLogo } from './SignInLandingIntractionLogo'
import SignedInInviteToTeamForm from './SignedInInviteToTeamForm'
import { getNumberOfInvitesLeft } from './getNumberOfInvitesLeft'

export default function SignedInInviteToTeam(props: SignedInViewProps) {
  const team = props.signedInState.data.team
  const numbersOfInvitesLeft = getNumberOfInvitesLeft(props.signedInState)
  return (
    <Layout enableMenu>
      <Box fullWidth align="center">
        <Box top spacing={reallyBigSpacing}>
          <SignInLandingIntractionLogo />
        </Box>
        <Box top spacing={reallyBigSpacing}>
          <Container fullWidth>
            <Box top right left fullWidth direction="row" justify="space-between" align="center">
              <Box pointer onClick={props.onClickBack}>
                {/**  <FigmaImageContainer imageKey={Images.backIcon} /> **/}
              </Box>
              <Box></Box>
              <Box></Box>
            </Box>
            <Box fullWidth align="center">
              <Box width={`${SIGNED_IN_INVITE_USER_FORM_WIDTH}px`}>
                <Box fullWidth>
                  <FigmaText
                    textKey={Texts.onboardingInviteTeamMembersInviteSegmentHeader}
                    text={team?.name || getFigmaText(Texts.onboardingInviteTeamMembersInputInviteEmailHeader)}
                  />
                </Box>
                <Box fullWidth top spacing={smallSpacing} />
                <TeamNameContainer fullPadding spacing={tinySpacing} fullWidth>
                  <FigmaText
                    textKey={Texts.onboardingInviteTeamMembersInviteTeamInfo}
                    text={formatText(getFigmaText(Texts.onboardingInviteTeamMembersInviteTeamInfo), [team?.code])}
                  />
                </TeamNameContainer>
                <Box fullWidth top spacing={smallSpacing} />
                <Box fullWidth>
                  <FigmaText
                    textKey={Texts.onboardingInviteTeamMembersInviteSegmentInfo}
                    text={formatText(getFigmaText(Texts.onboardingInviteTeamMembersInviteSegmentInfo), [
                      numbersOfInvitesLeft
                    ])}
                  />
                </Box>
                <Box top fullWidth spacing={smallSpacing}>
                  {props.isLoading && (
                    <Box fullWidth align="center">
                      <Loader />
                    </Box>
                  )}
                  {!props.isLoading && <SignedInInviteToTeamForm {...props} />}
                </Box>
              </Box>
            </Box>
            <Box top />
          </Container>
        </Box>
      </Box>
    </Layout>
  )
}

const Container = styled(Box)`
  background-color: ${MainColor};
  border-radius: ${BorderRadixes.big};
  width: ${LoginConatinerWidth}px;
  opacity: 0.8;
`

const TeamNameContainer = styled(Box)`
  border-radius: ${BorderRadixes.small};
  border: 1px dashed ${SecondayColor};
`
