import qs from 'query-string'

export const getFeatureFlagsFromUrl = () => {
  if (typeof window === 'undefined') return {}
  if (typeof window.location === 'undefined') return {}
  let _text = qs.parse(window.location.search)?.features
  if (!_text) return {}

  if (Array.isArray(_text)) _text = _text[0]
  const text = _text as string
  return text.split(',').reduce(
    (a, flag: string) => {
      a[flag] = true
      return a
    },
    {} as { [flag: string]: boolean }
  )
}
