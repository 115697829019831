import { SessionWithSlots } from '../../../pure-js/types/Session.js'
import { _useSession } from './QueryHooks.js'
import { isElligbleSlot } from '../../../pure-js/libs/getElligebleSessions.js'
import { useSlots } from './useSlots.js'
import { useTeamId } from './useTeamId.js'

export const useSession = (sessionId?: string): SessionWithSlots | undefined => {
  const teamId = useTeamId()

  const { data: session } = _useSession(sessionId, teamId)
  const slots = useSlots({ sessionId, teamId })

  if (!session) return undefined

  const sessionWithSlots: SessionWithSlots = { ...session, slots: slots.filter(isElligbleSlot) }
  return sessionWithSlots
}
