import invariant from 'invariant'
import { useEffect, useRef, useState } from 'react'
import { UnityInstance } from 'react-unity-webgl/declarations/unity-instance'
import { toPx } from '../../../pure-js/libs/Common'
import { Images } from '../../../pure-js/libs/Images'
import RoutePath from '../../../pure-js/libs/RoutePath'
import { Texts } from '../../../pure-js/libs/Texts'
import { getDisplayNameFromEmail } from '../../../pure-js/libs/UserWidgetHelper'
import { smallSpacing } from '../enums/Spacings'
import { useNavigate } from '../hooks/useNavigate'
import { useSessionId } from '../hooks/useSessionId'
import { useUser } from '../hooks/useUser'
import { GAME_NAME, GameParams, getGameUrl } from '../libs/GameHelper'
import { MenuItem } from '../libs/MenuHelper'
import { AppBar } from './AppBar'
import Box from './Box'
import Button from './Button'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'
import { LoadingPageLoggedIn } from './LoadingPage'
import { PageContainer } from './PageContainer'
import { useTeam } from '../hooks/QueryHooks'

const SOMETHING_LOW = 25

export function PlayGame({ gameParams }: { gameParams?: Partial<GameParams> }) {
  const gameFrame = useRef<HTMLIFrameElement>(null)
  const navigate = useNavigate()
  const { data: user } = useUser()
  const { data: sessionId, isLoading } = useSessionId()
  const [showFullScreenButton, setShowFullScreenButton] = useState(false)
  const [hasAttachedLifeCycleMethods, setHasAttachedLifeCycleMethods] = useState(false)
  const { data: team, isLoading: isLoadingTeam } = useTeam(user.team)
  const teamDisplayName = team?.name

  const interval = useRef<any>(undefined)

  let { displayName, email } = user

  useEffect(() => {
    // Using an internval here is a bit hacky but its used to solve: IN-201
    interval.current = setInterval(() => {
      if (hasAttachedLifeCycleMethods) return
      const gameWindow = getGameFrameWindow(gameFrame)
      if (!gameWindow) return
      const _gameWindow = gameWindow as any

      // Below methods should comply with: transition-game/Assets/Scripts/Navigation/Navigate.jslib
      _gameWindow.onGameLoaded = () => setShowFullScreenButton(true)
      _gameWindow.onGameEnded = () => navigate(RoutePath.COLLABORATION, { sessionId })
      _gameWindow.onGameAborted = () => navigate(RoutePath.COLLABORATION, { sessionId })
      _gameWindow.onTutorialAborted = () => navigate(RoutePath.DASHBOARD)
      _gameWindow.onTutorialEnded = () => navigate(RoutePath.DASHBOARD)
      _gameWindow.onLobbyLeft = () => navigate(RoutePath.GAME_CENTER)

      setHasAttachedLifeCycleMethods(true)
    }, SOMETHING_LOW)

    return () => clearInterval(interval.current)
  }, [])

  useEffect(() => {
    if (hasAttachedLifeCycleMethods) clearInterval(interval.current)
  }, [hasAttachedLifeCycleMethods])

  if (!displayName) displayName = getDisplayNameFromEmail(email) || ''

  const goToFullScreen = () => {
    const instance = getUnityInstance(gameFrame)
    invariant(instance, 'Unity instance not found')
    instance.SetFullscreen(1)
  }

  if (isLoading || !sessionId || isLoadingTeam) return <LoadingPageLoggedIn />

  return (
    <PageContainer fullWidth fullPadding>
      <AppBar menuItem={MenuItem.playgame} />
      <Box fullWidth fullPadding spacing={smallSpacing} justify="space-between" direction="row">
        <Box onClick={() => navigate(RoutePath.GAME_CENTER)} pointer>
          <FigmaImageContainer imageKey={Images.backIcon} />
        </Box>
        <Box direction="row" visibility={showFullScreenButton ? 'visible' : 'hidden'}>
          <Box>
            <Button buttonType="secondary">
              <Box direction="row" link onClick={goToFullScreen} align="center">
                <Box>
                  <FigmaText
                    styleTextKey={Texts.onboardingOtpPageCtaPrimaryVerifyCode}
                    textKey={Texts.gameCenterPlayGameCtaMakeFullScreen}
                  />
                </Box>
                <Box left spacing={smallSpacing}>
                  <FigmaImageContainer
                    imageKey={Images.fullScreen}
                    width={toPx(Texts.gameCenterPlayGameCtaMakeFullScreen.style.fontSize)}
                  />
                </Box>
              </Box>
            </Button>
          </Box>
        </Box>
        <Box />
      </Box>
      <Box fullPadding fullWidth align="center">
        <iframe
          ref={gameFrame}
          title={GAME_NAME}
          src={getGameUrl({ displayName, ...gameParams, sessionId, teamDisplayName })}
          width="100%"
          height="650px"
          style={{ border: 'none', textAlign: 'center', alignItems: 'center', display: 'flex' }}
        />
      </Box>
    </PageContainer>
  )
}

function getUnityInstance(gameFrame?): UnityInstance | undefined {
  return gameFrame?.current?.contentWindow?.unityInstance
}

function getGameFrameWindow(gameFrame?: React.RefObject<HTMLIFrameElement>) {
  return gameFrame?.current?.contentWindow
}
