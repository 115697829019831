import invariant from 'invariant'
import { OnboardingChapter } from '../../../pure-js/enums/OnboardingChapter'
import { removeDuplicateStrings } from '../../../pure-js/libs/Common'
import { User } from '../../../pure-js/types/User'
import useAppState from '../hooks/useAppState'
import {
  getNextOnboardingChapter,
  OnboardingChapterInfoItem,
  OnboardingChapterInfoItems
} from './OnboardingChapterInfoItems'
import { slack } from '../SlackHelper'
import { SlackChannels } from '../../../pure-js/types/SlackChannels'
import { getUser, updateUser } from './DBApiHandler'

export async function onClickCompleteChapter(
  {
    user,
    chapter,
    appStateContext: { state, setState }
  }: {
    user: User
    chapter: OnboardingChapter
    appStateContext: ReturnType<typeof useAppState>
  },
  f = { OnboardingChapterInfoItems, getUser, updateUser }
) {
  const intractionUser = await f.getUser(user.id)
  invariant(intractionUser, 'IntractionUser must be defined')
  const item: OnboardingChapterInfoItem = f.OnboardingChapterInfoItems[chapter]

  user = {
    ...intractionUser,
    completedOnboardingSteps: removeDuplicateStrings([...(intractionUser.completedOnboardingSteps || []), chapter])
  }

  await f
    .updateUser(user)
    .then(() => slack(`User ${user.id} completed onboarding step ${chapter}`, SlackChannels.DEBUG))

  state = { ...state, user, currentOnboardingChapter: getNextOnboardingChapter(chapter) }
  setState(state)

  // TODO WRITE TEST, should call onClickCompleteSuccess with a user that has the updated completedOnboardingSteps
  await item.onClickCompleteSuccess?.(state)
}
