import { ClickAwayListener } from '@mui/material'
import { useState } from 'react'
import { SecondaryFrameBG } from '../../../pure-js/libs/Colors'
import { TextKey } from '../../../pure-js/types/Antiloop'
import { User } from '../../../pure-js/types/User'
import { smallSpacing, tinySpacing } from '../enums/Spacings'
import { updateTeamIdForUser } from '../libs/DBApiHandler'
import { Images } from '../../../pure-js/libs/Images'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import Button from './Button'
import { DashboardMyTeamsUserWidgetProps } from './DashboardMyTeamUserWidget'
import FigmaImageContainer from './FigmaImageContainer'
import FigmaText from './FigmaText'

export function DashboardMyTeamItemView({
  user,
  textKey,
  text,
  isPending,
  UserWidgetComponent: Component,
  onClick,
  enableDelete = true
}: {
  UserWidgetComponent: React.FC<DashboardMyTeamsUserWidgetProps>
  user: User
  textKey: TextKey
  text: string
  isPending?: boolean
  onClick?: () => unknown
  enableDelete?: boolean
}): JSX.Element {
  const [_showDelete, setShowDelete] = useState(false)
  const [showDeleteButtons, setShowDeleteButtons] = useState(false)
  const [loading, setLoading] = useState(false)
  const showDelete = enableDelete && _showDelete

  const onClickRemoveUser = () =>
    Promise.resolve(setLoading(true))
      .then(() => updateTeamIdForUser(null, user))
      .finally(() => {
        setLoading(false)
        onClickCancel()
      })

  const onClickCancel = () => {
    setShowDeleteButtons(false)
    setShowDelete(false)
  }
  if (showDeleteButtons)
    return (
      <ClickAwayListener onClickAway={onClickCancel}>
        <Box fullWidth direction="row" align="center">
          <Button
            buttonType="tertiary"
            textKey={Texts.dashboardCtaVerifyRemoveUser}
            onClick={onClickRemoveUser}
            loading={loading}
            spacing={smallSpacing}
          />
          <Box left onClick={onClickCancel} pointer>
            <FigmaText textKey={Texts.dashboardCtaCancel} />
          </Box>
        </Box>
      </ClickAwayListener>
    )

  return (
    <Box
      align="center"
      direction="row"
      onClick={onClick}
      pointer={!!onClick}
      fullWidth
      justify="space-between"
      onMouseEnter={() => setShowDelete(true)}
      onMouseLeave={() => setShowDelete(false)}
      style={{ backgroundColor: showDelete ? SecondaryFrameBG : 'transparent' }}
      fullPadding
      spacing={tinySpacing}
    >
      <Box align="center" direction="row">
        <Component user={user} />
        <Box left>
          <FigmaText textKey={textKey} text={text} />
          {isPending && <FigmaText textKey={Texts.componentsTeamListTeamListInvitedUserPending} />}
        </Box>
      </Box>
      {showDelete && (
        <Box>
          <Box right spacing={smallSpacing}>
            <Box onClick={() => setShowDeleteButtons(true)} pointer>
              <FigmaImageContainer imageKey={Images.imgDelete} />
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  )
}
