export enum Collections {
  USERS = 'users',
  TEAMS = 'teams',
  COURSE = 'courses',
  QUESTIONARES = 'questionnaires',
  LOGIN_WITH_CODE_TOKEN = 'login_with_code_token'
}

export enum CollectionGroups {
  SLOTS = 'slots',
  SESSIONS = 'sessions',
  DATA_POINTS = 'datapoints',
  EVENTS = 'events',
  QUESTIONS = 'questions'
}
