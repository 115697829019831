import { Controller } from 'react-hook-form'
import * as Yup from 'yup'
import { ENTER_KEYCODE, NO_OF_LETTERS_IN_SIGN_IN_CODE } from '../../../pure-js/libs/Consts'
import { JoinTeamResponse } from '../../../pure-js/types/JoinTeamResponse'
import { SignedInViewProps } from '../../../pure-js/types/SignedInTypes'
import { smallSpacing } from '../enums/Spacings'
import { useForm } from '../hooks/useForm'
import { getFigmaText } from '../libs/TextRepository'
import { Texts } from '../../../pure-js/libs/Texts'
import Box from './Box'
import Button from './Button'
import FigmaText from './FigmaText'
import FigmaTextLoader from './FigmaTextLoader'
import { TextFieldFormExperimental } from './TextFieldForm'

const message = getFigmaText(Texts.onboardingOtpPageInputOtpCodeErrorWrongCode)
export const ValidationSchema = Yup.object().shape({
  teamCode: Yup.string().required(message).typeError(message).length(NO_OF_LETTERS_IN_SIGN_IN_CODE, message)
})

export default function SignedInJoinTeamForm(props: SignedInViewProps) {
  const { signedInState } = props
  const { data } = signedInState
  const { teamCode = '', joinTeamResponse } = data

  const error = joinTeamResponse?.error

  const _onClick = (values) => props.onPressContinue({ ...signedInState, data: { ...signedInState.data, ...values } })

  const { control, handleSubmit } = useForm(ValidationSchema, { defaultValues: { teamCode } })

  const onSubmit = handleSubmit(_onClick)
  return (
    <form onSubmit={_onClick}>
      <Box align="center" fullWidth>
        <Controller
          control={control}
          name="teamCode"
          render={(props) => (
            <Box fullWidth>
              <TextFieldFormExperimental
                {...props}
                field={{
                  ...props.field,
                  onKeyDown: (e) => e.keyCode === ENTER_KEYCODE && onSubmit(e)
                }}
                headerText={getFigmaText(Texts.onboardingConnectWithTeamInputTeamCodeHeader)}
                hasError={!!error}
              />
              {error && !props.fieldState.error && (
                <Box fullWidth align="flex-end">
                  <FigmaText
                    textKey={Texts.onboardingStartPageInputEmailErrorWrongFormat}
                    text={getMerrorMessage(joinTeamResponse)}
                  />
                </Box>
              )}
            </Box>
          )}
        />

        <Box top fullWidth spacing={smallSpacing}>
          <Button fullWidth onClick={onSubmit} textKey={Texts.onboardingOtpPageCtaPrimaryVerifyCode}>
            <FigmaTextLoader textKey={Texts.onboardingOtpPageCtaPrimaryVerifyCode} loading={props.isLoading} />
          </Button>
        </Box>
      </Box>
    </form>
  )
}

const getMerrorMessage = (r: JoinTeamResponse): string => {
  switch (r.error) {
    case 'team-not-found':
      return getFigmaText(Texts.onboardingConnectWithTeamInputTeamCodeErrorNoTeamFound)
    case 'team-full':
      return getFigmaText(Texts.onboardingConnectWithTeamInputTeamCodeErrorNoSpaceInTeam)
    default:
      throw new Error(`Unknown error: ${r.error}`)
  }
}
