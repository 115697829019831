import DayJs from 'dayjs'
import 'dayjs/locale/en.js'
import 'dayjs/locale/sv.js'
import quarterOfYear from 'dayjs/plugin/quarterOfYear.js'
import weekOfYear from 'dayjs/plugin/weekOfYear.js'
import weekday from 'dayjs/plugin/weekday.js'

DayJs.extend(quarterOfYear)
DayJs.extend(weekOfYear)
DayJs.extend(weekday)

export const dayjs = DayJs
