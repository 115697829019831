import React, { PropsWithChildren } from 'react'
import * as Sentry from '@sentry/react'
import ErrorBoundaryView from './ErrorBoundaryView'
import config from '../Config'
import { captureException } from '../libs/SentryHelper'

const FallBackComponent = config.enableSentry ? ErrorBoundaryView : undefined

const ErrorBoundaryProvider: React.FC<PropsWithChildren<any>> = ({ children }) => {
  return (
    <Sentry.ErrorBoundary fallback={FallBackComponent} onError={captureException}>
      {children}
    </Sentry.ErrorBoundary>
  )
}

export default ErrorBoundaryProvider
