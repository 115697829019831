import { PhoneNumberUtil } from 'google-libphonenumber'

const phoneUtil = PhoneNumberUtil.getInstance()

const DEFAULT_COUNTRY_CODE = 46

const SE = 'SE'

export function formatPayerAlias(number?: string): string {
  try {
    const parsedNumber = phoneUtil.parse(number, SE)
    const nationalNumber = `${parsedNumber.getNationalNumber()}`.replace(/^0+/, '')
    if (parsedNumber.hasCountryCode()) return `${parsedNumber.getCountryCode()}${nationalNumber}`
    return `${DEFAULT_COUNTRY_CODE}${nationalNumber}`
  } catch (e) {
    return ''
  }
}

export const isValidNumber = (phone) => {
  try {
    return phoneUtil.isValidNumberForRegion(phoneUtil.parse(phone, SE), SE)
  } catch (e) {
    return false
  }
}
