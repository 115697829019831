export enum AnalyticsEvents {
  INVITE = 'invite',
  LOGIN = 'login',
  SIGN_UP = 'sign_up',
  START_TUTORIAL_FROM_ONBOARDING = 'start_game_tutorial_from_onboarding',
  START_TUTORIAL_FROM_GAME_PAGE = 'start_game_tutorial_from_game_page'
}

export enum AnalyticsEventPropertyKeys {
  SOURCE = 'source'
}

export enum AnalyticsEventSource {
  'OnboardingChapter.GameIntroduction' = 'OnboardingChapter.GameIntroduction',
  PlayGamePage = 'PlayGamePage'
}
