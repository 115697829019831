import { useEffect } from 'react'

export default function useOnLoggedIn() {
  useEffect(() => {
    // @ts-ignore
    window.api = {}
  }, [])

  return null
}
