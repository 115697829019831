import { SignedInState } from '../../../pure-js/types/SignedInTypes'
import { MIN_NUMBER_OF_USERS_IN_TEAM } from '../../../pure-js/libs/Consts'
import { User } from '../../../pure-js/types/User'

export function getNumberOfInvitesLeft(signInState: SignedInState): number {
  return getNumberOfInvitesLeftForUsers(signInState.data.existingTeamMembers)
}

export function getNumberOfInvitesLeftForUsers(users: User[]): number {
  return MIN_NUMBER_OF_USERS_IN_TEAM - users.length
}
