import React from 'react'
import FigmaText, { FigmaTextProps } from './FigmaText.js'
import { minSpacing, tinySpacing } from '../enums/Spacings.js'

const FigmaTextArray: React.FC<{
  figmaTextPropSets: FigmaTextProps[]
}> = ({ figmaTextPropSets }) => {
  return (
    <div>
      {figmaTextPropSets.map((props, key) => {
        return (
          <span key={key} style={{ paddingRight: minSpacing }}>
            <FigmaText {...props} />
          </span>
        )
      })}
    </div>
  )
}

export default FigmaTextArray
